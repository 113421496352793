import Client from '@/api_client/client.js'
const show = (apiKey) => {
  const COMPANY_SHOW_PATH = `/accounts/${apiKey}/company`
  return Client.get(COMPANY_SHOW_PATH)
}

const update = (apiKey, params) => {
  const COMPANY_UPDATE_PATH = `/accounts/${apiKey}/company`
  return Client.put(COMPANY_UPDATE_PATH, { company: params, image_v2: true })
}

const accountsData = (apiKey) => {
  const COMPANY_ACCOUNTS_DATA_PATH = `/accounts/${apiKey}/company/accounts_data`
  return Client.get(COMPANY_ACCOUNTS_DATA_PATH)
}

const removeCompanyImage = (apiKey) => {
  const COMPANY_REMOVE_IMAGE_PATH = `/accounts/${apiKey}/company/delete_image`
  return Client.delete(COMPANY_REMOVE_IMAGE_PATH)
}

export {
  show,
  update,
  accountsData,
  removeCompanyImage
}

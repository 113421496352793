<template>
  <v-row align="start" justify="start" no-gutters>
    <image-with-fallback
      v-for="(image, index) in imagesArray" :key="index"
      height="90" width="90" max-width="90"
      max-height="90"
      contain
      class="pointer mme-4 mmb-4 rounded-lg grey darken-4"
      :src="image.src"
      @click="imageClick(index)"
    >
      <v-overlay absolute :value="image.showOverlay">
        <span class="font-weight-medium white--text text-subtitle-1"> + {{ showMoreNumber }}</span>
      </v-overlay>
      <template #placeholder>
        <v-row
          align="center" justify="center" class="fill-height"
          no-gutters
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>
    </image-with-fallback>
  </v-row>
</template>
<script>
import ImageWithFallback from '@/components/shared/ImageWithFallback.vue'
import { computed } from 'vue'
const maxImagesToShow = 4
export default {
  components: {
    'image-with-fallback': ImageWithFallback
  },
  props: {
    images: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  setup (props, { emit }) {
    // Methods
    const isSingleImage = computed(() => props.images.length === 1)
    const imageClick = (index) => emit('image-click', index)

    // Computed
    const showMoreNumber = computed(() => props.images.length - maxImagesToShow)
    const imagesArray = computed(() => {
      const result = props.images.slice(0, maxImagesToShow).map((image) => {
        return {
          src: image,
          showOverlay: false
        }
      })
      if (props.images.length > maxImagesToShow) {
        result[maxImagesToShow - 1].showOverlay = true
      }
      return result
    })
    return {
      isSingleImage,
      imagesArray,
      showMoreNumber,
      imageClick
    }
  }
}
</script>

<template>
  <v-container fluid class="blog-page px-0" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <confirm-delete ref="deleteDialog" />
    <blog-post-likes-dialog ref="likesDialog" />
    <share-content ref="shareDialog" :share-content="createShareContent" show-dialog />
    <blog-post-create-dialog ref="blogPostCreate" />
    <image-carousel-dialog ref="imageCarouselDialog" />
    <v-btn
      color="black" class="add-post-btn"
      dark
      small
      fab
      @click="openCreateDialog"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <blog-post-filter :api-key="apiKey" />
    <v-row align="start" justify="center">
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" class="top-image d-flex align-end justify-center" />
      <v-col
        cols="12" sm="8" lg="7"
        class="mt-8"
      >
        <v-skeleton-loader
          v-if="postsFeedLoading"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
        />
        <blog-post-empty-page v-else-if="emptyFeed" />
        <blog-post-feed
          v-else
          @reply-click="onReplyClick"
          @share-click="onShareClick"
          @image-click="showImages"
          @delete-post="deletePostClick"
          @edit-post="editPost"
          @open-likes-dialog="openLikesDialogClick"
        />
        <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BlogPostCreateDialog from '@/components/blog/BlogPostCreateDialog.vue'
import BlogPostFeed from '@/components/blog/BlogPostFeed.vue'
import BlogPostLikesDialog from '@/components/blog/BlogPostLikesDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import ShareContent from '@/components/shared/ShareContent.vue'
import BlogPostEmptyPage from '@/components/blog/BlogPostEmptyPage.vue'
import BlogPostFilter from '@/components/blog/BlogPostFilter.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'

import useAuth from '@/composables/useAuth.js'
import useBlogPost from '@/composables/useBlogPost.js'
import { createShareContent as createBlogPostShareContent } from '@/classes/BlogPostShareContent.js'
import useBlogPostLastSeen from '@/composables/useBlogPostLastSeen.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { i18n, isRtl } from '@/i18n.js'
import { ref, computed } from 'vue'
export default {
  components: {
    'blog-post-create-dialog': BlogPostCreateDialog,
    'blog-post-feed': BlogPostFeed,
    'blog-post-likes-dialog': BlogPostLikesDialog,
    'confirm-delete': ConfirmDelete,
    'share-content': ShareContent,
    'blog-post-empty-page': BlogPostEmptyPage,
    'blog-post-filter': BlogPostFilter,
    'image-carousel-dialog': ImageCarouselDialog
  },
  setup () {
    const blogPostCreate = ref(null)
    const deleteDialog = ref(null)
    const likesDialog = ref(null)
    const shareDialog = ref(null)
    const imageCarouselDialog = ref(null)

    let shareBlogPost = null
    const { apiKey } = useAuth()
    const { postsFeedLoading, pagination, emptyFeed, loadFeed, deletePost, loadFeedNextPage } = useBlogPost(apiKey.value)
    const { resetCounter } = useBlogPostLastSeen(apiKey.value)
    // Methods

    const openCreateDialog = () => blogPostCreate.value.openDialog()
    const editPost = (post) => blogPostCreate.value.openDialogForUpdate(post)
    const showImages = ({ imageUrls, index }) => imageCarouselDialog.value.openDialog({ imageUrls, index })

    const onIntersect = loadFeedNextPage
    const onReplyClick = (post) => blogPostCreate.value.openDialogForReply(post)
    const openLikesDialogClick = (post) => likesDialog.value.openDialog(post)
    const onShareClick = (post) => {
      shareBlogPost = JSON.parse(JSON.stringify(post))

      const title = i18n.t('blog_post_share.title')
      shareDialog.value.openDialog({ title })
    }

    const createShareContent = (medium) => {
      return createBlogPostShareContent({
        blogPost: shareBlogPost,
        apiKey: apiKey.value,
        medium
      })
    }

    const deletePostClick = (post) => {
      deleteDialog.value.open().then((shouldDelete) => {
        if (!shouldDelete) return deleteDialog.value.close()
        deletePost(post)
          .then(() => deleteDialog.value.close())
          .catch(errHandler)
      })
    }

    // Computed
    const showLoadMorePages = computed(() => {
      if (postsFeedLoading.value === true) return false

      const { page, totalPages } = pagination.value
      return page < totalPages
    })

    loadFeed({ reset: true })
    resetCounter()
    return {
      blogPostCreate,
      deleteDialog,
      imageCarouselDialog,
      postsFeedLoading,
      showLoadMorePages,
      likesDialog,
      shareDialog,
      emptyFeed,
      apiKey,
      isRtl,
      openCreateDialog,
      onIntersect,
      onReplyClick,
      deletePostClick,
      editPost,
      openLikesDialogClick,
      onShareClick,
      createShareContent,
      showImages
    }
  }
}
</script>

<style lang="scss">
.blog-page {
  @media only screen and (max-width: 960px) {
    padding-top: 44px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 80px;
  }

  .top-image {
    text-align: center;
    background-image: url(@/assets/blog_post_header.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 220px;
  }

  padding-top: 54px;

  .add-post-btn {
    position: fixed;
    z-index: 4;
    bottom: 86px;
    right: 10px;

    @media only screen and (max-width: 600px) {
      bottom: 80px;
    }
  }

  &.rtl {
    .add-post-btn {
      right: auto;
      left: 10px;
    }
  }
}
</style>

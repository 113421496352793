<template>
  <v-container fluid :class="{ 'rtl': isRtl, 'ltr': !isRtl }" class="items-page full-height pa-0">
    <div class="top-part-image" />
    <v-row align="center" justify="end" class="bg-color16 py-5 px-4">
      <send-report-button :api-key="currentApiKey" history-page />
    </v-row>
    <items-filter :api-key="currentApiKey" />
    <items-layout-big-devices
      v-if="$vuetify.breakpoint.mdAndUp"
      class="bg-color17"
      :api-key="currentApiKey"
    />
    <items-layout-small-devices
      v-else
      class="bg-color17"
      :api-key="currentApiKey"
    />
  </v-container>
</template>
<script>
import ItemsLayoutBigDevices from '@/components/items/ItemsLayoutBigDevices.vue'
import ItemsLayoutSmallDevices from '@/components/items/ItemsLayoutSmallDevices.vue'
import ItemsFilter from '@/components/items/ItemsFilter.vue'
import SendReportButton from '@/components/shared/broken_item_report/SendReportButton.vue'

import useItem from '@/composables/useItem.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n'
export default {
  components: {
    'items-layout-big-devices': ItemsLayoutBigDevices,
    'items-layout-small-devices': ItemsLayoutSmallDevices,
    'items-filter': ItemsFilter,
    'send-report-button': SendReportButton
  },
  setup () {
    const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()
    const { currentApiKey, initPageData } = useItem(apiKey.value)

    // Methods
    if (isCompanyAdminRegionOrAbove.value !== true) {
      initPageData(apiKey.value)
    }
    return {
      isRtl,
      currentApiKey
    }
  }
}
</script>

<template>
  <v-card class="single-post">
    <v-card-title>
      <single-blog-post-header
        :post="post"
        @delete-post="deletePost"
        @edit-post="editPost"
      />
    </v-card-title>
    <v-card-text>
      <single-blog-post-text :content="post.content" />
      <single-blog-post-images
        v-if="showImages" class="mt-5"
        :image-urls="imageUrls"
        @image-click="imageClick"
      />
      <video-player v-else-if="showVideo" :video-src="post.video_url" />
      <v-row
        v-if="showLinkPreview" align="start" justify="start"
        no-gutters class="mt-4"
      >
        <v-col cols="12" md="8">
          <link-preview :link-data="post.link" />
        </v-col>
      </v-row>
      <single-blog-post-footer
        :post="post"
        class="mt-4"
        @reply-click="replyClick"
        @share-click="shareClick"
        @show-comments-click="loadComments"
        @open-likes-dialog="openLikesDialogClick"
      />
      <blog-post-comments
        v-if="showCommentsFeed"
        :post="post"
        @delete-post="deletePostComment"
        @edit-post="editPostComment"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import SingleBlogPostHeader from '@/components/blog/shared/SingleBlogPostHeader.vue'
import SingleBlogPostFooter from '@/components/blog/single_blog_post/SingleBlogPostFooter.vue'
import SingleBlogPostImages from '@/components/blog/single_blog_post/SingleBlogPostImages.vue'
import BlogPostComments from '@/components/blog/BlogPostComments.vue'
import SingleBlogPostText from '@/components/blog/shared/SingleBlogPostText.vue'
import VideoPlayer from '@/components/shared/VideoPlayer.vue'
import LinkPreview from '@/components/shared/link_preview/LinkPreview.vue'

import { isObject, isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'single-blog-post-header': SingleBlogPostHeader,
    'single-blog-post-footer': SingleBlogPostFooter,
    'single-blog-post-images': SingleBlogPostImages,
    'blog-post-comments': BlogPostComments,
    'single-blog-post-text': SingleBlogPostText,
    'video-player': VideoPlayer,
    'link-preview': LinkPreview
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const showCommentsFeed = ref(false)
    // Methods

    const replyClick = () => emit('reply-click', props.post)
    const shareClick = () => emit('share-click', props.post)
    const imageClick = (index) => emit('image-click', { imageUrls: imageUrls.value, index })

    const deletePost = () => emit('delete-post', { post: props.post })
    const editPost = () => emit('edit-post', { post: props.post })
    const deletePostComment = (post) => emit('delete-post', { post, parentId: props.post.id })
    const editPostComment = (post) => emit('edit-post', { post, parentId: props.post.id })
    const openLikesDialogClick = () => emit('open-likes-dialog', props.post)
    const loadComments = () => (showCommentsFeed.value = true)

    // Computed
    const showImages = computed(() => Array.isArray(props.post.image_urls) && props.post.image_urls.length > 0)
    const imageUrls = computed(() => props.post.image_urls.map((image) => image.url))
    const showVideo = computed(() => isString(props.post.video_url))
    const showLinkPreview = computed(() => isObject(props.post.link) && isString(props.post.link.url))
    return {
      showImages,
      showVideo,
      showCommentsFeed,
      imageUrls,
      showLinkPreview,
      replyClick,
      shareClick,
      imageClick,
      deletePost,
      editPost,
      deletePostComment,
      editPostComment,
      openLikesDialogClick,
      loadComments
    }
  }
}
</script>
<style lang="scss">
.single-post {
  border-radius: 8px !important;
}
</style>

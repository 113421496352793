export default {
  save: 'שמור',
  cancel: 'ביטול',
  ok: 'אישור',
  delete: 'מחיקה',
  no_result: 'אין תוצאות',
  loading: 'טוען',
  exect_date: 'פעולות תאריך',
  daily_operations: 'פעולות יומיות',
  shift_start: 'תחילת משמרת',
  shift_middle: 'אמצע',
  shift_end: 'סיום משמרת',
  shift_transfer: 'העברת משמרת',
  during_shift: 'מהלך משמרת',
  currnent_day: 'יום {dayString}׳',
  close: 'סגור',
  select_all: 'בחר הכל',
  add: 'הוסף',
  yes: 'כן',
  no: 'לא',
  title: 'כותרת',
  report: 'דו״ח',
  create: 'יצירה',
  edit: 'עריכה',
  faults: 'תקלות',
  area: 'מרחב',
  name: 'שם',
  reset: 'אפס',
  all: 'הכל',
  employee: 'עובדים',
  departments: 'מחלקות',
  account_name: 'שם חשבון',
  time: 'זמן',
  today: 'היום',
  yesterday: 'אתמול',
  this_week: 'השבוע',
  older: 'ישן יותר',
  tasks: 'משימות',
  one_task: 'משימה אחת',
  chat: 'צ׳אט',
  menu: 'תפריט',
  download: 'הורדה',
  copy: 'העתק',
  permissions: 'הרשאות',
  email: 'מייל',
  notifications: 'התראות',
  community: 'קהילה',
  file_attach: 'הוסף קובץ',
  days: 'ימים',
  comments: 'תגובות',
  comment: 'תגובה',
  comment_1: 'הערה',
  share: 'שתף',
  show_more_details: 'הצג עוד פרטים',
  show_more: 'הצג עוד',
  view: 'צפייה',
  info: 'מידע',
  total: 'סה״כ',
  done: 'בוצע',
  not_done: 'לא בוצע',
  untagged: 'ללא תיוג',
  completed: 'בוצעו',
  on_going: 'לא בוצע',
  from: 'מ:',
  to: 'עד:',
  next: 'המשך',
  back: 'חזור',
  optional: 'אופציונלי  *',
  reset_all: 'אפס הכל',
  x_selected: '{count} נבחרו',
  hours: 'שעות',
  x_hours: '%{hours} שעות',
  x_days: '%{days} ימים',
  x_months: '%{months} חודשים',
  x_years: '%{years} שניפ',
  x_hour_and_minutes: '%{hours} שעות %{minutes} דקות',
  x_minutes: '%{minutes} דק׳',
  new: 'חדש',
  date: 'תאריך',
  without_category: 'ללא קטגוריה',
  description: 'תיאור',
  photos: 'תמונות',
  add_file: 'הוסף קובץ',
  video: 'וידאו',
  hq: 'מטה',
  show: 'הצג',
  call: 'חייג',
  text: 'טקסט',
  send: 'שלח',
  all_users: 'כל המשתמשים',
  frequently_used: 'בשימוש תדיר',
  login: {
    phone: 'מספר טלפון',
    user_not_found: 'משתמש לא נמצא',
    invalid_code: 'קוד שגוי',
    enter_phone: 'הכנס מספר טלפון בשביל להיכנס',
    send_to: 'שלח קוד ל:'
  },
  verify_code: {
    check_email: 'לינק כניסה למערכת נשלח למייל',
    enter_code: 'הכנס את הקוד שנשלח אליך',
    your_phone: 'המספר שלך: {phone}',
    didnt_recieve: 'לא קיבלת קוד? ',
    didnt_recieve_1: 'שלח אימייל',
    wrong_code: 'קוד שגוי'
  },
  org_structure_jobs: {
    total_jobs: 'סה״כ תפקידים',
    sunday_col: 'א׳',
    monday_col: 'ב׳',
    tuesday_col: 'ג׳',
    wednesday_col: 'ד׳',
    thursday_col: 'ה׳',
    friday_col: 'ו׳',
    saturday_col: 'ש׳',
    reset_date: 'היום',
    delete_date: 'מחק תאריך',
    delete_job_title: 'אישור מחיקת תפקיד',
    delete_job_content: 'כל המשימות המשויכות לתפקיד ימחקו',
    page_title: 'תפקידים',
    staff_page_title: 'תפקידי מטה',
    shift_duration: '%{hours} שעות %{minutes} דקות',
    shift_duration_hours: '%{hours} שעות',
    empty_text: 'אין תפקידים... עדיין'
  },
  org_structure_providers: {
    total_providers: 'סה״כ נותני שירות',
    page_title: 'נותני שירות',
    no_providers: 'אין נותני שירות... עדיין'
  },
  devices: {
    general_name: 'כללי'
  },
  work_panel: {
    tasks_tab: 'משימות',
    work_schedule: 'סידור עבודה'
  },
  devices_faulty_device_dialog: {
    total_calls: 'סה״כ קריאות',
    off_time: 'זמן השבתה',
    total_cost: 'סה״כ עלויות',
    open_date: 'פתיחה',
    whats_done: 'תיאור תיקון',
    status: 'סטטוס',
    cost: 'עלות',
    close_date: 'סגירה',
    close_ticket: 'סגירת קריאה',
    days_open: '{days_open} ימים',
    close_ticket_approve: 'התקלה תמחק מהלוח ותעבור להיסטוריה',
    send: 'שלח'
  },
  broken_item_dialog_dialog: {
    create_title: 'פתיחת קריאה',
    update_title: 'ערוך קריאה',
    price: 'עלות',
    description: 'תיאור הבעיה',
    progress_description: 'תיאור תיקון',
    comment: 'הערה',
    status: 'סטטוס',
    status_open: 'לא בטיפול',
    status_close: 'סגור',
    status_in_progress: 'בטיפול',
    status_done: 'טופל',
    priority: 'האם זה דחוף',
    priority_low: 'לא דחוף',
    priority_high: 'דחוף',
    what_broke: 'איפה הבעיה',
    assigned_user: 'מי אחראי',
    fix_images: 'תמונות פתרון תקלה',
    add_invoice: 'צרף חשבונית',
    deadline_date: 'תאריך לתיקון עד',
    deadline_time: 'שעה לתיקון עד',
    deadline_date_val: 'לתיקון עד: {deadlineDate}',
    add_video: 'הוסף סירטון',
    open_days: 'פתוח {days} ימים',
    repair: 'תיקון',
    max_photo: 'מקסימום 4',
    max_video: 'מקסימום 1',
    add_service_provider: 'שייך נותן שירות'
  },
  shifts: {
    morning: 'בוקר',
    noon: 'צהריים',
    evening: 'ערב',
    night: 'לילה',
    with_prefix: {
      morning: 'משמרת בוקר',
      noon: 'משמרת צהריים',
      evening: 'משמרת ערב',
      night: 'משמרת לילה'
    }
  },
  org_job_new_dialog: {
    create_title: 'הוסף תפקיד',
    update_title: 'ערוך תפקיד',
    job_name: 'שם תפקיד',
    choose_shift: 'משמרת',
    start: 'התחלה',
    end: 'סיום',
    sun: 'א',
    mon: 'ב',
    tue: 'ג',
    wed: 'ד',
    thu: 'ה',
    fri: 'ו',
    sat: 'ש',
    shift_times: 'זמני משמרת',
    all_days: 'כל יום',
    add_department: 'הוסף מחלקה חדשה',
    work_days: 'ימי עבודה',
    finish: 'סיום',
    daily: 'יומי'
  },
  org_provider_new_dialog: {
    create_title: 'הוסף נותן שירות',
    update_title: 'ערוך נותן שירות',
    provider_name: 'שם בעל מקצוע',
    provider_phone: 'טלפון',
    contact_name: 'שם איש קשר',
    comments: 'הערות'
  },
  validator: {
    invlid_time: 'הכנס זמן תקין',
    invlid_number: 'הכנס מספר תקין',
    required: 'הכנס ערך'
  },
  device_device_new_dialog: {
    create_title: 'הוסף אובייקט',
    create_for_category: 'הוסף אובייקט לקטגוריה',
    update_title: 'ערוך אובייקט',
    device_department: 'מיקום',
    name_taken_err: 'השם כבר קיים'
  },
  confirm_delete: {
    title: 'אישור מחיקה'
  },
  devices_new_operation: {
    op_desc: 'כתוב משימה'
  },
  job_op_schedule: {
    choose_job: 'בחר תפקיד'
  },
  op_schedule_frequency_dialog: {
    placeholder: 'תדירות'
  },
  schedule_new_op_dialog: {
    title: 'הוספת משימה',
    update_title: 'עדכון משימה',
    copy_title: 'העתקת משימה',
    description: 'כתוב משימה',
    choose_job: 'בחר תפקיד',
    set_account_manager: 'אין תפקידים המוגדרים כמנהלים',
    no_account_selected: 'לא נבחרו חשבונות',
    max_file_size: 'גודל קובץ מקסימלי 10 מ״ב',
    x_jobs_selected: '{jobs_count} תפקידים',
    related_item: 'שיוך אובייקט',
    new_item: 'שם אובייקט',
    execution_time: 'שעה לביצוע',
    select_end_date: 'ניתן לבחור תאריך סיום',
    tags: 'בחר תג',
    tag_report: 'דו״ח',
    tag_monitor: 'בקרה',
    tag_procedure: 'נוהל',
    x_tags_selected: '{tags_count} תגיות',
    select_color: 'בחר צבע',
    require_image: 'צלם תמונה בשביל לבצע את המשימה ?',
    guildance_images: 'תמונות הדרכה',
    add_sub_task: 'הוסף צ׳קליסט',
    max_photo: 'מקסימום 6',
    task_type: 'סוג משימה',
    task_type_regular: 'משימה רגילה',
    task_type_image: 'משימת תמונה',
    task_type_subtasks: 'צ׳קליסט'
  },
  languages: {
    en: 'English',
    he: 'עברית',
    es: 'Español',
    language: 'שפה'
  },
  item_search: {
    placeholder: 'חפש אובייקט',
    add_item: 'הוסף אובייקט חדש',
    delete_title: 'מחק אובייקט',
    delete_content: 'כל התקלות המקושרות לאובייקט זה ימחקו'
  },
  provider_search: {
    placeholder: 'חפש נותן שירות'
  },
  user_search: {
    placeholder: 'חפש עובד',
    x_users_selected: '{usersCount} משתמשים'
  },
  employees: {
    name_col: 'שם מלא',
    add_employee_from_another_store: 'צרף מחשבון אחר',
    total_employee: 'סה״כ עובדים',
    invite: 'הזמן',
    pending: 'ממתין לאישור',
    page_title: 'צוות',
    staff_page_title: 'צוות מטה'
  },
  employees_dialog: {
    create_title: 'הוסף עובד',
    update_title: 'עדכן עובד',
    first_name: 'שם פרטי',
    last_name: 'שם משפחה',
    phone: 'טלפון',
    permission: 'הרשאה',
    choose_jobs_placeholder: 'בחר תפקידים',
    birth_date: 'תאריך לידה',
    duplicate_phone: 'משתמש עם טלפון זהה כבר קיים',
    user_role: 'הרשאה',
    send_invitation: 'שלח הזמנה'
  },
  employees_other_store_dialog: {
    title: 'צרף עובד מחשבון אחר'
  },
  employees_roles: {
    company_admin: 'מנהל מטה',
    company_region_admin: 'מנהל אזור',
    account_admin: 'מנהל',
    account_manager: 'מחלקה',
    account_employee: 'עובד'
  },
  work_schedule_search_employee_dialog: {
    dialog_title: 'שיבוץ משמרות {jobName} {jobShift}',
    placeholder: 'הקלד שם עובד',
    all_week_schedule: 'כל השבוע'
  },
  reported_item_op_list: {
    save_error: 'אירעה שגיאה בעת שמירת הרשימה',
    title: 'רשימת פעולות',
    comment: 'כתוב הערה'
  },
  item_op_list_dialog: {
    title: 'רשימה',
    feedback_description: 'משוב משימה'
  },
  operation_frequency: {
    week_x_frequency: 'פעם ב {frequency} שבועות',
    week_x_frequency_with_day: 'פעם ב {frequency} שבועות ביום {dayName}',
    exect_date_frequency: 'תאריך ספציפי',
    every_year_no_date: 'כל שנה',
    every_year_x_selected: '{datesCount} תאריכים',
    every_week: 'פעם בשבוע',
    every_week_with_day: 'כל יום {dayName}',
    every_day: 'כל יום',
    every_year: 'כל שנה ב {date}',
    week_x_frequency_start_period: 'משימה פתוחה מיום {day}\' {date}',
    daily: 'יומי',
    weekly: 'שבועי',
    yearly: 'שנתי',
    every_few_weeks: 'כל כמה שבועות?',
    what_day: 'באיזה יום?',
    yearly_x_dates: 'שנתי {dateCount} תאריכים',
    yearly_single_date: 'שנתי - {date}',
    exact_date_command: 'ניתן לבחר תאריך בודד או טווח תאריכים'
  },
  job_selection_search: {
    x_jobs_selected: '{job_count} תפקידים'
  },
  account_selection_select: {
    placeholder: 'בחר חשבון',
    x_accounts_selected: '{account_count} חשבונות'
  },
  account_fault_devices: {
    faulty_device_tab: 'תקלות',
    faulty_device_history_tab: 'היסטוריה',
    faulty_device_reports_tab: 'דוחו״ת',
    broken_items_assigned_to_me: 'תקלות שמשויכות אלי',
    broken_items_opened_by_me: 'נפתח על ידי',
    broken_items_dates: 'טווח תאריכים',
    open_date: 'תאריך יצירה',
    close_date: 'תאריך סגירה',
    general_search: 'חיפוש כללי',
    broken_items_settings: {
      anonymous_explain: 'אפשר למשתמשים חיצוניים ליצור תקלות ע״י שליחת מייל לכתובת מיוחדת',
      anonymous_explain_field: '@ הזן שם לפני ה',
      invalid_subdomain: 'כתובת לא תקינה הזן רק אותיות מספרים, "_" ו "-" מותרים',
      page_title: 'הגדרת תקלות',
      domain_taken: 'הכתובת כבר תפוסה'
    }
  },
  account_selection: {
    staff_tasks: 'משימות מטה',
    store_tasks: 'משימות חשבון',
    stores: 'חשבונות',
    my_tasks_tab: 'אישי',
    hq_tasks_tab: 'מטה',
    accounts_tasks_tab: 'חשבונות',
    empty_area: 'ללא מרחב',
    select_area: 'בחר מרחב',
    x_selected_areas: '{area_count} מרחבים',
    no_area: 'ללא מרחב',
    daily_progress: 'התקדמות יומית'
  },
  account_selection_employee: {
    select_account: 'מעבר לחשבון'
  },
  install_prompt: {
    chrome: 'הוסף למסך הבית',
    ios: 'להתקנה: לחץ על {shareIcon}',
    ios1: 'ולאחר מכן לחץ הוסף למסך הבית'
  },
  company_faulty_devices: {
    store_select: 'בחר חשבון'
  },
  show_faulty_device: {
    empty: 'אין תקלות'
  },
  company_settings: {
    company_name: 'שם חברה'
  },
  chat_dialog: {
    title: 'צ\'אט',
    message_placeholder: 'הקלד הודעה'
  },
  account_operations: {
    cancel_filter: 'נקה סנן',
    status: 'סטטוס',
    image: 'תמונה',
    check_list: 'צ׳יק ליסט',
    status_done: 'בוצע',
    status_not_reported: 'לא בוצע',
    cross_account: 'משימות מטה',
    with_image: 'עם תמונה',
    without_image: 'ללא תמונה',
    with_checklist: 'עם רשימה',
    job_list: 'סידור עבודה',
    op_original_date: 'תאריך מקור: {date}',
    op_original_date_with_end_date: 'תאריך מקור: {date} - {endDate}',
    staff_operation: 'פעולת מטה: {first_name} {last_name}',
    assigned_to: 'מבצע: {executor}',
    mark_shift_completed: 'משמרת דווחה כבוצעה',
    operation_from: 'מ: {first_name} {last_name}',
    date_range_day: 'יום',
    date_range_week: 'שבוע',
    date_range_month: 'חודש',
    done_by: 'בוצע ע״י {first_name} {last_name}',
    created_by: 'נוצר ע״י {firstName} {lastName}'
  },
  job_shift_select: {
    x_shifts_selected: '{shift_count} משמרות'
  },
  version_check: {
    update_available: 'קיימת גירסה חדשה',
    reload: 'עדכן'
  },
  duplicate_operation_dialog: {
    title: 'העתק פעולה לתפקיד אחר'
  },
  color_picker: {
    clear: 'נקה'
  },
  out_of_shift_action: {
    title: 'פעולה מחוץ לזמן המשמרת',
    shift_not_start: 'המשמרת עוד לא התחילה',
    whice_shift: '? באיזה משמרת אתה רוצה לצפות',
    title_before_start: 'משמרת זו עדיין לא החלה<br>גיליון זה שייך ליום <span class="red--text font-weight-bold">{startDay}</span><br> למשמרת שתחל בשעה <span class="red--text font-weight-bold">{startTime}</span>',
    return_work_panel: 'חזור למערכת עבודה',
    dismiss: 'הבנתי, הישאר במשמרת זו',
    pass_shift: 'החזר למשמרת שלי ביום הקודם'
  },
  edit_operation_dialog: {
    invalid_day: '{job_name} לא עובד ביום {day}'
  },
  additional_allowed_jobs: {
    title: 'שיבוץ נוסף {jobName}'
  },
  account_edit_dialog: {
    title: 'ערוך חשבון'
  },
  create_district_dialog: {
    title: 'צור מרחב',
    update_title: 'ערוך מרחב',
    placeholder: 'שם מרחב'
  },
  staff_account_job_dialog: {
    title: 'הוסף תפקיד מטה',
    title_for_update: 'ערוך תפקיד מטה'
  },
  employee_schedules: {
    no_schedules: 'אין שיבוצים'
  },
  user_notifications: {
    missing_email: 'יש למלא אימייל ברישום עובד',
    sms: 'sms',
    email: 'email',
    broken_item: {
      tab_title: 'התראת תקלות',
      chat_notification: 'התראת צ׳אט',
      chat_notification_user_mentioned: 'בהם השתתפתי',
      low_priority: 'התראה לתקלות לא דחופות',
      high_priority: 'התראה לתקלות דחופות',
      deadline: 'תקלות פתוחות במועד דדליין',
      notify_not_only_assigned: 'התראות על תקלות שלא באחריותי',
      status_change: 'התראה בשינוי סטטוס תקלה'
    },
    job: {
      tab_title: 'התראת תפקידים',
      tab_title_for_comapny_admins: 'התראת חשבונות',
      chat_notification: 'התראת צ׳אט במשימות',
      chat_notification_cross_account: 'רק פעולות מטה',
      cross_account_operation_notification: 'התראת בקבלת משימות מטה',
      birthdate_notification: 'התראת למייל בימי הולדת לעובדים',
      end_of_shift: 'התראת סיום משימות לתפקיד',
      uncompleted_shift_tasks: 'התראה על משימות שלא בוצעו לתפקיד עד סוף המשמרת',
      task_create: 'התראה ביצירת משימה',
      task_daily: 'התראה יומית על משימות שיש לבצע'
    },
    staff_job: {
      tab_title: 'התראות תפקידי מטה',
      staff_task_daily: 'התראה יומית על משימות שיש לבצע',
      staff_task_create: 'התראה ביצירת משימה'
    },
    app_chat: {
      tab_title: 'התראות צ׳אט',
      join_group: 'התראה בצירוף לקבוצה',
      new_message: 'התראה בקבלת הודעה חדשה'
    },
    communnity: {
      tab_title: 'התראות קהילה',
      new_post: 'התראה בפרסום פוסט חדש'
    },
    reports: {
      tab_title: 'דוחו״ת',
      daily_report: 'דו״ח תפעול יומי למייל'
    }
  },
  bottom_navigation: {
    work_schedule: 'סידור עבודה',
    tasks: 'משימות',
    faulty_device: 'תקלות',
    accounts: 'חשבונות',
    chat: 'צ׳אטים'
  },
  work_schedule_request_statuses: {
    pending: 'בקשה ממתינה',
    approved: 'בקשה אושרה',
    denied: 'בקשה נדחתה'
  },
  account_department_select: {
    placeholder: 'בחר מחלקה',
    create: 'צור מחלקה',
    update: 'עדכן מחלקה',
    confirm_delete: 'האם למחוק את מחלקת {departmentName} מהתפקידים',
    error_name_taken: 'שם המחלקה כבר קיים',
    x_selected: '{count} מחלקות'
  },
  total_tasks_by_department: {
    empty_department: 'ללא מחלקה',
    ongoing_html: 'לא בוצע <span class="ongoing">{count}</span>',
    completed_html: 'בוצע <span class="completed">{count}</span>'
  },
  menu_page: {
    profile: 'פרופיל',
    accounts: 'חשבונות',
    title_general: 'כללי',
    title_preferences: 'הגדרות',
    title_admin: 'ניהול',
    title_hq_admin: 'ניהול מטה',
    tasks: 'ערוך משימות',
    tasks_subtitle: 'צפייה ועריכת משימות',
    team: 'צוות',
    team_subtitle: 'הוסף וערוך חברי צוות',
    team_subtitle_employee: 'צפייה בחברי צוות',
    jobs: 'תפקידים',
    jobs_subtitle: 'צפייה ועריכת תפקידים',
    service_providers: 'נותני שירות',
    service_providers_subtitle: 'הוסף וערוך נותני שירות',
    company_details: 'שם חברה',
    general_settings: 'הגדרות כלליות',
    hq_jobs: 'תפקידי מטה',
    hq_members: 'צוות מטה',
    hq_members_subtitle: 'הוספה ועריכת אנשי מטה',
    help: 'עזרה',
    faults_settings: 'הגדרת תקלות',
    task_bank: 'בנק משימות',
    push_history: 'היסטוריית התראות',
    drive: 'קבצים',
    logout: 'יציאה'
  },
  user_profile: {
    full_name: 'שם מלא',
    phone: 'טלפון',
    delete_account: 'מחיקת חשבון',
    delete_confirm: 'כל המידע הקשור לחשבון יימחק. האם למחוק את החשבון?',
    confirm: 'אישור',
    choose_avatar: 'בחר אווטאר',
    jobs: 'תפקידים'
  },
  account_department_page: {
    total: 'סה״כ מחלקות',
    name_col: 'שם מחלקה',
    page_title: 'מחלקות'
  },
  app_chat: {
    create_group: 'יצירת קבוצה',
    search_group_placeholder: 'חפש עובד או קבוצה',
    search_group_divide: 'קבוצות פעילות',
    search_user_divide: 'משתמשים פעילים',
    empty_messages: 'אין הודעות',
    group_details: 'פרטי קבוצה',
    exit_group: 'יציאה מהקבוצה',
    delete_group: 'מחיקת קבוצה',
    delete_group_confirm: 'האם למחוק את הקבוצה: {groupName}',
    delete_group_confirm_detail: 'כל היסטוריית ההודעות תמחק',
    exit_group_confirm: 'האם לצאת מהקבוצה: {groupName}',
    exit_group_confirm_detail: 'כל היסטוריית ההודעות תמחק',
    new_group_dialog: {
      title_create: 'צור קבוצה חדשה',
      title_read_only: 'פרטי קבוצה',
      title_update: 'עדכן קבוצה',
      group_name: 'שם קבוצה',
      group_image: 'תמונת קבוצה',
      add_users: 'הוסף משתמשים'
    },
    seen_by_dialog: {
      title: 'פרטי הודעה',
      read_by: 'נקרא ע״י',
      pending: 'ממתין לקריאה'
    }
  },
  user_live_notifications: {
    new_group_html: "צורפת לקבוצת צ׳אט <span class='font-weight-bold'>{groupName}</span>"
  },
  language_select: {
    placeholder: 'בחר שפה'
  },
  share_content: {
    clipboard_copy: 'התוכן הועתק'
  },
  broken_item_share: {
    dialog_title: 'שתף תקלה',
    ticket_from: 'תקלה מ: {companyName} | {accountName}',
    ticket_from_no_company: 'תקלה מ: {companyOrAccountName}',
    opened_at: 'נפתח בתאריך: %{open_date}',
    opened_by: 'נפתח ע״י: %{open_by}',
    description: 'תיאור: %{description}',
    responsibility: 'באחריות של:',
    comment: 'הערה: %{comment}',
    fix_description: 'תיאור תיקון: %{fix_description}',
    price: 'מחיר: %{price}',
    fault_images: 'תמונות',
    fault_fix_images: 'תמונות תיקון',
    fault_attached_invoice: 'חשבונית מצורפת',
    fault_attached_video: 'וידאו מצורף',
    related_provider: 'נותן שירות: {providerName}',
    related_providers: 'נותני שירות:',
    fault_fix_until: 'לתיקון עד: {fixUntil}'
  },
  image_uploader: {
    max_images: 'ניתן להעלות עד %{maxImages} תמונות'
  },
  audio_recorder: {
    permission_denied: 'אין הרשאה להקליט קול'
  },
  jobs_calander: {
    show_all: ' הצג הכל (%{count})'
  },
  job_operation_edit: {
    delete_operation: 'מחק פעולה'
  },
  feature_select: {
    enable_features: 'אפשר גישה',
    broken_item_history: 'לוח היסטוריית תקלות',
    broken_items: 'תקלות',
    read: 'קריאה',
    edit: 'עריכה',
    delete: 'מחיקה',
    create: 'יצירה',
    close: 'סגירת תקלה'
  },
  operation_share: {
    title: 'שתף משימה',
    hq: 'משימת מטה',
    start_time: 'שעת ביצוע: %{startTime}',
    creator: 'מ: %{firstName} %{lastName}',
    not_reported: 'סטטוס: לא דווח',
    reported: 'סטטוס: דווח ע״י %{firstName} %{lastName}',
    report_image: 'תמונת דיווח:',
    attahced_file: 'קובץ מצורף:',
    checklist: 'רשימה: (%{done}/%{total}):',
    guidance_images: 'תמונות הדרכה:'
  },
  company_permissions: {
    create_object: 'יצירת אובייקט',
    account_admin: 'הרשאת מנהל',
    account_manager: 'הרשאת מנהל מחלקה',
    account_employee: 'הרשאת עובד'
  },
  cross_account_page: {
    my_operations: 'משימות שיצרתי',
    delete_cross_operation: 'מחק משימה',
    delete_cross_operation_detail: 'כל סט המשימות הקשורות למשימה זו ימחקו'
  },
  single_task_notification: {
    title: 'צור התראות למשימה',
    on_complete: 'בהשלמת משימה',
    on_un_complete: 'באי השלמת משימה'
  },
  install_app_prompt: {
    title: 'היי, האפליקציה למובייל שלנו זמינה בגוגל פליי ואפ סטור',
    close: 'הבנתי, אל תציג שוב'
  },
  blog_post_create_dialog: {
    new_post_title: 'פוסט חדש',
    post_update_title: 'ערוך פוסט',
    new_comment_title: 'הגב לפוסט',
    comment_update_title: 'ערוך תגובה',
    content_placeholder: 'על מה את/ה רוצה לדבר?',
    select_all: 'כל החשבונות',
    add_tag: 'הוסף תג',
    post: 'שלח'
  },
  tags_select: {
    placeholder: 'בחר תגית'
  },
  blog_post: {
    read_more: 'קרא עוד',
    read_less: 'קרא פחות',
    like: 'אהבתי',
    reply: 'הגב',
    load_comments: 'טען תגובות',
    likes: 'Likes',
    staff_account: 'חשבון מטה',
    no_posts: 'לא נמצאו פוסטים',
    be_first: '!תיהיה הראשון לכתוב פוסט',
    my_posts: 'הפוסטים שלי',
    my_likes: 'הלייקים שלי',
    actions: 'אפשרויות'
  },
  blog_post_share: {
    title: 'שתף פוסט',
    post_by: 'פוסט נכתב ע״י: %{firstName} %{lastNmae}',
    created_at: 'נוצר בתאריך: %{date}',
    post_tags: 'תגיות: %{tags}',
    images: 'תמונות',
    video: 'וידאו: %{videoUrl}',
    likes_count: '%{likesCount} לייקים',
    comments_count: '%{commentsCount} תגובות'
  },
  video_upload: {
    max_size: 'גודל הוידאו חייב להיות עד 100MB'
  },
  item_type_select: {
    title: 'הצג ככותרת',
    single_checklist: 'בוצע/לא בוצע',
    image: 'דיווח תמונה',
    rating: 'דרג את המשימה',
    yes_no: 'שאלת כן/לא',
    feedback: 'השאר פידבק',
    feedback_optional: '* פידבק אופציונלי'
  },
  item_category_search: {
    placeholder: 'חפש קטגוריה',
    add_item_category: 'הוסף קטגוריה חדשה',
    delete_title: 'מחק קטגוריה',
    delete_content: 'מחיקה תעביר את כל האובייקטיים המשוייכים  להיות ללא לקטגוריה'
  },
  new_item_category: {
    create_title: 'יצירת קטגוריה',
    update_title: 'ערוך קטגוריה',
    name: 'שם קטגוריה'
  },
  company_broken_items: {
    deadline_date_label: 'תקלות עם דדליין',
    deadline_date_passed: 'תקלות שזמן הטיפול עבר',
    deadline_date_today: 'תקלות לטיפול היום',
    deadline_date_coming_week: 'תקלות לטיפול השבוע',
    deadline_date_no_deadline: 'תקלות ללא דדליין',
    no_deadline: 'ללא דדליין',
    deadline_this_week: 'לתיקון השבוע',
    deadline_today: 'לתיקון היום',
    deadline_passed: 'תאריך עבר',
    total: 'סה״כ תקלות',
    avg_open_time: 'זמן ממוצע לתיקון (ימים)',
    urgent_faults: 'תקלות דחופות',
    status: 'סטטוס',
    deadline: 'דדליין',
    after_deadline: 'לאחר הדדליין',
    assigned_ticket: 'תקלות שמשויכות לאחרים',
    report: {
      export_excel: 'יצוא לאקסל',
      send_later: 'הוד״ח ישלח לחשבון המייל שלך בהמשך',
      missing_email: 'יש להזין אימייל בפרופיל עובד'
    }
  },
  broken_item: {
    created_by: 'נפתח ע״י:',
    assigned_to: 'באחריות של:',
    repair_until: 'תאריך תיקון:',
    options: 'אפשרויות',
    close_ticket: 'סגור תקלה (העבר להיסטוריה)',
    service_provider: 'נותני שירות:',
    time_left: 'זמן שנשאר:'
  },
  district_select: {
    add_new: 'הוסף אזור חדש',
    delete_title: 'מחק אזור',
    delete_content: 'האם אתה בטוח שאתה רוצה למחוק את האזור?'
  },
  broken_item_table: {
    headers: {
      account_name: 'חשבון',
      location: 'מיקום',
      assigned_to: 'אחראי',
      service_provider: 'נותני שירות',
      comments: 'תגובות',
      open_date: 'זמן פתיחה',
      status: 'סטטוס'
    }
  },
  company_stats: {
    faults: 'תקלות'
  },
  company_table: {
    view: 'לצפייה',
    headers: {
      name: 'שם חשבון',
      location: 'אזור',
      hq_tasks: 'משימות מטה',
      account_tasks: 'משימות חשבון',
      total_faults: 'תקלות'
    }
  },
  items_page: {
    pick_account: 'בחר חשבון',
    general: 'כללי',
    total_calls: 'סה״כ קריאות',
    off_time: 'זמן השבתה',
    total_cost: 'סה״כ עלויות'
  },
  tasks_page: {
    delete_task_image: 'האם אתה בטוח שאתה רוצה למחוק את התמונה?',
    empty_tasks: 'אין לך משימות להיום',
    jobs: 'תפקידים',
    departments: 'מחלקות',
    progress: 'התקדמות',
    more_details: 'פרטים נוספים',
    less_details: 'פחות פרטים'
  },
  task_bank: {
    title: 'בנק משימות',
    every_day_frequency: 'כל יום',
    every_week_frequency: 'כל שבוע',
    every_x_weeks_frequency: 'פעם בכמה שבועות',
    every_year_frequency: 'כל שנה',
    exect_date_frequency: 'תאריך ספציפי',
    created_by: 'נוצר ע״י {firstName} {lastName}',
    no_tasks: 'לא נוצרו משימות עדיין',
    serach_placeholder: 'חפש לפי תיאור משימה',
    assigned_to_x_jobs: 'משויך ל {jobsCount} תפקידים'
  },
  hq_task_job_group: {
    create_title: 'צור קבוצת תפקידים חדשה',
    update_title: 'עדכן קבוצת תפקידים',
    job_groups: 'קבוצות תפקידים',
    delete_group: 'מחק קבוצת תפקידים {groupName}',
    group_name: 'שם קבוצת'
  },
  default_deadline: {
    broken_item_settings: 'תקלות',
    high_priority: 'תקלות דחופות',
    low_priority: 'תקלות לא דחופות',
    deadline_time: 'זמן לטיפול בתקלה (שעות)',
    deadline_notification: 'שלח הודעה אם התקלה לא טופלה (שעות)'
  },
  push_history: {
    title: 'היסטוריית התראות',
    no_notifications: 'אין התראות',
    is_viewed: 'האם נקרא',
    viewed: 'נקרא',
    not_viewed: 'לא נקרא',
    mark_as_viewed: 'סמן כנקרא',
    assigned_ticket: 'שויכה אליך תקלה ב %{accountName} עבור %{itemName}',
    ticket_new_message: 'התקבלה הודעת צ׳אט על תקלה ב%{accountName} עבור %{itemName}',
    new_ticket: 'תקלה חדשה נוצרה ב%{accountName} עבור %{itemName}',
    updated_ticket: 'תקלה עודכנה ב%{accountName} עבור %{itemName}',
    ticket_deadline: 'תקלה ב%{accountName} עבור %{itemName} צריכה להיות מטופלת עד %{deadlineDate}',
    ticket_timed_notification: 'התראה חוזרת על תקלה ב%{accountName} עבור %{itemName} שעדיין פתוחה מ %{openDate}',
    new_blog_post: 'פוסט קהילה חדש נכתב ע״י %{firstName} %{lastName}',
    new_app_chat_group_message: 'הודעה חדשה מ%{firstName} %{lastName} ב%{groupName}',
    new_app_chat_message: 'הודעה חדשה מ%{firstName} %{lastName}',
    app_chat_join_group: 'צורפת לקבוצה חדשה %{groupName}',
    task_chat_message: 'התקבלה הודעת צ׳אט עבור משימה ב %{accountName}, ל%{jobName} %{jobShift}',
    new_staff_task: 'משימת אישית חדשה נוצרה ע״ %{firstName} %{lastName} עבור %{jobName}',
    new_corss_account_task: 'משימת מטה חדשה נוצרה ב%{accountName} ל%{jobName} %{jobShift}',
    new_task: 'משימה חדשה נוצרה ב %{accountName} ל%{jobName} %{jobShift}',
    task_not_completed: 'משימה לא דווחה כבוצעה ב %{accountName} ל%{jobName} %{jobShift}',
    task_completed: 'משימה דווחה כבוצעה ב %{accountName} ל%{jobName} %{jobShift}',
    shift_completed: 'דיווח סיום משמרת ב%{accountName} עבור %{jobName} %{jobShift}',
    shift_not_completed: 'משימות לא הושלמו ב %{accountName} עבור %{jobName} %{jobShift}'
  },
  drive: {
    title: 'קבצים',
    upload: 'העלה קובץ',
    create_folder: 'צור תיקייה חדשה',
    update_folder: 'ערוך תיקייה',
    folder_name: 'שם תיקייה',
    all_accounts: 'כל החשבונות',
    created_by: 'ע״י: {firstName} {lastName}',
    one_file: 'קובץ אחד',
    many_files: '{count} קבצים',
    one_folder: 'תיקייה אחת',
    many_folders: '{count} תיקיות',
    all_roles: 'כל ההרשאות',
    delete_folder: 'מחק {folderName} תיקייה',
    delete_folder_details: 'האם למחוק את התיקייה וכל הקבצים שבה?',
    upload_file: 'העלה קובץ',
    update_file: 'עדכן קובץ',
    delete_file: 'מחק {fileName} קובץ',
    owner: 'בעלים',
    link_copied: 'הקישור הועתק',
    owner_search: 'נוצר ע״י',
    empty_drive: 'אין קבצים או תיקיות',
    empty_folder: 'תיקייה ריקה',
    roles_access: 'גישה להרשאות',
    cover_photo: 'תמונת רקע',
    file_info: 'פרטי קובץ',
    downloaded_by: 'הורד ע״י',
    no_downloads: 'הקובץ לא הורד',
    empty_single_folder: 'תיקייה ריקה'
  },
  hq_tasks_page: {
    account_rating: 'דירוג חשבונות',
    top_3: '👍🏻 נותנים עבודה',
    bottom_3: '👎🏻 יכולים קצת יותר',
    all_tasks: 'כל המשימות',
    single_account_mode: 'מצב חשבון יחיד',
    hq_tasks: 'משימות מטה',
    created_by_me: 'משימות שיצרתי',
    alerted_tasks: 'משימות עם התראות',
    no_tasks: 'אין משימות להיום',
    update_every_hour: 'מתעדכן כל שעה'
  },
  page_filter: {
    filter_by: 'סנן לפי'
  },
  task_order_dialog: {
    shift_title: 'שנה סדר משימות'
  }
}

<template>
  <v-dialog
    v-model="dialog" max-width="510" persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    content-class="rounded-xl"
  >
    <work-days-dialog ref="workDaysDialogRef" />
    <v-card
      class="org-struct-job-new overflow-y-hidden popup-card bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-0">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start pps-1">
            <v-btn
              icon
              color="black"
              @click="close(false)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4 px-1">
        <v-form ref="newJobForm">
          <v-row align="center" justify="center">
            <v-col cols="11">
              <v-text-field
                v-model="newJob.name"
                class="required"
                outlined
                background-color="white"
                counter="120"
                :label="$t('org_job_new_dialog.job_name')"
              />
            </v-col>
            <v-col cols="11" class="pt-0">
              <account-department-select
                v-model="newJob.account_department.id"
                :api-key="apiKey"
                :is-required="isUserAccountManager"
                :show-add-department="isAccountAdminOrAbove"
                outlined
                background-color="white"
              />
            </v-col>
            <v-col cols="11" class="pt-0">
              <v-select
                v-model="newJob.shift_id"
                class="required"
                :items="shiftSelectionOptions"
                outlined
                background-color="white"
                :label="$t('org_job_new_dialog.choose_shift')"
                item-value="id"
                item-text="translatedName"
                single-line
                :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
              >
                <template #selection="data">
                  <div class="text-center">
                    <div>{{ data.item.translatedName }}</div>
                  </div>
                </template>
                <template #item="{ item }">
                  <v-row align="center" justify="space-between" no-gutters>
                    <v-col cols="10">
                      {{ item.translatedName }}
                    </v-col>
                    <v-col cols="2" class="texts-end">
                      <v-checkbox
                        :input-value="item.id === newJob.shift_id"
                        off-icon="radio_button_unchecked" on-icon="radio_button_checked"
                        hide-details class="mt-0 pt-0 d-inline-block"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
            <v-col cols="11" class="pt-0">
              <shift-time-field
                :active-days-count="workDaysCount"
                @shift-time-click="openWorkDaysDialog"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="saveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'
import AccountJobsCreateShiftTimeField from '@/components/account_jobs/account_job_create_dialog/AccountJobsCreateShiftTimeField.vue'
import AccountJobCreateWorkDaysDialog from '@/components/account_jobs/account_job_create_dialog/AccountJobCreateWorkDaysDialog.vue'

import {
  newJobModel as OrgJobNewJobModel,
  getShiftSortOrderMap, translateShift as OrgJobTranslateShift
} from '@/store/modules/OrganizationJob.js'

import useAuth from '@/composables/useAuth.js'

import { create as JobClientCreate, update as JobClientUpdate } from '@/api_client/AccountJob.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, nextTick, computed } from 'vue'
const DialogMode = { create: 0, update: 1 }

export default {
  components: {
    'account-department-select': AccountDepartmentSelect,
    'shift-time-field': AccountJobsCreateShiftTimeField,
    'work-days-dialog': AccountJobCreateWorkDaysDialog
  },
  setup () {
    const { apiKey, isUserAccountManager, isAccountAdminOrAbove } = useAuth()
    const workDaysDialogRef = ref(null)
    const shiftSelection = []
    const shiftSortOrderMap = getShiftSortOrderMap()
    for (const shiftName in shiftSortOrderMap) { // eslint-disable-line no-unused-vars
      shiftSelection.push({
        id: shiftSortOrderMap[shiftName],
        translatedName: OrgJobTranslateShift(shiftName),
        name: shiftName
      })
    }

    const dialogMode = ref(null)
    const dialog = ref(false)
    const shiftSelectionOptions = ref(shiftSelection)
    const newJob = ref(OrgJobNewJobModel())
    const isLoading = ref(false)
    const newJobForm = ref(null)
    let resolveFunc = null

    const openDialog = (job, dialogModeVal) => {
      dialog.value = true
      dialogMode.value = dialogModeVal
      newJob.value = OrgJobNewJobModel(job)
      if (newJob.value.shift && newJob.value.shift.name) {
        newJob.value.shift_id = shiftSelectionOptions.value.find((item) => item.name === newJob.value.shift.name).id
      }

      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const createNewJob = () => {
      const creationParams = newJob.value.serialize()
      JobClientCreate(apiKey.value, { account_job: creationParams })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }

    const updateJob = () => {
      const updateParams = newJob.value.serialize()
      JobClientUpdate(apiKey.value, newJob.value.id, { account_job: updateParams })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }

    const allweeklyScheduleValid = (weeklySchedule) => {
      for (const day in weeklySchedule) { // eslint-disable-line no-unused-vars
        const startTime = weeklySchedule[day].start_time || ''
        const endTime = weeklySchedule[day].end_time || ''
        if (startTime.length !== endTime.length) return false
      }
      return true
    }

    // Methods

    const openForUpdate = (job) => openDialog(job, DialogMode.update)
    const openForCreate = () => openDialog({}, DialogMode.create)
    const openWorkDaysDialog = () => workDaysDialogRef.value.openDialog(JSON.parse(JSON.stringify(newJob.value.weekly_schedule)))
      .then(({ isSaved, newWeeklyDays }) => {
        if (!isSaved) return

        const weeklySchedule = newJob.value.weekly_schedule
        for (const day in weeklySchedule) {
          if (day in newWeeklyDays) {
            weeklySchedule[day].start_time = newWeeklyDays[day].start_time
            weeklySchedule[day].end_time = newWeeklyDays[day].end_time
          } else {
            weeklySchedule[day].start_time = null
            weeklySchedule[day].end_time = null
          }
        }
      })

    const close = (resolveResult) => {
      dialogMode.value = null
      dialog.value = false
      newJobForm.value.reset()
      resolveFunc(resolveResult)
    }
    const save = () => {
      nextTick(() => {
        isLoading.value = true
        if (dialogMode.value === DialogMode.update) {
          updateJob()
        } else {
          createNewJob()
        }
      })
    }

    // Computed
    const saveDisabled = computed(() => {
      if (!allweeklyScheduleValid(newJob.value.weekly_schedule)) return true
      if (!newJob.value.name ||
        !Number.isInteger(+newJob.value.shift_id)) return true
      if (isUserAccountManager.value === true && !Number.isInteger(newJob.value.account_department?.id)) return true
      if (isLoading.value) return true
      return false
    })

    const workDaysCount = computed(() => {
      let count = 0
      for (const day in newJob.value.weekly_schedule) {
        if (newJob.value.weekly_schedule[day].start_time && newJob.value.weekly_schedule[day].end_time) {
          count++
        }
      }
      return count
    })

    const dialogTitle = computed(() =>
      dialogMode.value === DialogMode.update
        ? i18n.t('org_job_new_dialog.update_title')
        : i18n.t('org_job_new_dialog.create_title')
    )

    return {
      dialog,
      workDaysDialogRef,
      apiKey,
      shiftSelectionOptions,
      newJob,
      workDaysCount,
      isLoading,
      isRtl,
      isUserAccountManager,
      isAccountAdminOrAbove,
      newJobForm,
      saveDisabled,
      dialogTitle,
      openForUpdate,
      openForCreate,
      close,
      openWorkDaysDialog,
      save
    }
  }
}
</script>
<style lang="scss">
.org-struct-job-new {
  .time-select {
    display: inline-block;
    padding-right: 6px;
    padding-left: 6px;
    width: calc(100% / 7);
  }

  @media only screen and (max-width: 600px) {
    .time-select {
      width: calc(100% / 3);
      margin-top: 10px;
      padding-right: 8px;
      padding-left: 8px;

      .v-input.v-text-field.start-time {
        padding-top: 0;
      }

      .v-text-field__details {
        display: none;
      }
    }

    .scrollable {
      overflow: auto;
      height: 80vh;
      max-height: 80vh;
    }
  }

  .v-input.v-text-field {
    margin-top: 0px
  }
}
</style>

<template>
  <v-container
    fluid class="org-structure-providers"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <new-company-provider-dialog ref="providerDialog" />
    <confirm-delete ref="deleteDialog" />
    <v-btn
      v-if="isAccountAdminOrAbove" fab small
      color="black white--text"
      class="add-button"
      @click="createNewProvider"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-row v-if="isLoading" align="center" justify="center">
      <v-col
        v-for="i in 3" :key="i"
        cols="11"
      >
        <v-skeleton-loader
          type="article, actions"
        />
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="space-around">
      <v-col cols="12" class="page-title">
        {{ $t("org_structure_providers.page_title") }}
      </v-col>
      <empty-page v-if="isEmptyPage" :empty-text="$t('org_structure_providers.no_providers')" />
      <v-col v-else cols="11" lg="8">
        <v-row align="start" justify="start" no-gutters>
          <v-col
            cols="12" md="4" sm="6"
            class="my-4"
          >
            <company-provider-search
              v-model="curentProviderSearch"
              :api-key="apiKey"
              outlined
              hide-details
              @change="onProviderSearchChange"
            />
          </v-col>
          <v-col cols="12" class="mt-2 ml-2 font-weight-bold">
            <span>
              {{ $t("org_structure_providers.total_providers") }}
            </span>
            <span class="blue--text">
              {{ pagination.total }}
            </span>
          </v-col>
          <v-col
            v-for="provider in providerList" :key="provider.id"
            cols="12"
            class="my-2"
          >
            <single-company-provider
              :provider="provider"
              :read-only="!isAccountAdminOrAbove"
              @edit-provider="editProvider"
              @delete-provider="onDeleteProvider"
            />
          </v-col>
          <v-col v-if="showLoadMorePages" v-intersect.once="loadNextPage" />
          <v-col v-if="isLoadingNextPage" cols="12" class="row no-gutters align-start justify-start mb-4">
            <v-col
              v-for="i in 3" :key="i"
              cols="12"
            >
              <v-skeleton-loader
                type="article, actions"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import NewCompanyProviderDialog from '@/components/company_providers/NewCompanyProviderDialog.vue'
import CompanyProviderSearch from '@/components/shared/company_provider_search/CompanyProviderSearch.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import SingleCompanyProvider from '@/components/company_providers/SingleCompanyProvider.vue'
import EmptyPage from '@/components/shared/EmptyPage.vue'

import useCompanyProvider from '@/composables/useCompanyProvider.js'
import useAuth from '@/composables/useAuth.js'

import { isObject } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'new-company-provider-dialog': NewCompanyProviderDialog,
    'confirm-delete': ConfirmDelete,
    'single-company-provider': SingleCompanyProvider,
    'company-provider-search': CompanyProviderSearch,
    'empty-page': EmptyPage
  },
  setup () {
    const curentProviderSearch = ref({})
    const providerDialog = ref(null)
    const deleteDialog = ref(null)
    const { apiKey, isAccountAdminOrAbove } = useAuth()
    const {
      isLoading, isLoadingNextPage, pagination, providerList, isEmptyPage, loadNextPage,
      loadProviders, deleteProvider, providerSearchChange, clearSearch
    } = useCompanyProvider(apiKey.value)

    // Methods
    const onProviderSearchChange = () => {
      isObject(curentProviderSearch.value) && Number.isInteger(curentProviderSearch.value.id)
        ? providerSearchChange(curentProviderSearch.value)
        : clearSearch()
    }

    const createNewProvider = () => providerDialog.value.openForCreate()
      .then((providerCreated) => {
        if (providerCreated) {
          loadProviders(true)
        }
      })

    const editProvider = (data) => providerDialog.value.openForUpdate(data.provider)
      .then((providerUpdated) => {
        if (providerUpdated) {
          loadProviders(true)
        }
      })

    const onDeleteProvider = (data) => deleteDialog.value.open()
      .then((shouldDelete) => {
        if (!shouldDelete) return
        deleteProvider(data?.provider?.id)
          .finally(() => deleteDialog.value.close())
      })

    const showLoadMorePages = computed(() => {
      if (isLoading.value || isLoadingNextPage.value) return false
      return pagination.value.total > providerList.value.length
    })

    loadProviders()
    return {
      apiKey,
      curentProviderSearch,
      pagination,
      isLoading,
      isLoadingNextPage,
      isAccountAdminOrAbove,
      providerList,
      providerDialog,
      deleteDialog,
      isRtl,
      showLoadMorePages,
      isEmptyPage,
      createNewProvider,
      editProvider,
      onDeleteProvider,
      providerSearchChange,
      clearSearch,
      loadNextPage,
      onProviderSearchChange
    }
  }
}
</script>
<style lang="scss">
.org-structure-providers {
  margin-top: 116px;

  @media only screen and (max-width: 600px) {
    margin-top: 136px;
  }
}
</style>

<template>
  <v-container
    fluid class="px-0 user-profile"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <confirm-delete ref="deleteDialog" />
    <select-avatar-dialog ref="selectAvatarDialogRef" />
    <v-row
      align="start" justify="start" justify-sm="center"
      no-gutters
    >
      <v-col
        cols="12" sm="6" md="4"
        class="row align-start justify-start"
      >
        <v-col cols="12" class="page-title py-4">
          {{ $t('menu_page.profile') }}
        </v-col>
        <v-row align="start" justify="start" class="px-4">
          <v-col cols="12" class="text-center mt-4">
            <span class="avatar-container">
              <v-avatar color="indigo" size="140">
                <v-img :src="userAvatarUrl" />
              </v-avatar>
              <v-btn
                fab
                x-small class="edit-avatar-btn" color="#0040FF"
                @click="editAvatar"
              >
                <v-icon small color="white">edit</v-icon>
              </v-btn>
            </span>
          </v-col>
          <v-col cols="12" class="profile-item mt-8">
            <div class="sub-title-item">
              {{ $t('user_profile.full_name') }}
            </div>
            <div class="title-item">
              {{ userFullName }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="profile-item mt-4">
            <div class="sub-title-item">
              {{ $t('user_profile.phone') }}
            </div>
            <div class="title-item">
              {{ userPhoneNumber }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="profile-item mt-4">
            <div class="sub-title-item">
              {{ $t('email') }}
            </div>
            <div class="title-item">
              {{ userEmail }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="profile-item mt-4">
            <div class="sub-title-item">
              {{ $t('employees_dialog.birth_date') }}
            </div>
            <div class="title-item">
              {{ userBirthdate }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="px-0 pt-4">
            <multiple-file-uploader ref="userFilesUploader" record-type="User" :max-files="5" />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-btn
              color="#0E4AFD" class="white--text rounded" depressed
              tile
              :loading="isLoading" :disabled="saveDisabled" @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="px-0 pt-0 color5 t-400-14">
            {{ $t('permissions') }}
          </v-col>
          <v-col cols="12" class="px-0 pt-0 color5 t-400-14">
            <v-chip
              class="white--text t-700-14"
              color="#111"
            >
              {{ userRoleTranslated }}
            </v-chip>
          </v-col>
          <v-col v-if="showAllowedJobs" cols="12" class="px-0 pt-0 color5 t-400-14">
            {{ $t('user_profile.jobs') }}
          </v-col>
          <v-col v-if="showAllowedJobs" cols="12" class="px-0 pt-0 color5 t-400-14">
            <v-chip
              v-for="(job, index) in allowedJobs"
              :key="index"
              class="t-700-14 mme-2 mb-2"
              color="#CBE4F2"
            >
              {{ job }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="pt-4 pl-0">
            <v-btn icon color="red" @click="deleteUser">
              <v-icon class="material-icons-outlined">
                delete
              </v-icon>
            </v-btn>
            {{ $t('user_profile.delete_account') }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SelectAvatarDialog from '@/components/user_profile/SelectAvatarDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import MultipleFileUploader from '@/components/shared/MultipleFileUploader.vue'

import { translateShift } from '@/store/modules/OrganizationJob.js'
import { translateRole } from '@/store/modules/EmployeeRole.js'

import { me as userClientCurrentUser, destroy as userClientDelete } from '@/api_client/User.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useAuth from '@/composables/useAuth.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed, ref } from 'vue'

export default {
  components: {
    'select-avatar-dialog': SelectAvatarDialog,
    'confirm-delete': ConfirmDelete,
    'multiple-file-uploader': MultipleFileUploader
  },
  setup () {
    const isLoading = ref(true)
    const selectAvatarDialogRef = ref(null)
    const user = ref(null)
    const userFilesUploader = ref(null)
    const { apiKey } = useAuth()
    const deleteDialog = ref(null)

    const loadUser = () => {
      isLoading.value = true
      userClientCurrentUser(apiKey.value)
        .then(response => {
          user.value = response.data.data
          isLoading.value = false
          userFilesUploader.value.setFiles(user.value.attached_files)
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Methods
    const editAvatar = () => {
      const userId = user.value.id
      const userAvatarUrl = user.value.avatar_url
      selectAvatarDialogRef.value.openDialog({ userId, userAvatarUrl }).then(({ isChanged, selectedAvatar }) => {
        if (isChanged !== true) return
        user.value.avatar_url = selectedAvatar
      })
    }

    const deleteUser = () => {
      const title = i18n.t('user_profile.delete_account')
      const content = i18n.t('user_profile.delete_confirm')
      const deleteBtnText = i18n.t('user_profile.confirm')
      deleteDialog.value.open({ title, content, deleteBtnText })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          const { logoutUser } = useAuth()
          userClientDelete(apiKey.value, user.value.id)
            .then(() => {
              deleteDialog.value.close()
              logoutUser()
            })
            .catch(errHandler)
        })
    }

    const save = async () => {
      isLoading.value = true
      userFilesUploader.value.saveFiles({ apiKey: apiKey.value, userId: user.value.id })
        .then(loadUser)
        .finally(() => (isLoading.value = false))
    }
    // Computed
    const userRoleTranslated = computed(() => {
      if (user.value === null) return ''
      return translateRole(user.value.role)
    })

    const showAllowedJobs = computed(() => {
      if (user.value === null) return false
      return Array.isArray(user.value.user_allowed_jobs) && user.value.user_allowed_jobs.length > 0
    })

    const allowedJobs = computed(() => {
      if (user.value === null) return []
      return user.value.user_allowed_jobs.map((job) => {
        return `${job.name} - ${translateShift(job.shift)}`
      })
    })

    const userAvatarUrl = computed(() => {
      if (isString(user.value?.avatar_url)) return user.value.avatar_url
      const env = import.meta.env.MODE
      return `https://assets.toperation.co/${env}/images/User/Avatar/ic_avatar17.png`
    })

    const userFullName = computed(() => {
      if (user.value === null) return ''
      return `${user.value.first_name} ${user.value.last_name}`
    })

    const userPhoneNumber = computed(() => {
      if (user.value === null) return ''
      return user.value.phone
    })

    const userEmail = computed(() => {
      if (user.value === null) return ''
      return user.value.email
    })

    const userBirthdate = computed(() => {
      if (user.value === null) return ''
      return user.value.birthdate
    })

    const saveDisabled = computed(() => {
      if (isLoading.value === true) return true
      return userFilesUploader.value?.hasChanges !== true
    })

    loadUser()
    return {
      selectAvatarDialogRef,
      userFullName,
      userPhoneNumber,
      userEmail,
      userBirthdate,
      deleteDialog,
      userFilesUploader,
      isLoading,
      showAllowedJobs,
      allowedJobs,
      saveDisabled,
      userAvatarUrl,
      isRtl,
      userRoleTranslated,
      deleteUser,
      editAvatar,
      save
    }
  }
}
</script>
<style lang="scss">
.user-profile {
  padding: 0;
  background-color: #E5E5E5;
  height: 100%;
  padding-top: 126px;
  padding-bottom: 26px;
  margin: 0;
  .v-chip {
    height: 48px;
  }
  .avatar-container {
    position: relative;
    display: inline-block;
  }
  .edit-avatar-btn {
    position: absolute;
    bottom: 10%;
    right: 0%;
    border: 4.367px solid white !important;
  }
  .profile-item {
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background-color: #FFFFFF;

    .sub-title-item {
      font-size: 13px;
      font-weight: 400;
      color: #9E9E9E;
    }

    .title-item {
      font-size: 17px;
      font-weight: 500;
      color: #212121;
    }
  }
}
</style>

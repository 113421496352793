<template>
  <v-row
    align="start" justify="center" class="mt-15"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <confirm-delete ref="deleteDialog" />
    <v-col
      cols="12" lg="8" md="10"
      class="row align-start justify-start"
    >
      <v-col
        cols="12"
        class="page-title mms-4 mt-15 mt-sm-8"
      >
        {{ $t('push_history.title') }}
      </v-col>
      <v-col cols="12">
        <push-notifications-history-filters
          :api-key="apiKey"
        />
      </v-col>
      <v-col v-if="isLoading" cols="12">
        <v-skeleton-loader
          v-for="n in 5" :key="n" class="mt-3"
          type="list-item-two-line"
        />
      </v-col>
      <v-row
        v-else align="start" no-gutters
        justify="start" class="mb-10"
      >
        <v-col cols="12">
          <push-notifications-history-list
            :api-key="apiKey"
            :push-history-list="pushHistoryList"
            @toggle-viewed="onToggleViewed"
            @delete-click="onDeleteClick"
          />
        </v-col>
        <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect" />
        <v-col v-if="isLoadingNextPage" cols="12">
          <v-skeleton-loader
            v-for="n in 3" :key="n" class="mt-3"
            type="list-item-two-line"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

import PushNotificationsHistoryFilters from '@/components/push_notifications_history_page/PushNotificationsHistoryFilters.vue'
import PushNotificationsHistoryList from '@/components/shared/PushNotificationsHistoryList.vue'

import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useAuth from '@/composables/useAuth.js'
import usePushNotificationsHistory from '@/composables/usePushNotificationsHistory.js'
import usePushNotificationHistoryTopBar from '@/composables/usePushNotificationHistoryTopBar.js'

import { isRtl } from '@/i18n'
import { computed, ref } from 'vue'

export default {
  components: {
    'push-notifications-history-filters': PushNotificationsHistoryFilters,
    'push-notifications-history-list': PushNotificationsHistoryList,
    'confirm-delete': ConfirmDelete
  },
  setup () {
    const deleteDialog = ref(null)
    const { apiKey } = useAuth()
    const {
      isLoading, isLoadingNextPage, pushHistoryList, pagination,
      loadPushHistory, loadNextPage, toggleViewed, deletePush
    } = usePushNotificationsHistory(apiKey.value)
    const { markAsSeen } = usePushNotificationHistoryTopBar(apiKey.value)

    // Methods
    const onIntersect = loadNextPage
    const onToggleViewed = toggleViewed

    const onDeleteClick = (push) => {
      deleteDialog.value.open()
        .then((shouldDelete) => {
          if (!shouldDelete) return

          deletePush(push)
            .then(() => deleteDialog.value.close())
        })
    }

    // Computed
    const showLoadMorePages = computed(() => {
      if (isLoading.value === true || isLoadingNextPage.value) return false

      const { page, totalPages } = pagination.value
      return page < totalPages
    })

    loadPushHistory({ reset: true }).then(markAsSeen)
    return {
      isRtl,
      isLoading,
      pushHistoryList,
      apiKey,
      deleteDialog,
      showLoadMorePages,
      isLoadingNextPage,
      onIntersect,
      onToggleViewed,
      onDeleteClick
    }
  }
}
</script>

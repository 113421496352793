<template>
  <v-dialog
    v-model="dialog" max-width="510" persistent
    scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="overflow-y-hidden popup-card bg-color17 employee-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="scrollable">
        <v-form ref="newEmployeeForm">
          <v-row align="start" justify="space-around" no-gutters>
            <v-col v-if="isCreateMode" cols="11" class="mt-4">
              <div class="bg-color16 rounded-md px-3 d-flex justify-space-between align-center">
                <span class="color5 t-500-16">
                  <v-icon>mail</v-icon>
                  {{ $t('employees_dialog.send_invitation') }}
                </span>
                <v-switch v-model="inviteUser" class="ltr" color="#0040FF" />
              </div>
            </v-col>
            <v-col cols="11" class="mt-4">
              <v-text-field
                v-model="newEmployee.first_name"
                counter="80"
                class="required"
                outlined
                background-color="white"
                :label="$t('employees_dialog.first_name')"
              />
            </v-col>
            <v-col cols="11">
              <v-text-field
                v-model="newEmployee.last_name"
                counter="80"
                class="required"
                outlined
                background-color="white"
                :label="$t('employees_dialog.last_name')"
              />
            </v-col>
            <v-col cols="11">
              <v-text-field
                v-model="newEmployee.phone"
                v-mask="'####################'"
                counter="20"
                class="required"
                outlined
                background-color="white"
                :label="$t('employees_dialog.phone')"
              />
            </v-col>
            <v-col cols="11">
              <v-text-field
                v-model="newEmployee.email"
                counter="320"
                outlined
                background-color="white"
                :label="$t('email')"
              />
            </v-col>
            <v-col v-if="!staffOnlyRoute" cols="11">
              <date-picker-select-field
                v-model="newEmployee.birthdate"
                :label="$t('employees_dialog.birth_date')"
              />
            </v-col>
            <v-col cols="11">
              <span class="role-select">
                *
              </span>
              {{ $t('employees_dialog.user_role') }}
            </v-col>
            <v-col cols="11" class="mt-2">
              <role-select v-model="newEmployee.role" />
            </v-col>
            <v-col v-if="showAvailableJobSelect" cols="11" class="mt-3">
              <job-selection-search
                v-model="allowedJobs" multiple
                :api-key="apiKey"
                :hide-shift="staffOnlyRoute"
                :search-for-operation="(isUserAccountManager || staffOnlyRoute)"
                background-color="white"
                hide-details
                height="50"
                outlined
                :label="$t('employees_dialog.choose_jobs_placeholder')"
              />
            </v-col>
            <v-col
              v-if="showCompanyDistrictSelect" cols="11" class="mt-4"
            >
              <company-district-select
                v-model="allowedCompanyDistricts"
                :api-key="apiKey"
                :show-empty-area="false"
                background-color="white"
                class="required"
                hide-details
                outlined
                :dense="false"
              />
            </v-col>
            <v-col v-if="showDepartmentSelect" cols="11" class="mt-4">
              <account-department-select
                v-model="accountDepartments"
                :api-key="apiKey"
                :is-required="showDepartmentSelect"
                multiple
                hide-details
                outlined
                background-color="white"
              />
            </v-col>
            <v-col v-if="showFeatureSelect" cols="11" class="mt-2">
              <employee-feature-select v-model="userFeatures" />
            </v-col>
            <v-col v-if="(showAvailableJobSelect || showDepartmentSelect) && $vuetify.breakpoint.smAndUp" sm="5" />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="errorSnackBar" :timeout="3000">
      <div class="text-center">
        {{ $t('employees_dialog.duplicate_phone') }}
      </div>
    </v-snackbar>
  </v-dialog>
</template>
<script>

import { newEmployeeModel } from '@/store/modules/Employee.js'

import CompanyDistrictSelect from '@/components/shared/CompanyDistrictSelect.vue'
import useEmployeeStore from '@/components/employees/useEmployeeStore.js'
import EmployeeFeatureSelect from '@/components/employees/EmployeeFeatureSelect.vue'
import RoleSelect from '@/components/employees/new_employee_dialog/RoleSelect.vue'

import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'
import DatePickerSelectField from '@/components/shared/DatePickerSelectField.vue'

import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'

import { mask } from 'vue-the-mask'

import { useRoute } from 'vue-router/composables'

import { ref, computed } from 'vue'

const DialogMode = { create: 0, update: 1 }

export default {
  components: {
    'job-selection-search': JobSelectionSearch,
    'account-department-select': AccountDepartmentSelect,
    'employee-feature-select': EmployeeFeatureSelect,
    'company-district-select': CompanyDistrictSelect,
    'role-select': RoleSelect,
    'date-picker-select-field': DatePickerSelectField
  },
  directives: {
    mask
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    allowSetFeatures: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    let oldFeatures = []
    const dialog = ref(false)
    const dialogMode = ref(null)
    const newEmployee = ref(newEmployeeModel({}))
    const isLoading = ref(false)
    const allowedJobs = ref([])
    const allowedCompanyDistricts = ref([])
    const accountDepartments = ref([])
    const errorSnackBar = ref(false)
    const newEmployeeForm = ref(null)
    const userFeatures = ref([])
    const inviteUser = ref(false)
    const { isUserAccountManager } = useAuth()
    const { createUser, updateUser, featureForUser, updateUserFeatures, inviteUser: inviteUserApiCall } = useEmployeeStore(props.apiKey)
    const router = useRoute()

    const featuresChanged = () => {
      const newFeatures = userFeatures.value
      const isFeatureRemainTheSame = oldFeatures.length === newFeatures.length && oldFeatures.every((value, index) => value === newFeatures[index])
      return !isFeatureRemainTheSame
    }

    const openDialog = (employee, dialogModeVal) => {
      dialog.value = true
      dialogMode.value = dialogModeVal
      newEmployee.value = newEmployeeModel(employee)

      allowedJobs.value = (newEmployee.value.user_allowed_jobs || []).map((allowedJob) => allowedJob.id)
      allowedCompanyDistricts.value = (newEmployee.value.user_allowed_company_districts || [])
      accountDepartments.value = newEmployee.value.account_department_ids
    }

    const userRequestParams = () => {
      const requestParams = newEmployee.value.serialize()
      if (showAvailableJobSelect.value) {
        requestParams.allowed_job_ids = allowedJobs.value
      }
      if (showCompanyDistrictSelect.value) {
        requestParams.allowed_company_district = allowedCompanyDistricts.value
      }
      if (showDepartmentSelect.value) {
        requestParams.account_department_ids = accountDepartments.value
          .filter((department) => Number.isInteger(department) && department > 0)
      }
      return requestParams
    }

    const requestUpdateParams = () => {
      const creationParams = userRequestParams()
      creationParams.role = newEmployee.value.role
      return { apiKey: props.apiKey, user: creationParams, userId: newEmployee.value.id }
    }

    const showErrors = (errors) => {
      if (!Array.isArray(errors)) return
      if (!errors.some((err) => err === 'phone_taken')) return
      errorSnackBar.value = true
    }

    const updateEmployee = () => {
      const requestParams = requestUpdateParams()
      return updateUser(requestParams)
        .catch((err) => {
          showErrors(err?.response?.data?.errors)
          errHandler(err)
          return { error: true }
        })
        .finally(() => (isLoading.value = false))
    }

    const requestCreateParams = () => {
      const creationParams = userRequestParams()
      creationParams.role = newEmployee.value.role
      return { apiKey: props.apiKey, user: creationParams }
    }

    const createNewEmployee = () => {
      const requestParams = requestCreateParams()
      return createUser(requestParams)
        .catch((err) => {
          showErrors(err?.response?.data?.errors)
          errHandler(err)
          return { error: true }
        })
        .finally(() => (isLoading.value = false))
    }
    // Methods

    const openForUpdate = (employee) => {
      openDialog(employee, DialogMode.update)
      if (showFeatureSelect.value) {
        oldFeatures = featureForUser(employee.id)
        userFeatures.value = oldFeatures.slice()
      }
    }

    const openForCreate = (isStaff) => {
      const employee = {}
      if (!isStaff) {
        userFeatures.value = ['broken_item_close', 'broken_item_create', 'broken_item_update']
      }
      return openDialog(employee, DialogMode.create)
    }
    const close = () => {
      dialogMode.value = null
      dialog.value = false
      allowedJobs.value = []
      accountDepartments.value = []
      newEmployeeForm.value.reset()
      newEmployee.value = newEmployeeModel({})
      userFeatures.value = []
      oldFeatures = []
    }

    const updateUserImp = async () => {
      const userId = newEmployee.value.id
      if (showFeatureSelect.value === true && featuresChanged() && Number.isInteger(userId)) {
        await updateUserFeatures(userId, userFeatures.value)
      }
      return updateEmployee()
    }

    const createUserImp = async () => {
      const newUser = await createNewEmployee()
      const userId = newUser?.id
      const isValidUser = Number.isInteger(userId)
      if (showFeatureSelect.value === true && userFeatures.value.length > 0 && isValidUser) {
        await updateUserFeatures(userId, userFeatures.value)
      }
      if (isValidUser && inviteUser.value === true) {
        const requestParams = {
          userId,
          apiKey: props.apiKey,
          language: i18n.locale
        }
        await inviteUserApiCall(requestParams)
      }
      return newUser
    }

    const save = async () => {
      if (!newEmployeeForm.value.validate()) return

      isLoading.value = true
      let isError = false
      const response = dialogMode.value === DialogMode.update ? await updateUserImp() : await createUserImp()
      isLoading.value = false
      isError = response?.error

      if (isError) return
      close()
    }

    // Computed

    const isCreateMode = computed(() => dialogMode.value === DialogMode.create)
    const dialogTitle = computed(() => isCreateMode.value ? i18n.t('employees_dialog.create_title') : i18n.t('employees_dialog.update_title'))
    const showCompanyDistrictSelect = computed(() => newEmployee.value.isCompanyRegionAdmin())
    const staffOnlyRoute = computed(() => router.meta.staffOnly)
    const showAvailableJobSelect = computed(() => newEmployee.value.isAccountEmployee() || staffOnlyRoute.value)
    const showDepartmentSelect = computed(() => newEmployee.value.isAccountManager())
    const showFeatureSelect = computed(() => props.allowSetFeatures && (newEmployee.value.isAccountManager() || newEmployee.value.isAccountEmployee()))
    const isSaveDisabled = computed(() => {
      if (!newEmployee.value.valid()) return true
      if (isLoading.value) return true
      if (!newEmployee.value.hasPhoneNumber()) return true
      if (newEmployee.value.isAccountManager() && !accountDepartments.value.length > 0) return true
      if (newEmployee.value.isCompanyRegionAdmin() && !allowedCompanyDistricts.value.length > 0) return true
      return false
    })
    return {
      dialog,
      newEmployee,
      isLoading,
      allowedJobs,
      allowedCompanyDistricts,
      accountDepartments,
      errorSnackBar,
      inviteUser,
      isRtl,
      isCreateMode,
      newEmployeeForm,
      dialogTitle,
      isSaveDisabled,
      staffOnlyRoute,
      showDepartmentSelect,
      showAvailableJobSelect,
      showCompanyDistrictSelect,
      isUserAccountManager,
      showFeatureSelect,
      userFeatures,
      openForUpdate,
      openForCreate,
      close,
      save
    }
  }
}
</script>
<style lang="scss">
.employee-dialog {
  .role-select {
    color: #D25455;
  }
  @media only screen and (max-width: 600px) {
    .scrollable {
      overflow: auto;
      height: 100%;
      max-height: 400px;
    }
  }
}
</style>

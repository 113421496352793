<template>
  <v-row align="start" justify="center" class="full-height text-center">
    <v-col cols="8">
      <v-img
        :src="emptyTaskImage" contain max-width="250"
        class="mx-auto"
      />
      <div class="color10 t-700-16">
        {{ emptyText }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    emptyText: {
      type: String,
      required: true
    },
    sourceImage: {
      type: String,
      default: 'empty_tasks.png'
    }
  },
  setup (props) {
    let emptyTaskImage = new URL('@/assets/empty_tasks.png', import.meta.url).href
    if (props.sourceImage === 'calendar.png') {
      emptyTaskImage = new URL('@/assets/calendar.png', import.meta.url).href
    }
    return {
      emptyTaskImage
    }
  }
}
</script>

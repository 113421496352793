<template>
  <v-dialog v-model="dialog" :max-width="imageMaxWidth" @click:outside="onClose">
    <v-card
      class="media-preview-display"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-text class="pa-0">
        <v-carousel
          v-model="startIndex"
          :show-arrows="false" class="media-preview-carousel" :height="imageMaxHeight"
        >
          <v-btn
            icon
            class="close-btn"
            @click="close"
          >
            <v-icon color="black">
              close
            </v-icon>
          </v-btn>
          <v-carousel-item
            v-for="(media, index) in mediaRefs"
            :key="index"
          >
            <v-btn
              icon
              class="download-btn" @click="downloadImage(media)"
            >
              <v-icon color="black">
                download
              </v-icon>
            </v-btn>
            <v-row justify="center" align="center" class="full-height">
              <pinch-zoom v-if="media.type === 'image'" ref="pinchZoomRef">
                <v-col class="px-0">
                  <image-with-fallback
                    :class="{ 'zoomable': !isZoomed(index) }"
                    :src="betterImageQuality(media.url)"
                    :max-height="imageMaxHeight"
                    :max-width="imageMaxWidth"
                    contain
                    @click="onImageClick(index)"
                  >
                    <template #placeholder>
                      <v-row
                        align="center" justify="center" class="fill-height"
                        no-gutters
                      >
                        <v-progress-circular indeterminate color="primary" />
                      </v-row>
                    </template>
                  </image-with-fallback>
                </v-col>
              </pinch-zoom>
              <video v-else controls class="attached-video">
                <source :src="media.url">
                Your browser does not support the video tag.
              </video>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ImageWithFallback from '@/components/shared/ImageWithFallback.vue'

import { betterImageQuality, downloadFile } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import Vuetify from '@/plugins/vuetify.js'
import PinchZoom from 'vue-pinch-zoom'
import { isRtl } from '@/i18n'

import { ref, computed } from 'vue'
const dialog = ref(false)
const mediaRefs = ref([])
const startIndex = ref(0)
export default {
  components: {
    'pinch-zoom': PinchZoom,
    'image-with-fallback': ImageWithFallback
  },
  setup (_, { emit }) {
    const pinchZoomRef = ref(null)

    /// Methods
    function openDialog (mediaObjects) {
      dialog.value = true
      mediaRefs.value = mediaObjects
    }

    const onClose = () => {
      mediaRefs.value = []
      startIndex.value = 0
    }

    const close = () => {
      dialog.value = false
      onClose()
    }
    const reset = () => {
      emit('reset')
      close()
    }

    const downloadImage = async (media) => {
      try {
        downloadFile(media.url)
      } catch (error) {
        errHandler(error)
        const newTab = window.open(media.url, '_blank')
        if (newTab) {
          newTab.focus() // Focus on the new tab if it successfully opens
        }
      }
    }

    const onImageClick = (index) => {
      if (pinchZoomRef.value && pinchZoomRef.value[index]) {
        pinchZoomRef.value[index].toggleZoom()
      }
    }

    const isZoomed = (index) => {
      if (pinchZoomRef.value && pinchZoomRef.value[index]) {
        return pinchZoomRef.value[index].isZoomedIn
      }
      return false
    }

    /// Computed
    const imageMaxWidth = computed(() => Vuetify.framework.breakpoint.xsOnly ? '90vw' : '70vw')
    const imageMaxHeight = computed(() => Vuetify.framework.breakpoint.xsOnly ? '80vh' : '70vh')

    return {
      dialog,
      mediaRefs,
      startIndex,
      imageMaxWidth,
      imageMaxHeight,
      isRtl,
      pinchZoomRef,
      isZoomed,
      onImageClick,
      downloadImage,
      betterImageQuality,
      openDialog,
      onClose,
      close,
      reset
    }
  }
}
</script>
<style lang="scss" scoped>
.media-preview-display {
  .media-preview-carousel {
    position: relative;

    .zoomable {
      cursor: zoom-in;
    }
    .close-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      left: 8px
    }
    .download-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      left: 48px
    }

    .attached-video {
      max-width: 80%;
      max-height: 90%;
    }
  }
}
.rtl.media-preview-display {
  .media-preview-carousel {
    .close-btn {
      right: 8px;
      left: auto;
    }
    .download-btn {
      right: 48px;
      left: auto;
    }
  }
}
</style>

<template>
  <div v-if="showDay" class="justify-center align-center row py-2 px-1 no-gutters">
    <div
      v-for="(event, index) in dayEvents" :key="index" v-ripple
      class="single-event text-truncate mb-2 texts-start"
      :style="{ 'background-color': event.color }" @click="onClick(event)"
    >
      <job-calander-single-event :event="event" />
    </div>
  </div>
</template>
<script>
import JobCalanderSingleEvent from '@/components/jobs_calander/JobCalanderSingleEvent.vue'

import { computed } from 'vue'

export default {
  components: {
    'job-calander-single-event': JobCalanderSingleEvent
  },
  props: {
    dayEvents: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const showDay = computed(() => props.dayEvents.length > 0)

    // Methods
    const onClick = (event) => emit('click', { event })

    // Computed
    return {
      showDay,
      onClick
    }
  }
}
</script>
<style scoped lang="scss">
.v-event.monthly-day {
  width: 95%;
  margin-bottom: 8px;
}
</style>

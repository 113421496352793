<template>
  <v-autocomplete
    :value="value"
    :items="selectEntries"
    :loading="selectIsLoading"
    :search-input.sync="searchTermValue"
    clearable
    return-object
    :multiple="multiple"
    :hide-selected="!multiple"
    append-icon="search"
    class="item-search"
    color="black"
    :no-data-text="$t('no_result')"
    item-value="id"
    item-text="name"
    :disabled="disabled"
    :menu-props="menuPropsWithClass"
    :placeholder="placeholder"
    :background-color="backgroundColor"
    :outlined="outlined"
    @update:search-input="onSearch"
    @change="emitChange"
    @click:clear="clear"
    @input="update"
  >
    <template v-if="$scopedSlots['prepend-item']" #prepend-item>
      <slot name="prepend-item" :update="update" />
    </template>
    <template #item="{ item: user, attrs }">
      <span v-if="!multiple" class="font-weight-bold"> {{ user.name }}</span>
      <v-row v-else align="center" justify="start">
        <v-col class="flex-grow-1 flex-shrink-0">
          <div class="t-700-16">
            {{ user.name }}
          </div>
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
    </template>
    <template #selection="{ item }">
      <v-chip
        v-if="multiple" :key="item.id"
        :color="chipColor"
      >
        {{ item.name }}
        <v-icon @click="removeSelection(item.id)">
          cancel
        </v-icon>
      </v-chip>
      <span v-else class="font-weight-bold"> {{ item.name }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
import UserSearchStore from '@/components/shared/user_search/UserSearchStore.js'
import { search as userSearch, searchCrossAccount as usersSearchCrossAccount } from '@/api_client/User.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n'

import { ref, computed, watch } from 'vue'

export default {
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    apiKey: {
      type: String,
      required: true
    },
    corssAccountUsers: {
      type: Boolean,
      default: false,
      required: false
    },
    withHqUsers: {
      type: Boolean,
      default: false,
      required: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    placeholder: {
      type: String,
      required: false,
      default: function () {
        return this.$i18n.t('user_search.placeholder')
      }
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    chipColor: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup (props, { emit }) {
    const entries = ref([])
    const isSearchLoading = ref(false)
    const showSearchResults = ref(false)
    const searchTermValue = ref('')
    const debounceTimeout = ref(null)

    const debounce = (func, delay) => {
      if (debounceTimeout.value) {
        clearTimeout(debounceTimeout.value)
      }
      debounceTimeout.value = setTimeout(func, delay)
    }

    const {
      loadAllUsers, loadAllUsersWithHq, loadAllUsersCrossAccount, changeApiKey,
      allUsers, allUsersWithHQ, allUsersCrossAccount, isLoading, isLoadingUsersWithHQ, isLoadingCrossAccount
    } = UserSearchStore(props.apiKey)

    const loadUsers = () => {
      if (props.corssAccountUsers === true) {
        loadAllUsersCrossAccount()
      } else if (props.withHqUsers) {
        loadAllUsersWithHq()
      } else {
        loadAllUsers()
      }
    }

    const userNameFunction = (user) => {
      user.name = `${user.first_name} ${user.last_name}`
      return user
    }

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => {
      emit('change', newValue)
      searchTermValue.value = ''
    }
    const clear = () => emit('click:clear')
    const removeSelection = (userId) => {
      const newValue = props.value.filter((user) => user.id !== userId)
      update(newValue)
    }

    const onSearch = (term) => {
      debounce(() => {
        if (isSearchLoading.value) return
        const isValid = isString(term) && term.trim().length > 0
        if (!isValid) {
          showSearchResults.value = false
          entries.value.splice(0, entries.value.length)
          return
        }
        isSearchLoading.value = true
        showSearchResults.value = true
        const promise = props.corssAccountUsers === true
          ? usersSearchCrossAccount({ apiKey: props.apiKey, searchTerm: term })
          : userSearch({ apiKey: props.apiKey, searchTerm: term, withHq: props.withHqUsers })
        promise
          .then(response => {
            const entriesVal = entries.value
            entriesVal.splice(0, entriesVal.length)
            response.data.data.forEach((user) => entriesVal.push(userNameFunction(user)))
          })
          .catch(errHandler)
          .finally(() => (isSearchLoading.value = false))
      }, 300)
    }

    // Computed
    const searchLoading = computed(() => {
      if (props.corssAccountUsers === true) return isLoadingCrossAccount.value
      if (props.withHqUsers === true) return isLoadingUsersWithHQ.value
      return isLoading.value
    })

    const selectIsLoading = computed(() => isSearchLoading.value || searchLoading.value)
    const allUsersWithName = computed(() => {
      let allUsersVal = null
      if (props.corssAccountUsers === true) {
        allUsersVal = allUsersCrossAccount.value
      } else if (props.withHqUsers) {
        allUsersVal = allUsersWithHQ.value
      } else {
        allUsersVal = allUsers.value
      }
      return allUsersVal.map(userNameFunction)
    })

    const selectEntries = computed(() => {
      if (searchLoading.value === true) return []
      const result = showSearchResults.value === true ? entries : allUsersWithName
      if (isObject(props.value) && Number.isInteger(props.value.id)) {
        const isUserInList = result.value.find((user) => user.id === props.value.id)
        if (!isUserInList) {
          result.value.unshift(props.value)
        }
      }
      return result.value
    })

    const menuPropsWithClass = computed(() => {
      const result = isObject(props.menuProps) ? Object.assign(props.menuProps, {}) : {}
      result.contentClass = isString(result.contentClass) ? result.contentClass : ''
      result.contentClass = `${result.contentClass} ${isRtl.value ? 'rtl' : 'ltr'}`
      return result
    })

    const userId = props.multiple ? null : (props.value || {}).id
    if (Number.isInteger(userId)) {
      entries.value.splice(0, entries.value.length)
      entries.value.push(props.value)
    }

    watch(() => props.apiKey, (newValue) => {
      if (!isString(newValue)) return
      changeApiKey(newValue)
      loadUsers()
    })

    loadUsers()

    return {
      menuPropsWithClass,
      update,
      emitChange,
      clear,
      selectIsLoading,
      selectEntries,
      removeSelection,
      onSearch,
      searchTermValue
    }
  }
}
</script>
<style lang="scss">
.item-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>

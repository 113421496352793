<template>
  <v-row
    align="start" justify="start" class="broken-item-dialog mt-2 px-2 t-500-13 color5 pointer"
    :style="{ 'background-color': backgroundColor }" :class="{ 'pointer': editEnabled }"
    @click="onEditClick"
  >
    <v-col cols="12" class="show-line-break t-700-14 py-2">
      {{ brokenItem.description }}
    </v-col>
    <v-col cols="10" class="row align-center justify-start no-gutters pt-0">
      <v-col class="flex-grow-0 flex-shrink-1 ppe-2">
        <v-icon color="black">
          back_hand
        </v-icon>
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-0">
        {{ $t('broken_item.created_by') }}
        <span>
          {{ brokenItem.creator_first_name }} {{ brokenItem.creator_last_name }}
        </span>
        <div>
          {{ brokenItem.open_date_user_format }}
          <span v-if="hasOpenTime">{{ brokenItem.open_time }} </span>
        </div>
      </v-col>
    </v-col>
    <v-col cols="2" class="row align-center justify-end pa-0">
      <v-badge
        color="red"
        :value="hasMessages"
        :content="brokenItem.chat_messages_count"
        overlap
      >
        <v-btn small icon @click.stop="onChatClick">
          <v-icon color="black">
            message
          </v-icon>
        </v-btn>
      </v-badge>
      <v-menu v-if="showOptions" min-width="100">
        <template #activator="{ on }">
          <v-btn small icon v-on="on">
            <v-icon color="black">
              more_vert
            </v-icon>
          </v-btn>
        </template>
        <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item
            v-if="editEnabled"
            class="px-2"
            @click="onEditClick"
          >
            <v-list-item-content>
              {{ $t('edit') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small>
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item
            v-if="deleteEnabled" class="px-2"
            @click="onDeleteClick"
          >
            <v-list-item-content>
              {{ $t('delete') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small>
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col v-if="isClosed" cols="12" class="row align-center justify-start no-gutters pt-1">
      <v-col class="flex-grow-0 flex-shrink-1 ppe-2">
        <v-icon color="black">
          lock
        </v-icon>
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-0">
        <span>
          {{ brokenItem.closing_user_first_name }} {{ brokenItem.closing_user_last_name }}
        </span>
        <div>
          {{ brokenItem.close_date_user_format }}
          <span v-if="hasCloseTime">{{ brokenItem.close_time }} </span>
        </div>
      </v-col>
    </v-col>
    <v-col v-if="hasPrice" cols="12" class="pt-1">
      <v-icon color="black" class="ppe-2">
        local_atm
      </v-icon> {{ brokenItem.price }}
    </v-col>
    <v-col v-if="!isClosed" cols="12" class="pt-1">
      <v-menu offset-y top>
        <template #activator="{ on, attrs }">
          <v-chip
            v-bind="attrs" small class="status-chip"
            v-on="on"
          >
            {{ statusName }}
            <v-icon v-if="editEnabled">
              keyboard_arrow_down
            </v-icon>
          </v-chip>
        </template>
        <v-list v-if="editEnabled" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item
            v-for="status in selectStatusOptions"
            :key="status.name"
            @click="onChangeStatus(status.name)"
          >
            <v-list-item-title>{{ status.translatedName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import { statusColors, statuses, getAllStatuses } from '@/store/modules/BrokenItem.js'
import { isString } from '@/helpers/Utils.js'

import { isRtl, i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    brokenItem: {
      type: Object,
      required: true
    },
    deleteEnabled: {
      type: Boolean,
      default: false
    },
    editEnabled: {
      type: Boolean,
      default: false
    },
    closeEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // Methods
    const onChatClick = () => emit('open-chat', props.brokenItem)
    const onEditClick = () => {
      if (!props.editEnabled) return
      emit('edit-click', props.brokenItem)
    }
    const onDeleteClick = () => emit('delete-click', props.brokenItem)

    const onChangeStatus = (status) => {
      if (status === props.brokenItem.status) return
      emit('change-status', { brokenItem: props.brokenItem, status })
    }

    // Computed
    const backgroundColor = computed(() => statusColors[props.brokenItem.status])
    const hasOpenTime = computed(() => isString(props.brokenItem.open_time))
    const hasCloseTime = computed(() => isString(props.brokenItem.close_time))
    const isClosed = computed(() => props.brokenItem.status === statuses.close)
    const hasPrice = computed(() => Number.isInteger(props.brokenItem.price) && props.brokenItem.price > 0)
    const hasMessages = computed(() => Number.isInteger(props.brokenItem.chat_messages_count) && props.brokenItem.chat_messages_count > 0)
    const showOptions = computed(() => props.editEnabled || props.deleteEnabled)
    const selectStatusOptions = computed(() => {
      const closeTranslation = i18n.t('broken_item.close_ticket')
      return getAllStatuses({ addClosedStatus: props.closeEnabled, closeTranslation })
    })

    const statusName = computed(() => {
      const status = props.brokenItem.status
      return selectStatusOptions.value.find(statusRow => statusRow.name === status)?.translatedName
    })

    return {
      backgroundColor,
      hasOpenTime,
      hasCloseTime,
      isClosed,
      hasPrice,
      hasMessages,
      showOptions,
      statusName,
      isRtl,
      selectStatusOptions,
      onChatClick,
      onEditClick,
      onDeleteClick,
      onChangeStatus
    }
  }
}
</script>
<style lang="scss">
.broken-item-dialog {
  border-radius: 8px;

  .status-chip {
    background-color: #FFF !important;
  }
}
</style>

<template>
  <v-col v-if="isLoadingTasks" class="flex-grow-1 flex-shrink-0 mb-8 tasks-list">
    <v-skeleton-loader
      v-for="i in 3" :key="i" class="single-task"
      type="list-item-three-line, actions"
    />
  </v-col>
  <v-col v-else-if="isEmptyPage" class="flex-grow-1 flex-shrink-0 full-height">
    <empty-page :empty-text="$t('tasks_page.empty_tasks')" />
  </v-col>
  <v-col v-else class="flex-grow-1 flex-shrink-0 mb-8 tasks-list">
    <div
      v-if="hasActiveJob && showJobName" class="t-800-20 pps-4 color5 text-center"
      :class="{ 'mb-3': staffAccount }"
    >
      {{ jobNameAndShift }}
      <span class="color1 ltr d-inline-block t-700-18 mx-2">
        {{ totalTasksDone }} / {{ totalTasks }}
      </span>
      <div v-if="showDepartment" class="t-700-14">
        {{ departmentName }}
      </div>
    </div>
    <template v-for="part in tasksByShift">
      <template v-for="task in part">
        <div v-if="showHeader(task)" :key="task.name" class="t-800-20 pps-4 mb-1 mt-3 color5 header">
          {{ task.translatedlName }}
        </div>
        <single-task-layout
          v-else-if="!task.header"
          :key="task.id"
          :client-date="clientDateIso"
          :task="task"
          :task-enabled-options="taskOptions"
          :disabled="tasksDisabled"
          :allow-toggle-complete="allowToggleTaskComplete"
        />
      </template>
    </template>
    <v-col v-if="showLoadMorePages" v-intersect="onIntersect" />
    <v-col v-if="isLoadingTasksNextPage" cols="12">
      <v-skeleton-loader
        v-for="i in 3" :key="i" class="single-task"
        type="list-item-three-line, actions"
      />
    </v-col>
  </v-col>
</template>
<script>

import SingleTaskLayout from '@/components/tasks/tasks_list/single_task/SingleTaskLayout.vue'
import EmptyPage from '@/components/shared/EmptyPage.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'
import useAuth from '@/composables/useAuth.js'
import { translateShift } from '@/store/modules/OrganizationJob.js'

import { FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'

const shiftPartToIndex = { start: 0, middle: 1, during_shift: 2, end: 3, shift_transfer: 4, done: 5 }

const shiftParts = () =>
  [
    [{ header: true, name: 'start', translatedlName: i18n.t('shift_start') }],
    [{ header: true, name: 'middle', translatedlName: i18n.t('shift_middle') }],
    [{ header: true, name: 'during_shift', translatedlName: i18n.t('during_shift') }],
    [{ header: true, name: 'end', translatedlName: i18n.t('shift_end') }],
    [{ header: true, name: 'shift_transfer', translatedlName: i18n.t('shift_transfer') }],
    [{ header: true, name: 'done', translatedlName: i18n.t('done') }]
  ]

export default {
  components: {
    'single-task-layout': SingleTaskLayout,
    'empty-page': EmptyPage
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    },
    showJobName: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const {
      activeJobTasks, isLoadingTasks, tasksDisabled, clientDateIso, activeJob, isLoadingTasksNextPage, isEmptyPage,
      allowToggleTaskComplete, loadActiveJobTasksNextPage
    } = useDailyJobs(props.apiKey)
    const { isUserAccountEmployee } = useAuth()

    const taskOptions = computed(() => {
      if (isUserAccountEmployee.value) {
        return {
          share: true,
          notification: true
        }
      }

      return {
        edit: true,
        copy: true,
        delete: true,
        share: true,
        notification: true
      }
    })

    // Methods
    const showHeader = (task) => {
      if (task.header && task.name === 'done') return true

      return task.header && !props.staffAccount
    }

    const onIntersect = loadActiveJobTasksNextPage

    // Computed
    const showDepartment = computed(() => isString(activeJob.value?.department_name) && activeJob.value.department_name.length > 0)
    const hasActiveJob = computed(() => isObject(activeJob.value))
    const departmentName = computed(() => hasActiveJob.value ? activeJob.value.department_name : '')
    const jobNameAndShift = computed(() => {
      if (!hasActiveJob.value) return ''
      if (props.staffAccount === true) return activeJob.value.name
      return `${activeJob.value.name} - ${translateShift(activeJob.value.shift)}`
    })
    const totalTasks = computed(() => hasActiveJob.value ? activeJob.value.total_tasks : '')
    const totalTasksDone = computed(() => hasActiveJob.value ? activeJob.value.total_tasks_reported : '')

    const tasksByShift = computed(() => {
      const shiftPartsOrdered = shiftParts()
      const [start, middle, duringShift, end, shiftTransfer, done] = activeJobTasks.value.reduce((acc, task) => {
        const shiftPart = task.operationRef.shift_part
        const frequencyType = task.operationRef.frequency_type
        const isReported = task.isReported()
        let designatedShiftPart = shiftPart
        if (isReported) {
          designatedShiftPart = 'done'
        } else if (frequencyType === FrequencyTypes.ExectDate || frequencyType === FrequencyTypes.EveryYear) {
          designatedShiftPart = 'start'
        }
        const index = shiftPartToIndex[designatedShiftPart]
        acc[index].push(task)
        return acc
      }, shiftPartsOrdered)
      return [start, middle, duringShift, end, shiftTransfer, done].filter((shiftPart) => shiftPart.length > 1)
    })

    const showLoadMorePages = computed(() => {
      if (!isObject(activeJob.value)) return false

      return activeJob.value.total_tasks > activeJob.value.tasks.length
    })
    return {
      isLoadingTasks,
      isEmptyPage,
      jobNameAndShift,
      showDepartment,
      departmentName,
      tasksByShift,
      showLoadMorePages,
      isLoadingTasksNextPage,
      clientDateIso,
      taskOptions,
      tasksDisabled,
      hasActiveJob,
      totalTasks,
      totalTasksDone,
      showHeader,
      allowToggleTaskComplete,
      onIntersect
    }
  }
}
</script>
<style lang="scss">
.tasks-list {
  overflow-y: auto;

  .single-task:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid #DADADA;
  }

  .single-task:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid #DADADA;
    margin-bottom: 8px;
  }

  .single-task:not(:last-child) {
    border-bottom: 1px solid #C9C9C9;
  }

  @media only screen and (max-width: 600px) {
    overflow-y: unset;
  }
}
</style>

<template>
  <v-row align="center" justify="start" class="broken-item-images full-height pb-5">
    <v-col
      v-for="(image, index) in images"
      :key="index" cols="3" class="text-center full-height"
    >
      <div class="pointer pb-0 single-image d-flex align-center justify-center">
        <v-img
          v-if="image.hasImage" width="100%"
          height="100%"
          contain
          class="rounded-lg grey darken-4"
          :src="image.url"
          @click="onImageClick"
        >
          <template #placeholder>
            <v-row
              align="center" justify="center" class="fill-height"
              no-gutters
            >
              <v-progress-circular indeterminate color="primary" />
            </v-row>
          </template>
        </v-img>
        <label v-else :for="`image_${randSeed}_${index}`">
          <v-icon class="material-icons-outlined" color="black"> image </v-icon>
          <input
            :id="`image_${randSeed}_${index}`" type="file" accept="image/*"
            @change="event => addImage(index, event)"
          >
        </label>
        <v-btn
          v-if="image.hasImage" class="delete-btn" icon
          @click="removeImage(index)"
        >
          <v-icon color="white">
            delete
          </v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>

import {
  deleteImages as BrokenItemClientDeleteImages,
  addImages as BrokenItemClientAddImages
} from '@/api_client/BrokenItem.js'

import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import { isFile } from '@/helpers/Utils.js'

import { ref } from 'vue'

const validImageTypes = ['broken_item_images', 'broken_item_fix_images']
const IMAGE_COUNT = { broken_item_images: 4, broken_item_fix_images: 4 }

const defaultImage = () => ({ url: null, originalUploadedFile: null, hasImage: false, imageId: null })

const releaseImageMemory = (image) => {
  if (!image.url) return
  URL.revokeObjectURL(image.url)
  image.url = null
}

const deleteImages = ({ apiKey, deviceId, brokenItemId, imagesToRemove, imageType }) => {
  const imageIds = imagesToRemove.map((image) => image.imageId)

  return BrokenItemClientDeleteImages({ apiKey, deviceId, brokenItemId, imageIds, imageType })
}

const createNewImages = async ({ apiKey, deviceId, brokenItemId, imageUploadSignatures, imagesToAdd, imageType }) => {
  const uploadImageToRemoteServerPromises = []
  imagesToAdd.forEach((image, index) => {
    const imageSignature = imageUploadSignatures[index]
    uploadImageToRemoteServerPromises.push(uploadFileToRemoteServer(image.originalUploadedFile, imageSignature.file_url))
  })
  await Promise.all(uploadImageToRemoteServerPromises)

  const imageNames = imageUploadSignatures.map((signature) => signature.file_name)
  return BrokenItemClientAddImages({ apiKey, deviceId, brokenItemId, imageNames, imageType })
}

export default {
  props: {
    imageTypes: {
      type: String,
      required: true,
      validator: function (value) {
        return validImageTypes.includes(value)
      }
    }
  },
  setup (props, { emit }) {
    const randSeed = ref(Math.floor(Math.random() * 100000))
    const imageCountFromType = IMAGE_COUNT[props.imageTypes]
    const images = ref([...Array(imageCountFromType)].map(() => defaultImage()))

    const getImage = (index) => (images.value[index])

    // Methods
    const onImageClick = () => {
      const mediaPreviewUrls = images.value.filter((image) => image.hasImage).map((image) => ({ url: image.url, type: 'image' }))
      emit('media-click', mediaPreviewUrls)
    }
    const addImage = (index, event) => {
      const file = event.target.files[0]
      if (!file) return
      const image = getImage(index)
      releaseImageMemory(image)

      image.url = URL.createObjectURL(file)
      image.originalUploadedFile = file
      image.hasImage = true
    }

    const removeImage = (imageIndex) => {
      const image = getImage(imageIndex)
      releaseImageMemory(image)
      image.originalUploadedFile = null
      image.hasImage = false
    }

    const imagesToUpload = () => images.value.filter((image) => image.hasImage && isFile(image.originalUploadedFile))

    const imagesToDelete = () => images.value.filter((image) => Number.isInteger(image.imageId) && (isFile(image.originalUploadedFile) || image.url === null))

    const uploadImageCount = () => imagesToUpload().length

    const hasChanges = () => imagesToUpload().length > 0 || imagesToDelete().length > 0

    const saveImages = async ({ apiKey, deviceId, brokenItemId, imageUploadSignatures } = {}) => {
      let response = null
      const imagesToAdd = imagesToUpload()
      const imagesToRemove = imagesToDelete()
      if (imagesToRemove.length > 0) {
        response = await deleteImages({ apiKey, deviceId, brokenItemId, imagesToRemove, imageType: props.imageTypes })
      }
      if (imagesToAdd.length > 0) {
        response = await createNewImages({ apiKey, deviceId, brokenItemId, imageUploadSignatures, imagesToAdd, imageType: props.imageTypes })
      }
      return response
    }

    const reset = () => images.value.forEach((image) => {
      releaseImageMemory(image)
      const newImage = defaultImage()
      for (const attr in newImage) {
        image[attr] = newImage[attr]
      }
    })

    const setImages = (imagesDate) => {
      reset()
      if (Array.isArray(imagesDate) !== true) return

      imagesDate.forEach((imageDate, index) => {
        const image = getImage(index)
        image.url = imageDate.url
        image.hasImage = true
        image.imageId = imageDate.id
      })
    }

    return {
      randSeed,
      images,
      addImage,
      removeImage,
      imagesToDelete,
      uploadImageCount,
      setImages,
      saveImages,
      hasChanges,
      onImageClick
    }
  }
}
</script>
<style lang="scss">
.broken-item-images {
  height: 100px;
  input[type="file"] {
      display: none;
  }
  .single-image {
    aspect-ratio: 1;
    width: 100%;
    max-width: 90px;
    border-radius: 12px;
    border: 2px dashed #616365;
    position: relative;
    .delete-btn {
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }
}
</style>

<template>
  <div v-if="showTopUsers">
    <div class="t-700-16 pps-3 bg-color16 py-2 mb-2">
      {{ $t('frequently_used') }}
    </div>
    <v-row
      v-for="user in userList" :key="user.id"
      align="center"
      justify="start"
      class="pointer"
      @click="onSelectUser(user)"
      @mousedown.prevent
    >
      <v-col class="flex-grow-1 flex-shrink-0">
        <div class="t-700-16">
          <v-icon color="#facc15">
            star
          </v-icon> {{ user.name }}
        </div>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-1">
        <v-icon class="ppe-1">
          {{ isUserSelected(user.id) ? 'check_box' : 'check_box_outline_blank' }}
        </v-icon>
      </v-col>
    </v-row>
    <div class="pps-3 bg-color16 py-1 mt-2">
      {{ $t('all_users') }}
    </div>
  </div>
</template>
<script>
import { topAssignedUsers } from '@/api_client/AccountFaultyDevice.js'
import { isString } from '@/helpers/Utils.js'

import { ref, watch, computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup (props, { emit }) {
    const userList = ref([])

    const loadTopAssignedUsers = () => {
      userList.value = []
      return topAssignedUsers(props.apiKey)
        .then(({ data: { data } }) => {
          userList.value = data.map((user) => {
            user.name = `${user.first_name} ${user.last_name}`
            return user
          })
        })
    }

    // Methods
    const isUserSelected = (userId) => props.value.some((user) => user.id === userId)
    const onSelectUser = (user) => {
      const newUsers = isUserSelected(user.id)
        ? props.value.filter((u) => u.id !== user.id)
        : [...props.value, user]

      emit('input', newUsers)
    }

    // Computed
    const showTopUsers = computed(() => userList.value.length > 0)

    watch(() => props.apiKey, (newValue) => {
      if (!isString(newValue)) return
      loadTopAssignedUsers()
    })

    loadTopAssignedUsers()
    return {
      showTopUsers,
      userList,
      onSelectUser,
      isUserSelected
    }
  }
}
</script>

import Client from '@/api_client/client.js'

const create = (apiKey, { name, groupUsers, withImage }) => {
  const CREATE_GROUP_CHAT_PATH = `/accounts/${apiKey}/chat_groups`
  const params = { chat_group: { name, chat_participants: groupUsers } }
  if (withImage) {
    params.chat_group.with_image = true
    params.image_v2 = true
  }

  return Client.post(CREATE_GROUP_CHAT_PATH, params)
}

const index = (apiKey, { page, perPage }) => {
  const GROUP_CHAT_INDEX_PATH = `/accounts/${apiKey}/chat_groups`
  return Client.get(GROUP_CHAT_INDEX_PATH, { params: { page, per_page: perPage } })
}

const update = (apiKey, { id, name, groupUsers, withImage }) => {
  const GROUP_CHAT_UPDATE_PATH = `/accounts/${apiKey}/chat_groups/${id}`
  const params = { chat_group: { name, chat_participants: groupUsers } }
  if (withImage) {
    params.chat_group.with_image = true
    params.image_v2 = true
  }

  return Client.put(GROUP_CHAT_UPDATE_PATH, params)
}

const destroy = (apiKey, { chatGroupId }) => {
  const GROUP_CHAT_DESTROY_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}`
  return Client.delete(GROUP_CHAT_DESTROY_PATH)
}

const search = (apiKey, { term = null } = {}) => {
  const GROUP_CHAT_SEARCH_PATH = `/accounts/${apiKey}/chat_groups/search`
  return Client.get(GROUP_CHAT_SEARCH_PATH, { params: { name: term } })
}

const searchPrivateChatGroup = (apiKey, { userId } = {}) => {
  const GROUP_CHAT_SEARCH_PRIVATE_PATH = `/accounts/${apiKey}/chat_groups/search_private_chat_group`
  return Client.get(GROUP_CHAT_SEARCH_PRIVATE_PATH, { params: { chat_group: { user_id: userId } } })
}

const findOrCreatePrivateChatGroup = (apiKey, { userId } = {}) => {
  const GROUP_CHAT_SEARCH_PRIVATE_PATH = `/accounts/${apiKey}/chat_groups/find_or_create_private_chat_group`
  return Client.post(GROUP_CHAT_SEARCH_PRIVATE_PATH, { chat_group: { user_id: userId } })
}

const addImage = (apiKey, { id, image }) => {
  const CHAT_GROUP_CREATE_IMAGE_PATH = `/accounts/${apiKey}/chat_groups/${id}/add_image`
  return Client.post(CHAT_GROUP_CREATE_IMAGE_PATH, { chat_group: { image_name: image }, image_v2: true })
}

const removeImage = (apiKey, { id }) => {
  const CHAT_GROUP_DELETE_IMAGE_PATH = `/accounts/${apiKey}/chat_groups/${id}/delete_image`
  return Client.delete(CHAT_GROUP_DELETE_IMAGE_PATH)
}

export {
  create,
  index,
  update,
  destroy,
  search,
  searchPrivateChatGroup,
  findOrCreatePrivateChatGroup,
  addImage,
  removeImage
}

<template>
  <v-dialog
    v-model="dialog" width="510" persistent
    scrollable
    content-class="rounded-xl job-days-dialog"
  >
    <time-selection ref="timeDialogRef" v-model="currentTime" />
    <v-card
      class="overflow-y-hidden bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start pps-1">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center color5 t-600-18">
            {{ $t('org_job_new_dialog.work_days') }}
          </v-col>
          <v-col cols="3" class="texts-end ppe-2 t-400-17 color22">
            <span class="pointer" @click="onReset">
              {{ $t('reset') }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="dialog" class="pa-2">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="10">
            <account-job-create-single-time
              class="white rounded-xl"
              :working-day="allDaysTime"
              :day="allDayDayName"
              @time-click="onTimeClick"
              @day-select="onToggleAllDays"
            />
            <account-job-create-single-time
              v-for="day in daysOfWeek" :key="day.name"
              :working-day="workingDays[day.name]"
              :day="day"
              @time-click="onTimeClick"
              @day-select="onDaySelect"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TimeSelectionDialog from '@/components/account_jobs/account_job_create_dialog/AccountJobsCreateTimeSelectionDialog.vue'
import AccountJobCreateSingleTime from '@/components/account_jobs/account_job_create_dialog/AccountJobCreateSingleTime.vue'

import useAccount from '@/composables/useAccount.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { daysOfTheWeek, isValidTimeString } from '@/helpers/DateTime.js'

import { isRtl, i18n } from '@/i18n.js'
import { ref, computed } from 'vue'
import { isObject } from '@/helpers/Utils'

export default {
  components: {
    'account-job-create-single-time': AccountJobCreateSingleTime,
    'time-selection': TimeSelectionDialog
  },
  setup () {
    const { weekStartDay } = useAccount()
    let resolveFunc = null
    const daysOfWeek = daysOfTheWeek(weekStartDay.value)

    const timeDialogRef = ref(null)
    const dialog = ref(false)
    const currentTime = ref('')
    const currentTimeEdit = {
      day: '',
      isStartTime: false
    }
    const allDayDayName = {
      name: 'daily',
      translatedName: i18n.t('org_job_new_dialog.daily')
    }
    const allDaysTime = ref({
      start_time: '',
      end_time: '',
      selected: false
    })

    const defaultWorkingDays = () => {
      const workDays = {}
      for (const day of daysOfWeek) {
        workDays[day.name] = {
          start_time: '',
          end_time: '',
          selected: false
        }
      }
      return workDays
    }

    const workingDays = ref(defaultWorkingDays())

    const resetWorkDays = () => (workingDays.value = defaultWorkingDays())

    const initWorkDays = (userWorkDays) => {
      if (!isObject(userWorkDays)) {
        resetWorkDays()
        return
      }

      const workingDaysVal = workingDays.value
      for (const day in userWorkDays) {
        const startTime = userWorkDays[day]?.start_time || ''
        const endTime = userWorkDays[day]?.end_time || ''
        workingDaysVal[day].start_time = startTime
        workingDaysVal[day].end_time = endTime
        workingDaysVal[day].selected = isValidTimeString(startTime) && isValidTimeString(endTime)
      }
    }

    const changeAllTimeFields = ({ newTime, isStartTime }) => {
      const field = isStartTime === true ? 'start_time' : 'end_time'
      for (const day in workingDays.value) {
        const currentDay = workingDays.value[day]
        currentDay[field] = newTime
      }
    }

    const reset = () => {
      currentTime.value = ''
      allDaysTime.value = {
        start_time: '',
        end_time: '',
        selected: false
      }
      resetWorkDays()
    }

    // Methods
    const onDaySelect = (dayName) => (workingDays.value[dayName].selected = !workingDays.value[dayName].selected)
    const onAllStartTimeChange = (newTime) => changeAllTimeFields({ newTime, isStartTime: true })
    const onAllEndTimeChange = (newTime) => changeAllTimeFields({ newTime })
    const onToggleAllDays = () => {
      const newSelected = !allDaysTime.value.selected
      allDaysTime.value.selected = newSelected

      for (const day in workingDays.value) {
        workingDays.value[day].selected = newSelected
      }
    }

    const onTimeClick = ({ isStartTime, dayName }) => {
      currentTimeEdit.day = dayName
      currentTimeEdit.isStartTime = isStartTime
      const currentDay = dayName === 'daily' ? allDaysTime.value : workingDays.value[dayName]
      const field = isStartTime === true ? 'start_time' : 'end_time'
      currentTime.value = currentDay[field]

      timeDialogRef.value.open()
        .then(onTimeSelect)
        .catch(errHandler)
    }

    const onTimeSelect = () => {
      const isDaily = currentTimeEdit.day === 'daily'
      const isStartTime = currentTimeEdit.isStartTime === true

      const currentDay = isDaily ? allDaysTime.value : workingDays.value[currentTimeEdit.day]
      const field = isStartTime === true ? 'start_time' : 'end_time'
      currentDay[field] = currentTime.value

      if (!isDaily) return

      isStartTime
        ? onAllStartTimeChange(currentTime.value)
        : onAllEndTimeChange(currentTime.value)
    }

    const openDialog = (workingDaysVal) => {
      initWorkDays(workingDaysVal)
      dialog.value = true
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = (isSavedVal) => {
      const isSaved = isSavedVal === true
      const result = {}

      if (isSaved) {
        for (const day in workingDays.value) {
          if (workingDays.value[day].selected !== true) continue
          result[day] = JSON.parse(JSON.stringify(workingDays.value[day]))
        }
      }

      reset()
      dialog.value = false
      resolveFunc({ isSaved, newWeeklyDays: result })
    }

    const onReset = reset

    const save = () => close(true)

    // Computed

    const isSaveDisabled = computed(() => {
      const workingDaysVal = workingDays.value
      for (const day in workingDays.value) {
        if (workingDays.value[day].selected !== true) continue

        if (!isValidTimeString(workingDaysVal[day].start_time) || !isValidTimeString(workingDaysVal[day].end_time)) {
          return true
        }
      }
      return false
    })

    return {
      dialog,
      isRtl,
      timeDialogRef,
      isSaveDisabled,
      daysOfWeek,
      allDaysTime,
      allDayDayName,
      currentTime,
      workingDays,
      onToggleAllDays,
      openDialog,
      close,
      onReset,
      onAllStartTimeChange,
      onAllEndTimeChange,
      onTimeClick,
      onDaySelect,
      save
    }
  }
}
</script>
<style lang="scss">
.job-days-dialog {
  .shift-time-field {
    margin-top: 0;
    padding-top: 0;
    max-width: 60px;
  }
  .day-name {
    min-width: 60px;
    border-radius: 4px;
    background-color: white;
    padding: 8px 12px;
    text-align: center;
  }
}
</style>

export default {
  save: 'Save',
  cancel: 'Cancel',
  ok: 'Ok',
  delete: 'Delete',
  no_result: 'No results',
  loading: 'Loading',
  exect_date: 'Date tasks',
  daily_operations: 'Daily tasks',
  shift_start: 'Start of shift',
  shift_middle: 'Middle',
  shift_end: 'End of shift',
  shift_transfer: 'Shift transfer',
  during_shift: 'During the shift',
  currnent_day: '{dayString}',
  close: 'Close',
  select_all: 'Select all',
  add: 'Add',
  yes: 'Yes',
  no: 'No',
  title: 'Title',
  report: 'Report',
  create: 'Create',
  edit: 'Edit',
  faults: 'Tickets',
  area: 'Area',
  name: 'name',
  reset: 'Reset',
  all: 'All',
  employee: 'Employees',
  departments: 'Departments',
  account_name: 'Account name',
  time: 'Time',
  today: 'Today',
  yesterday: 'Yesterday',
  this_week: 'This week',
  older: 'Older',
  tasks: 'Tasks',
  one_task: 'One task',
  chat: 'Chat',
  menu: 'Menu',
  download: 'Download',
  copy: 'Copy',
  permissions: 'Permissions',
  email: 'Email',
  notifications: 'Notifications',
  community: 'Community',
  file_attach: 'Add file',
  days: 'Days',
  comments: 'Comments',
  comment: 'Comment',
  comment_1: 'Comment',
  share: 'Share',
  show_more_details: 'Show more details',
  show_more: 'Show more',
  view: 'View',
  info: 'Info',
  total: 'Total',
  done: 'Done',
  not_done: 'Not done',
  untagged: 'Untagged',
  completed: 'Completed',
  on_going: 'On going',
  from: 'From:',
  to: 'To:',
  next: 'NEXT',
  back: 'Back',
  optional: 'Optional *',
  reset_all: 'Reset all filter',
  x_selected: '{count} selected',
  hours: 'Hours',
  x_hours: '%{hours} hr',
  x_days: '%{days} days',
  x_months: '%{months} mo.',
  x_years: '%{years} yr.',
  x_hour_and_minutes: '%{hours} hr %{minutes} min',
  x_minutes: '%{minutes} min',
  new: 'New',
  date: 'Date',
  without_category: 'Without category',
  description: 'Description',
  photos: 'Photos',
  video: 'Video',
  add_file: 'Add file',
  hq: 'HQ',
  show: 'Show',
  call: 'Call',
  text: 'Text',
  send: 'Send',
  all_users: 'All users',
  frequently_used: 'Frequently Used',
  login: {
    phone: 'Phone number',
    user_not_found: 'User not found',
    invalid_code: 'Wrong code',
    enter_phone: 'Enter your mobile number to log in',
    send_to: 'Send code to:'
  },
  verify_code: {
    check_email: 'Login link sent to your mail',
    enter_code: 'Enter the code we just texted you',
    your_phone: 'Your phone number: %{phone}',
    didnt_recieve: 'Didn\'t receive a text message? ',
    didnt_recieve_1: 'Send an email',
    wrong_code: 'Wrong code'
  },
  org_structure_jobs: {
    total_jobs: 'Total roles',
    sunday_col: 'Sun',
    monday_col: 'Mon',
    tuesday_col: 'Tue',
    wednesday_col: 'Wed',
    thursday_col: 'Thu',
    friday_col: 'Fri',
    saturday_col: 'Sat',
    reset_date: 'Today',
    delete_date: 'Delete date',
    delete_job_title: 'Confirm delete job',
    delete_job_content: 'All tasks related to this job will be deleted',
    page_title: 'Roles',
    staff_page_title: 'Staff roles',
    shift_duration: '%{hours}H %{minutes}M',
    shift_duration_hours: '%{hours}H',
    empty_text: 'No jobs in sight... yet!'
  },
  org_structure_providers: {
    total_providers: 'Total providers',
    page_title: 'Service providers',
    no_providers: 'No providers in sight... yet!'
  },
  devices: {
    general_name: 'General'
  },
  work_panel: {
    tasks_tab: 'Tasks',
    work_schedule: 'Shifts'
  },
  devices_faulty_device_dialog: {
    total_calls: 'Total calls',
    off_time: 'Off time',
    total_cost: 'Total cost',
    open_date: 'Open date',
    whats_done: 'Repair description',
    status: 'Status',
    cost: 'Cost',
    close_date: 'Close date',
    close_ticket: 'Close ticket',
    days_open: '{days_open} days',
    close_ticket_approve: 'The ticket will be closed and move to history',
    send: 'Send'
  },
  broken_item_dialog_dialog: {
    create_title: 'Open new ticket',
    update_title: 'Update a ticket',
    price: 'Cost',
    description: 'Describe the problem',
    progress_description: 'Description Repair',
    comment: 'Write a comment',
    status: 'Status',
    status_open: 'Open',
    status_close: 'Closed',
    status_in_progress: 'In progress',
    status_done: 'Done',
    priority: 'Is it urgent',
    priority_low: 'Low urgency',
    priority_high: 'High urgency',
    what_broke: 'Where is the problem',
    assigned_user: 'Who is responsible for this',
    fix_images: 'Fixed items images',
    add_invoice: 'Attach an invoice',
    deadline_date: 'Fix until date',
    deadline_time: 'Fix until time',
    deadline_date_val: 'Fix until: {deadlineDate}',
    add_video: 'Add movie',
    open_days: 'Open %{days} days',
    repair: 'Repair',
    max_photo: 'Maximun 4',
    max_video: 'Maximun 1',
    add_service_provider: 'Related service provider'

  },
  shifts: {
    morning: 'morning',
    noon: 'noon',
    evening: 'evening',
    night: 'night',
    with_prefix: {
      morning: 'morning shift',
      noon: 'noon shift',
      evening: 'evening shift',
      night: 'night shift'
    }
  },
  org_job_new_dialog: {
    create_title: 'Add role',
    update_title: 'Update role',
    job_name: 'Role name',
    choose_shift: 'Shift',
    start: 'Start',
    end: 'End',
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    shift_times: 'shift schedule',
    all_days: 'All days',
    add_department: 'Add new department',
    work_days: 'Work days',
    finish: 'Finish',
    daily: 'Daily'
  },
  org_provider_new_dialog: {
    create_title: 'Add provider',
    update_title: 'Update provider',
    provider_name: 'Provider name',
    provider_phone: 'Phone',
    contact_name: 'Contact name',
    comments: 'Comments'
  },
  validator: {
    invlid_time: 'insert valid time',
    invlid_number: 'insert valid number',
    required: 'Please insert value'
  },
  device_device_new_dialog: {
    create_title: 'Add object',
    create_for_category: 'Add object for category',
    update_title: 'Update object',
    device_department: 'Location',
    name_taken_err: 'Name already taken'
  },
  confirm_delete: {
    title: 'Confirm delete'
  },
  devices_new_operation: {
    op_desc: 'Write a task'
  },
  job_op_schedule: {
    choose_job: 'Choose role'
  },
  op_schedule_frequency_dialog: {
    placeholder: 'Repeat frequency'
  },
  schedule_new_op_dialog: {
    title: 'New Task',
    update_title: 'Update Task',
    copy_title: 'Copy Task',
    description: 'Write a task',
    choose_job: 'Select job',
    set_account_manager: 'No manager roles defined',
    no_account_selected: 'No accounts selected',
    max_file_size: 'Max file size is 10MB',
    x_jobs_selected: '{jobs_count} Jobs',
    related_item: 'Object attribution',
    new_item: 'Item name',
    execution_time: 'Execution time',
    select_end_date: 'You can select end date',
    tags: 'Select tag',
    tag_report: 'Report',
    tag_monitor: 'Control',
    tag_procedure: 'Procedure',
    x_tags_selected: '{tags_count} tags',
    select_color: 'Select color',
    require_image: 'Take a picture to show the task is done?',
    guildance_images: 'Guidance images',
    add_sub_task: 'Add sub tasks',
    max_photo: 'Maximun 6',
    task_type: 'Task type',
    task_type_regular: 'Regular task',
    task_type_image: 'Image task',
    task_type_subtasks: 'Subtasks'
  },
  languages: {
    en: 'English',
    he: 'עברית',
    es: 'Español',
    language: 'Language'
  },
  item_search: {
    placeholder: 'Search Object',
    add_item: 'Add new Object',
    delete_title: 'Delete Object',
    delete_content: 'This would delete all related tickets'
  },
  provider_search: {
    placeholder: 'Search provider'
  },
  user_search: {
    placeholder: 'Search user',
    x_users_selected: '{usersCount} users'
  },
  employees: {
    name_col: 'Full name',
    add_employee_from_another_store: 'From another account',
    total_employee: 'Total employees',
    invite: 'Invite',
    pending: 'Pending',
    page_title: 'Employees',
    staff_page_title: 'Staff Employees'
  },
  employees_dialog: {
    create_title: 'Create an employee',
    update_title: 'Update an employee',
    first_name: 'First name',
    last_name: 'Last name',
    phone: 'Phone',
    permission: 'Permission',
    choose_jobs_placeholder: 'Choose roles',
    birth_date: 'Birth date',
    duplicate_phone: 'User with the same phone already exists',
    user_role: 'User role',
    send_invitation: 'Send an invitation message'
  },
  employees_other_store_dialog: {
    title: 'Add employee from another account'
  },
  employees_roles: {
    company_admin: 'Company admin',
    company_region_admin: 'Region admin',
    account_admin: 'Manager',
    account_manager: 'Department',
    account_employee: 'Employee'
  },
  work_schedule_search_employee_dialog: {
    dialog_title: 'Schedule for {jobShift} {jobName}',
    placeholder: 'Type employee name',
    all_week_schedule: 'All week'
  },
  reported_item_op_list: {
    save_error: 'Error occurred while updating list',
    title: 'Subtasks',
    comment: 'Type a comment'
  },
  item_op_list_dialog: {
    title: 'Sub tasks',
    feedback_description: 'Task feedback'
  },
  operation_frequency: {
    week_x_frequency: 'Once every {frequency} weeks',
    week_x_frequency_with_day: 'Every {frequency} weeks on {dayName}',
    exect_date_frequency: 'Specific date',
    every_year_no_date: 'Every year',
    every_year_x_selected: '{datesCount} dates selected',
    every_week: 'Once a week',
    every_week_with_day: 'Every {dayName}',
    every_day: 'Every day',
    every_year: 'Every year at {date}',
    week_x_frequency_start_period: 'Open operation {day}, {date}',
    daily: 'Daily',
    weekly: 'Weekly',
    yearly: 'Yearly',
    every_few_weeks: 'Every few weeks?',
    what_day: 'What day?',
    yearly_x_dates: 'Yearly - {dateCount} dates',
    yearly_single_date: 'Yearly - {date}',
    exact_date_command: 'You can pick one date or a range of dates'
  },
  job_selection_search: {
    x_jobs_selected: '{job_count} roles'
  },
  account_selection_select: {
    placeholder: 'Select account',
    x_accounts_selected: '{account_count} accounts'
  },
  account_fault_devices: {
    faulty_device_tab: 'Tickets',
    faulty_device_history_tab: 'History',
    faulty_device_reports_tab: 'Reports',
    broken_items_assigned_to_me: 'Assinged to me',
    broken_items_opened_by_me: 'Opened by me',
    broken_items_dates: 'Dates range',
    open_date: 'Creation date',
    close_date: 'Closed date',
    general_search: 'Free text search',
    broken_items_settings: {
      anonymous_explain: 'Let external users create tickets for your account by sending an email to a speacial email address',
      anonymous_explain_field: 'Add name before the @',
      invalid_subdomain: 'Invalid address, only letters, numbers, "_" and "-" are allowed',
      page_title: 'Tickets settings',
      domain_taken: 'This address is already taken'
    }
  },
  account_selection: {
    staff_tasks: 'HQ Tasks',
    store_tasks: 'Account Tasks',
    stores: 'Accounts',
    my_tasks_tab: 'Personal',
    hq_tasks_tab: 'HQ',
    accounts_tasks_tab: 'Accounts',
    empty_area: 'Without area',
    select_area: 'Select area',
    x_selected_areas: '{area_count} areas',
    no_area: 'No area',
    daily_progress: 'Daily progress'
  },
  account_selection_employee: {
    select_account: 'Select account'
  },
  install_prompt: {
    chrome: 'Add to home screen',
    ios: 'Install this webapp: tap {shareIcon}',
    ios1: 'then Add to home screen'
  },
  company_faulty_devices: {
    store_select: 'Select account'
  },
  show_faulty_device: {
    empty: 'No faulty devices'
  },
  company_settings: {
    company_name: 'Company Name'
  },
  chat_dialog: {
    title: 'Chat',
    message_placeholder: 'Type a message'
  },
  account_operations: {
    cancel_filter: 'Reset filter',
    status: 'Status',
    image: 'Image',
    check_list: 'Checklist',
    status_done: 'Done',
    status_not_reported: 'Not reported',
    cross_account: 'HQ operations',
    with_image: 'With image',
    without_image: 'Without image',
    with_checklist: 'With list',
    job_list: 'Jobs list',
    op_original_date: 'Original date: {date}',
    op_original_date_with_end_date: 'Original date: {date} - {endDate}',
    staff_operation: 'HQ operation: {first_name} {last_name}',
    assigned_to: 'Assigned to: {executor}',
    mark_shift_completed: 'Shift reported as complete',
    operation_from: 'From: {first_name} {last_name}',
    date_range_day: 'Day',
    date_range_week: 'Week',
    date_range_month: 'Month',
    done_by: 'Done by {first_name} {last_name}',
    created_by: 'Created by {firstName} {lastName}'
  },
  job_shift_select: {
    x_shifts_selected: '{shift_count} shifts'
  },
  version_check: {
    update_available: 'There is a new version available',
    reload: 'Update'
  },
  duplicate_operation_dialog: {
    title: 'Copy operation to another role'
  },
  color_picker: {
    clear: 'Clear'
  },
  out_of_shift_action: {
    title: 'Action is out of shift time',
    shift_not_start: 'Your shift hasn\'t started yet',
    whice_shift: 'Which shift would you like to watch?',
    title_before_start: 'The shift didn\'t start yet<br>This workshhet belongs to <span class="red--text font-weight-bold">{startDay}</span><br>for the shift which starts at <span class="red--text font-weight-bold">{startTime}</span>',
    return_work_panel: 'Back to work panel',
    dismiss: 'Ok, Stay in this shift',
    pass_shift: 'Change to previous day shift'
  },
  edit_operation_dialog: {
    invalid_day: '{job_name} doesn\'t work on {day}'
  },
  additional_allowed_jobs: {
    title: 'Work Schedule for {jobName}'
  },
  account_edit_dialog: {
    title: 'Edit account'
  },
  create_district_dialog: {
    title: 'Create area',
    update_title: 'Update area',
    placeholder: 'Area name'
  },
  staff_account_job_dialog: {
    title: 'Add staff account job',
    title_for_update: 'Update staff account job'
  },
  employee_schedules: {
    no_schedules: 'No schedules'
  },
  user_notifications: {
    missing_email: 'Please provide email at the employee registration tab',
    sms: 'sms',
    email: 'email',
    broken_item: {
      tab_title: 'Tickets',
      chat_notification: 'Chat notifications',
      chat_notification_user_mentioned: 'Only participated chats',
      low_priority: 'Alert when a new "low urgency" ticket opens up',
      high_priority: 'Alert when a new "high urgency" ticket opens up',
      deadline: 'Open tickets at deadline date',
      notify_not_only_assigned: 'Notify on tickets not only assigned to me',
      status_change: 'Notify on status change'
    },
    job: {
      tab_title: 'Jobs',
      tab_title_for_comapny_admins: 'Accounts',
      chat_notification: 'Task chat notifications',
      chat_notification_cross_account: 'Only HQ operations',
      cross_account_operation_notification: 'Notify when HQ operation received',
      birthdate_notification: 'Email me when employees have birthday',
      end_of_shift: 'Finish job tasks notification',
      uncompleted_shift_tasks: 'Notify of incomplete tasks for a job shift',
      task_create: 'Notify when new task is created',
      task_daily: 'Daily notifications for pending tasks'

    },
    staff_job: {
      tab_title: 'Staff Jobs',
      staff_task_daily: 'Daily notifications for pending tasks',
      staff_task_create: 'Notify when new task is created'
    },
    app_chat: {
      tab_title: 'Chat',
      join_group: 'Notify when i join a group',
      new_message: 'Notify when i receive a new message'
    },
    communnity: {
      tab_title: 'Community',
      new_post: 'Notify when a new post is created'
    },
    reports: {
      tab_title: 'Reports',
      daily_report: 'Daily general report email'
    }
  },
  bottom_navigation: {
    work_schedule: 'Shifts',
    tasks: 'Tasks',
    faulty_device: 'Tickets',
    accounts: 'Accounts',
    chat: 'Chats'
  },
  work_schedule_request_statuses: {
    pending: 'Pending request',
    approved: 'Request accepted',
    denied: 'Request rejected'
  },
  account_department_select: {
    placeholder: 'Choose department',
    create: 'Create department',
    update: 'Update department',
    confirm_delete: 'Do you want to delete {departmentName} department from roles',
    error_name_taken: 'Department name is already taken',
    x_selected: '{count} departments'
  },
  total_tasks_by_department: {
    empty_department: 'Without Department',
    ongoing_html: 'Ongoing <span class="ongoing">{count}</span>',
    completed_html: 'Completed <span class="completed">{count}</span>'
  },
  menu_page: {
    profile: 'Profile',
    accounts: 'Accounts',
    title_general: 'General',
    title_preferences: 'Preferences',
    title_admin: 'Admin',
    title_hq_admin: 'HQ Admin',
    tasks: 'Edit tasks',
    tasks_subtitle: 'View and edit all tasks',
    team: 'Team members',
    team_subtitle: 'Add and edit team members',
    team_subtitle_employee: 'View team members',
    jobs: 'Jobs',
    jobs_subtitle: 'View and edit jobs',
    service_providers: 'Service providers',
    service_providers_subtitle: 'View and edit account service providers',
    company_details: 'Company name',
    general_settings: 'General settings',
    hq_jobs: 'HQ Jobs',
    hq_members: 'HQ Team members',
    hq_members_subtitle: 'Add and edit HQ team members',
    help: 'Help',
    faults_settings: 'Tickets settings',
    task_bank: 'Task bank',
    push_history: 'Push notifications history',
    drive: 'My Drive',
    logout: 'Logout'
  },
  user_profile: {
    full_name: 'Full name',
    phone: 'Telephone number',
    delete_account: 'Delete account',
    delete_confirm: 'This will permanently delete all your data',
    confirm: 'Confirm',
    choose_avatar: 'Choose avatar',
    jobs: 'Job posts'
  },
  account_department_page: {
    total: 'Total departments',
    name_col: 'Department name',
    page_title: 'Departments'
  },
  app_chat: {
    create_group: 'New group',
    search_group_placeholder: 'Search User or group',
    search_group_divide: 'Active groups',
    search_user_divide: 'Other users',
    empty_messages: 'No messages',
    group_details: 'Group details',
    exit_group: 'Exit group',
    delete_group: 'Delete group',
    delete_group_confirm: 'Would you like to delete the group "{groupName}"',
    delete_group_confirm_detail: 'The entire message history will be deleted',
    exit_group_confirm: 'Would you like to exit the group "{groupName}"',
    exit_group_confirm_detail: 'The entire message history will be deleted',
    new_group_dialog: {
      title_create: 'Create new chat group',
      title_read_only: 'Group details',
      title_update: 'Update group',
      group_name: 'Group name',
      group_image: 'Group image',
      add_users: 'Add users'
    },
    seen_by_dialog: {
      title: 'Message Info',
      read_by: 'Read by',
      pending: 'Pending'
    }
  },
  user_live_notifications: {
    new_group_html: "You have joined to chat group <span class='font-weight-bold'>{groupName}</span>"
  },
  language_select: {
    placeholder: 'Choose language'
  },
  share_content: {
    clipboard_copy: 'Content copied to clipboard'
  },
  broken_item_share: {
    dialog_title: 'Share ticket',
    ticket_from: 'Ticket from: %{companyName} | %{accountName}',
    ticket_from_no_company: 'Ticket from: %{companyOrAccountName}',
    opened_at: 'Opened at: %{open_date}',
    opened_by: 'Opened by: %{open_by}',
    description: 'Description: %{description}',
    responsibility: 'Responsibility:',
    comment: 'Comment: %{comment}',
    fix_description: 'Fix Description: %{fix_description}',
    price: 'Price: %{price}',
    fault_images: 'Ticket images',
    fault_fix_images: 'Ticket fix images',
    fault_attached_invoice: 'Ticket invoice',
    fault_attached_video: 'Ticket video',
    related_provider: 'Service provider: %{providerName}',
    related_providers: 'Service providers:',
    fault_fix_until: 'Fix until: %{fixUntil}'
  },
  image_uploader: {
    max_images: 'Upload up to %{maxImages} images'
  },
  audio_recorder: {
    permission_denied: 'Audio permission denied'
  },
  jobs_calander: {
    show_all: 'Show All (%{count})'
  },
  job_operation_edit: {
    delete_operation: 'Delete task'
  },
  feature_select: {
    enable_features: 'Enable features',
    broken_item_history: 'Broken item history panel',
    broken_items: 'Broken items',
    read: 'Read',
    edit: 'Edit',
    delete: 'Delete',
    create: 'Create',
    close: 'Close ticket'
  },
  operation_share: {
    title: 'Share task',
    hq: 'HQ task',
    start_time: 'Start time at: %{startTime}',
    creator: 'from: %{firstName} %{lastName}',
    not_reported: 'Status: not finished',
    reported: 'Status: Done by %{firstName} %{lastName}',
    report_image: 'Report image:',
    attahced_file: 'Attached file:',
    checklist: 'List (%{done}/%{total}):',
    guidance_images: 'Guidance images:'
  },
  company_permissions: {
    create_object: 'Create object',
    account_admin: 'Manager permission',
    account_manager: 'Department permission',
    account_employee: 'Employee permission'
  },
  cross_account_page: {
    my_operations: 'Created by me',
    delete_cross_operation: 'Delete task',
    delete_cross_operation_detail: 'This will delete all related task'
  },
  single_task_notification: {
    title: 'Set alerts for this task',
    on_complete: 'On task complete',
    on_un_complete: 'On task un-complete'
  },
  install_app_prompt: {
    title: 'Hi, our mobile app is available on all stores',
    close: 'Got it, don\'t show again'
  },
  blog_post_create_dialog: {
    new_post_title: 'New post',
    post_update_title: 'Update post',
    new_comment_title: 'Reply to post',
    comment_update_title: 'Update comment',
    content_placeholder: 'What do you want to talk about?',
    select_all: 'Select all accounts',
    add_tag: 'Add tag',
    post: 'Post'
  },
  tags_select: {
    placeholder: 'Choose tag'
  },
  blog_post: {
    read_more: 'Read more',
    read_less: 'Read less',
    like: 'Like',
    reply: 'Reply',
    load_comments: 'Load comments',
    likes: 'Likes',
    staff_account: 'Staff account',
    no_posts: 'No posts yet',
    be_first: 'Be the first one to post!',
    my_posts: 'My posts',
    my_likes: 'My likes',
    actions: 'Actions'
  },
  blog_post_share: {
    title: 'Share post',
    post_by: 'Post by: %{firstName} %{lastName}',
    created_at: 'Created at: %{date}',
    post_tags: 'Tags: %{tags}',
    images: 'Images',
    video: 'video: %{videoUrl}',
    likes_count: '%{likesCount} Likes',
    comments_count: '%{commentsCount} Comments'
  },
  video_upload: {
    max_size: 'Max video size is 100 MB'
  },
  item_type_select: {
    title: 'Show as title',
    single_checklist: 'Done/not done',
    image: 'Image report',
    rating: 'Rate this task',
    yes_no: 'Yes/No report',
    feedback: 'Leave feedback',
    feedback_optional: '* Feedback is optional'
  },
  item_category_search: {
    placeholder: 'Search category',
    add_item_category: 'Add new category',
    delete_title: 'Delete category',
    delete_content: 'This would remove this category from all related objects'
  },
  new_item_category: {
    create_title: 'Create category',
    update_title: 'Update category',
    name: 'Category name'
  },
  company_broken_items: {
    deadline_date_label: 'Tickets with deadline date',
    deadline_date_passed: 'Tickets with passed deadline date',
    deadline_date_today: 'Tickets for fix today',
    deadline_date_coming_week: 'Tickets for fix in the coming week',
    deadline_date_no_deadline: 'Tickets without deadline',
    no_deadline: 'No deadline',
    deadline_this_week: 'Fix this week',
    deadline_today: 'Fix today',
    deadline_passed: 'Passed date',
    total: 'Total tickets',
    avg_open_time: 'Average fixing time (days)',
    urgent_faults: 'Urgent tickets',
    status: 'Status',
    deadline: 'SLA',
    after_deadline: 'after deadline',
    assigned_ticket: 'Tickets assigned to others',
    report: {
      export_excel: 'Export to Excel',
      send_later: 'This file will be sent to your email',
      missing_email: 'There is no email address to send the file.'
    }
  },
  broken_item: {
    created_by: 'Created by:',
    assigned_to: 'Assigned to:',
    repair_until: 'Repair until:',
    options: 'Options',
    close_ticket: 'Close ticket (move to history)',
    service_provider: 'Service provider:',
    time_left: 'Time left:'
  },
  district_select: {
    add_new: 'Add new area',
    delete_title: 'Delete area',
    delete_content: 'Are you sure you want to delete the area?'
  },
  broken_item_table: {
    headers: {
      account_name: 'Account',
      location: 'Location',
      assigned_to: 'Assigned to',
      service_provider: 'Service providers',
      comments: 'Comments',
      open_date: 'Open time',
      status: 'Status'
    }
  },
  company_stats: {
    faults: 'Tickets'
  },
  company_table: {
    view: 'View',
    headers: {
      name: 'Account name',
      location: 'Area',
      hq_tasks: 'HQ tasks',
      account_tasks: 'Account tasks',
      total_faults: 'Faults'
    }
  },
  items_page: {
    pick_account: 'Pick account',
    general: 'General',
    total_calls: 'Total calls',
    off_time: 'Off time',
    total_cost: 'Total cost'
  },
  tasks_page: {
    delete_task_image: 'Are you sure you want to delete this image?',
    empty_tasks: 'You have no tasks for today',
    jobs: 'Jobs',
    departments: 'Departments',
    progress: 'Progress',
    more_details: 'More details',
    less_details: 'Less details'
  },
  task_bank: {
    title: 'Task bank',
    every_day_frequency: 'Every day',
    every_week_frequency: 'Every week',
    every_x_weeks_frequency: 'Every X weeks',
    every_year_frequency: 'Every year',
    exect_date_frequency: 'Specific date',
    created_by: 'By: {firstName} {lastName}',
    no_tasks: 'No tasks created yet',
    serach_placeholder: 'Search by task description',
    assigned_to_x_jobs: 'Assigned to {jobsCount} jobs'
  },
  hq_task_job_group: {
    create_title: 'Create new job group',
    update_title: 'Update job group',
    job_groups: 'Job groups',
    delete_group: 'Delete {groupName} job group',
    group_name: 'Group name'
  },
  default_deadline: {
    broken_item_settings: 'Tickets settings',
    high_priority: 'High priority tickets',
    low_priority: 'Low priority tickets',
    deadline_time: 'Time to close the ticket (hours)',
    deadline_notification: 'Send notification in case ticket is not resolved (hours)'
  },
  push_history: {
    title: 'Push notifications history',
    no_notifications: 'No notifications',
    is_viewed: 'Is viewed',
    viewed: 'Viewed',
    not_viewed: 'Not viewed',
    mark_as_viewed: 'Mark as viewed',
    assigned_ticket: 'Ticket assigned to you at %{accountName} for %{itemName}',
    ticket_new_message: 'New message recieved for ticket at %{accountName} for %{itemName}',
    new_ticket: 'New ticket created at %{accountName} for %{itemName}',
    updated_ticket: 'Ticket updated at %{accountName} for %{itemName}',
    ticket_deadline: 'Ticket at %{accountName} for %{itemName} should be fixed by %{deadlineDate}',
    ticket_timed_notification: 'Additional notification for ticket at %{accountName} for %{itemName} which still open from %{openDate}',
    new_blog_post: 'New blog post created by %{firstName} %{lastName}',
    new_app_chat_group_message: 'New message from %{firstName} %{lastName} at %{groupName}',
    new_app_chat_message: 'New message from %{firstName} %{lastName}',
    app_chat_join_group: 'You joined to chat group %{groupName}',
    task_chat_message: 'New message recieved for task at %{accountName} for %{jobName} %{jobShift}',
    new_staff_task: 'New personal task created by %{firstName} %{lastName} for %{jobName}',
    new_corss_account_task: 'New HQ task created at %{accountName} for %{jobName} %{jobShift}',
    new_task: 'New task created at %{accountName} for %{jobName} %{jobShift}',
    task_not_completed: 'Task was not reported as done at %{accountName} for %{jobName} %{jobShift}',
    task_completed: 'Task was reported as done at %{accountName} for %{jobName} %{jobShift}',
    shift_completed: 'Shift was reported as done at %{accountName} for %{jobName} %{jobShift}',
    shift_not_completed: 'Shift tasks were not done at %{accountName} for %{jobName} %{jobShift}'
  },
  drive: {
    title: 'Files',
    upload: 'Upload',
    create_folder: 'Create new folder',
    update_folder: 'Update folder',
    folder_name: 'Folder name',
    all_accounts: 'All accounts',
    created_by: 'By: {firstName} {lastName}',
    one_file: 'One file',
    many_files: '{count} files',
    one_folder: 'One folder',
    many_folders: '{count} folders',
    all_roles: 'All permissions',
    delete_folder: 'Delete {folderName} folder',
    delete_folder_details: 'This would delete all folder content',
    upload_file: 'Upload file',
    update_file: 'Update file',
    delete_file: 'Delete {fileName} file',
    owner: 'Owner',
    link_copied: 'Link copeid to clipboard',
    owner_search: 'Created by',
    empty_drive: 'No files or folders',
    empty_folder: 'No files yet',
    roles_access: 'Roles access',
    cover_photo: 'Cover photo',
    file_info: 'File info',
    downloaded_by: 'Downloaded by',
    no_downloads: 'The file hasn’t been downloaded yet',
    empty_single_folder: 'Empty folder'
  },
  hq_tasks_page: {
    account_rating: 'Account rating',
    top_3: '👍🏻 Good Job',
    bottom_3: '👎🏻 You can do better',
    all_tasks: 'All Tasks Mode',
    single_account_mode: 'Single Account Mode',
    hq_tasks: 'HQ Mode',
    created_by_me: 'Created by me',
    alerted_tasks: 'Tasks with alerts',
    no_tasks: 'No tasks for today',
    update_every_hour: 'Updates every hour'
  },
  page_filter: {
    filter_by: 'Filter by'
  },
  task_order_dialog: {
    shift_title: 'Cambiar el orden de las tareas'
  }
}

<template>
  <v-dialog
    v-model="dialog" :max-width="imageMaxWidth" @click:outside="onClose"
  >
    <v-card
      class="images-preview-display"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-text class="pa-0">
        <v-carousel
          v-model="startIndex"
          :show-arrows="false" class="images-carousel" :height="imageMaxHeight"
        >
          <v-btn
            v-if="isShowReset"
            color="black"
            max-width="100px"
            class="mx-2 reset-btn white--text"
            @click="reset"
          >
            {{ $t('reset') }}
            <v-icon>
              restart_alt
            </v-icon>
          </v-btn>
          <v-btn
            icon
            class="close-btn"
            @click="close"
          >
            <v-icon color="black">
              close
            </v-icon>
          </v-btn>
          <v-carousel-item
            v-for="(imageUrl, index) in images"
            :key="index"
          >
            <v-btn
              icon
              class="download-btn" @click="downloadImage(imageUrl)"
            >
              <v-icon color="black">
                download
              </v-icon>
            </v-btn>
            <v-row justify="center" align="center" class="full-height">
              <pinch-zoom ref="pinchZoomRef">
                <v-col class="px-0">
                  <v-img
                    :src="betterImageQuality(imageUrl)" :max-height="imageMaxHeight" :max-width="imageMaxWidth"
                    :class="{ 'zoomable': !isZoomed(index) }"
                    contain
                    @click="onImageClick(index)"
                  >
                    <template #placeholder>
                      <v-row
                        align="center" justify="center" class="fill-height"
                        no-gutters
                      >
                        <v-progress-circular indeterminate color="primary" />
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </pinch-zoom>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { betterImageQuality, downloadFile } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import Vuetify from '@/plugins/vuetify.js'
import PinchZoom from 'vue-pinch-zoom'
import { ref, computed } from 'vue'
const dialog = ref(false)
const images = ref([])
const startIndex = ref(0)
export default {
  components: {
    'pinch-zoom': PinchZoom
  },
  setup (_, { emit }) {
    const isShowReset = ref(false)
    const pinchZoomRef = ref([])
    const model = ref(0)

    /// Methods
    function openDialog ({ imageUrls, index = 0, showReset = false }) {
      dialog.value = true
      model.value = index
      isShowReset.value = showReset
      images.value = imageUrls
      if (Number.isInteger(index) && index >= 0 && index < imageUrls.length) {
        startIndex.value = index
      }
    }

    const onClose = () => {
      images.value = []
      startIndex.value = 0
    }

    const close = () => {
      dialog.value = false
      onClose()
    }
    const reset = () => {
      emit('reset')
      close()
    }

    const downloadImage = (mediaUrl) => {
      try {
        downloadFile(mediaUrl)
      } catch (error) {
        errHandler(error)
        const newTab = window.open(mediaUrl, '_blank')
        if (newTab) {
          newTab.focus() // Focus on the new tab if it successfully opens
        }
      }
    }

    const onImageClick = (index) => {
      if (pinchZoomRef.value && pinchZoomRef.value[index]) {
        pinchZoomRef.value[index].toggleZoom()
      }
    }

    const isZoomed = (index) => {
      if (pinchZoomRef.value && pinchZoomRef.value[index]) {
        return pinchZoomRef.value[index].isZoomedIn
      }
      return false
    }

    /// Computed
    const imageMaxWidth = computed(() => Vuetify.framework.breakpoint.xsOnly ? '90vw' : '70vw')
    const imageMaxHeight = computed(() => Vuetify.framework.breakpoint.xsOnly ? '80vh' : '70vh')
    return {
      dialog,
      model,
      pinchZoomRef,
      images,
      startIndex,
      imageMaxWidth,
      imageMaxHeight,
      isShowReset,
      isRtl,
      betterImageQuality,
      openDialog,
      onClose,
      close,
      reset,
      downloadImage,
      onImageClick,
      isZoomed
    }
  }
}
</script>
<style lang="scss" scoped>
.images-preview-display {
  .images-carousel {
    position: relative;
    .zoomable {
      cursor: zoom-in;
    }
    .close-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      left: 8px
    }
    .download-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      left: 48px
    }
    .reset-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      right: 0
    }
  }
}
.rtl.images-preview-display {
  .images-carousel {
    .close-btn {
      right: 8px;
      left: auto;
    }
    .download-btn {
      right: 48px;
      left: auto;
    }
    .reset-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      right: auto;
      left: 0
    }
  }
}
</style>

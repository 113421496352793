<template>
  <span>
    <date-picker-dialog
      ref="datePickerDialog"
      :value="valueToDateObj"
      :max-date="maxDate"
      :show-current-date-button="false"
      :show-delete-date-button="true"
      :disabled="disabled"
      @change="applyDatePicker"
      @clear="deleteDate"
    />
    <v-text-field
      :label="label"
      :value="valueInUserFormat"
      readonly
      outlined
      background-color="white"
      @click="openDatePicker"
    />
  </span>
</template>
<script>
import DatePickerDialog from '@/components/shared/DatePickerDialog.vue'

import { dateToISO8601String, isValidISO8601Date, ISO8601StringToDate } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'date-picker-dialog': DatePickerDialog
  },
  props: {
    value: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    const maxDateObj = new Date()
    maxDateObj.setDate(maxDateObj.getDate() - 1)
    const maxDate = ref(dateToISO8601String(maxDateObj))
    const datePickerDialog = ref(null)

    // Methods
    const openDatePicker = () => datePickerDialog.value.openDialog()
    const deleteDate = () => emit('input', null)
    const applyDatePicker = (birthdate) => emit('input', dateToISO8601String(birthdate))

    const valueToDateObj = computed(() => isValidISO8601Date(props.value) ? ISO8601StringToDate(props.value) : null)

    const valueInUserFormat = computed(() => {
      const dateObj = valueToDateObj.value
      return dateObj ? dateObj.toLocaleDateString(i18n.locale) : ''
    })

    return {
      valueInUserFormat,
      maxDate,
      datePickerDialog,
      valueToDateObj,
      applyDatePicker,
      openDatePicker,
      deleteDate
    }
  }
}
</script>

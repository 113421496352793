<template>
  <v-select
    :value="value"
    :items="companyDistrictsOptions"
    :label="$t('account_selection.select_area')"
    item-value="id"
    item-text="name"
    :loading="getIsLoading"
    class="district-select"
    :outlined="outlined"
    multiple
    :menu-props="menuProps"
    :dense="dense"
    clearable
    :height="height"
    :background-color="backgroundColor"
    @input="onUpdate" @change="onChange"
  >
    <template v-if="showSelectCount" #selection="{ index }">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('account_selection.x_selected_areas', { area_count: value.length }) }}
      </div>
    </template>
    <template #item="{ item: area, attrs }">
      <v-row
        no-gutters justify="space-between" align="center"
        :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
      >
        <v-col cols="8" class="font-weight-bold">
          {{ area.name }} ({{ area.accounts_count || 0 }})
        </v-col>
        <v-col cols="4" class="texts-end">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { getCompanyDistricts, loadCompanyDistricts, getIsLoading, getAccountsWithoutDistrictsSize } from '@/models/CompanyDistrict.js'

import { handler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'
import { computed } from 'vue'
const EMPTY_AREA_ID = -1
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    showEmptyArea: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    showSelectCount: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup (props, { emit }) {
    const emptyAreaSelectOption = () => {
      const emptyAreaText = i18n.t('account_selection.empty_area')
      return { id: EMPTY_AREA_ID, name: emptyAreaText, accounts_count: getAccountsWithoutDistrictsSize.value }
    }

    // Methods
    const onUpdate = (newValue) => emit('input', newValue)
    const onChange = (newValue) => emit('change', newValue)

    // Computed

    const companyDistrictsOptions = computed(() => {
      const companyDistricts = getCompanyDistricts.value
      if (companyDistricts.length === 0 || !props.showEmptyArea) return companyDistricts
      return [emptyAreaSelectOption()].concat(companyDistricts)
    })

    loadCompanyDistricts({ apiKey: props.apiKey })
      .catch(handler)
    return {
      getIsLoading,
      EMPTY_AREA_ID,
      isRtl,
      companyDistrictsOptions,
      onUpdate,
      onChange
    }
  }
}
</script>

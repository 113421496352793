<template>
  <v-row align="center" justify="start" no-gutters>
    <v-btn
      v-for="role in availableRoles"
      :key="role.id" color="#111"
      :outlined="!isSelected(role)"
      class="mme-4 rounded-xl"
      :class="{ 'white--text': isSelected(role) }"
      @click="onClick(role)"
    >
      {{ role.translatedName }}
    </v-btn>
  </v-row>
</template>
<script>
import { accountAdminAndBelow, accountManagerAndBelow, regionAdminAndAbove } from '@/store/modules/EmployeeRole.js'

import useAuth from '@/composables/useAuth.js'

import { useRoute } from 'vue-router/composables'

import { computed } from 'vue'
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const router = useRoute()
    const { isUserAccountManager } = useAuth()

    // Methods
    const onClick = (role) => emit('input', role.name)
    const isSelected = (role) => role.name === props.value

    // Computed
    const staffOnlyRoute = computed(() => router.meta.staffOnly)
    const availableRoles = computed(() => {
      if (staffOnlyRoute.value) return regionAdminAndAbove()
      return isUserAccountManager.value ? accountManagerAndBelow() : accountAdminAndBelow()
    })
    return {
      availableRoles,
      onClick,
      isSelected
    }
  }
}
</script>

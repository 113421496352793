<template>
  <span class="d-relative media-preview-container">
    <span v-if="showCounter" class="counter">
      {{ mediaUrls.length }}
    </span>
    <image-with-fallback
      v-if="showImagePreview"
      :src="imagePreviewUrl" contain height="65"
      width="65" class="pointer grey darken-4 image-preview"
    />
    <div v-else class="video-preview pointer">
      <video>
        <source :src="videoPreviewUrl">
        Your browser does not support the video tag.
      </video>
    </div>
  </span>
</template>
<script>
import ImageWithFallback from '@/components/shared/ImageWithFallback.vue'

import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  components: {
    'image-with-fallback': ImageWithFallback
  },
  props: {
    mediaUrls: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const imagePreviewUrl = computed(() => props.mediaUrls.find((media) => media.type === 'image')?.url)
    const videoPreviewUrl = computed(() => props.mediaUrls.find((media) => media.type === 'video')?.url)
    const showImagePreview = computed(() => isString(imagePreviewUrl.value))
    const showCounter = computed(() => props.mediaUrls.length > 1)
    return {
      imagePreviewUrl,
      videoPreviewUrl,
      showImagePreview,
      showCounter
    }
  }
}
</script>
<style lang="scss">
.media-preview-container {
  position: relative;

  .image-preview {
    border-radius: 12px;
  }

  .video-preview {
    border-radius: 12px;
    width: 65px;
    height: 65px;
    overflow: hidden;

    video {
      width: 65px;
      height: 65px;
    }
  }

  .counter {
    z-index: 3;
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    color: black;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
  }
}
</style>

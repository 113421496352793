import Client from '@/api_client/client.js'
const show = (apiKey) => {
  const ACCOUNT_SHOW_PATH = `/accounts/${apiKey}/accounts`
  return Client.get(ACCOUNT_SHOW_PATH)
}

const index = (apiKey, params = {}) => {
  const ACCOUNT_INDEX_PATH = `/accounts/${apiKey}/accounts/all`
  return Client.get(ACCOUNT_INDEX_PATH, { params })
}

const indexForEmployees = (apiKey) => {
  const ACCOUNT_INDEX_FOR_EMPLOYEES_PATH = `/accounts/${apiKey}/accounts/index_for_employees`
  return Client.get(ACCOUNT_INDEX_FOR_EMPLOYEES_PATH)
}

const update = (apiKey, params) => {
  const ACCOUNT_UPDATE_PATH = `/accounts/${apiKey}/accounts`
  return Client.put(ACCOUNT_UPDATE_PATH, { account: params, image_v2: true })
}

const addStoreImage = (apiKey, imageName) => {
  const ACCOUNT_ADD_STORE_IMAGE_PATH = `/accounts/${apiKey}/accounts/add_image`
  return Client.post(ACCOUNT_ADD_STORE_IMAGE_PATH, { account: { image_name: imageName }, image_v2: true })
}

const removeStoreImage = (apiKey) => {
  const ACCOUNT_REMOVE_STORE_IMAGE_PATH = `/accounts/${apiKey}/accounts/delete_image`
  return Client.delete(ACCOUNT_REMOVE_STORE_IMAGE_PATH)
}

const updateOrder = ({ apiKey, params }) => {
  const ACCOUNT_UPDATE_ORDER_PATH = `/accounts/${apiKey}/accounts/update_order`
  return Client.put(ACCOUNT_UPDATE_ORDER_PATH, params)
}

const stats = (apiKey, params) => {
  const ACCOUNT_STATS_PATH = `/accounts/${apiKey}/accounts/stats`
  return Client.put(ACCOUNT_STATS_PATH, params)
}

export {
  show,
  update,
  index,
  indexForEmployees,
  addStoreImage,
  removeStoreImage,
  updateOrder,
  stats
}

import Client from '@/api_client/client.js'

const index = (apiKey, params) => {
  const INDEX_COMPANY_BROKEN_ITEM_PATH = `/accounts/${apiKey}/company_broken_items`
  return Client.put(INDEX_COMPANY_BROKEN_ITEM_PATH, params)
}

const stats = (apiKey, params) => {
  const STATS_COMPANY_BROKEN_ITEM_PATH = `/accounts/${apiKey}/company_broken_items/stats`
  return Client.put(STATS_COMPANY_BROKEN_ITEM_PATH, params)
}

const createReport = (apiKey, params) => {
  const COMPANY_BROKEN_ITEM_CREATE_REPORT_PATH = `/accounts/${apiKey}/company_broken_items/create_report`
  return Client.post(COMPANY_BROKEN_ITEM_CREATE_REPORT_PATH, params)
}

export {
  index,
  stats,
  createReport
}

<template>
  <v-row
    v-if="isLoading" align="center" justify="center"
    class="fill-height my-3"
  >
    <v-skeleton-loader
      type="card"
    />
  </v-row>
  <v-row
    v-else align="start" justify="start"
    class="my-4"
  >
    <v-col cols="12">
      <draggable
        :list="taskList"
        handle=".drag-handle"
        animation="200"
      >
        <v-row
          v-for="task in taskList" :key="task.operation_id" align="center"
          justify="start" no-gutters class="task-summary-row py-2"
        >
          <v-col class="flex-grow-0 flex-shrink-1 ppe-3">
            <v-btn
              text icon
              class="drag-handle"
            >
              <v-icon color="#9E9E9E">
                drag_indicator
              </v-icon>
            </v-btn>
          </v-col>
          <v-col class="flex-grow-1 flex-shrink-0">
            <div class="t-400-14 color20">
              {{ task.operation_description }}
            </div>
            <div class="t-400-12 color7">
              <v-icon>
                schedule
              </v-icon> {{ taskFrequencyText(task) }}
              <span v-if="showStartTime(task)" class="color1 t-700-13 mms-1">
                {{ startTimeUserFormat(task) }}
              </span>
            </div>
          </v-col>
        </v-row>
      </draggable>
    </v-col>
    <v-col v-if="showLoadMorePages" v-intersect="onIntersect" />
  </v-row>
</template>
<script>

import useAccount from '@/composables/useAccount.js'
import useTaskOrder from '@/composables/useTaskOrder.js'

import { getFrequencyTextWithDays, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import {
  isValidISO8601Date, getClientDateFormatted, ISO8601StringToDate, timeStringToUserFormat
} from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'

import draggable from 'vuedraggable'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'

export default {
  components: {
    draggable
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    accountJobId: {
      type: Number,
      required: true
    },
    shiftPartName: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      isLoading, isLoadingNextPage, taskList, pagination,
      loadTasksPage, loadNextPage
    } = useTaskOrder(props.apiKey)

    const { accountCountryCode, accountLocaleCode } = useAccount()
    const countryCode = accountCountryCode.value
    const localeCode = accountLocaleCode.value

    // Methods
    const onIntersect = loadNextPage

    const showStartTime = (task) => isString(task.start_time)
    const startTimeUserFormat = (task) => timeStringToUserFormat({ timeString: task.start_time, localeCode })

    const taskFrequencyText = (task) => {
      if (task.frequency_type !== FrequencyTypes.ExectDate) {
        return getFrequencyTextWithDays({
          frequency: task.frequency,
          frequencyType: task.frequency_type,
          dateString: task.first_scheduled_date
        })
      }

      const endDate = task.end_date
      if (endDate && isValidISO8601Date(endDate)) {
        const endDateUserFormat = getClientDateFormatted({ fromDate: ISO8601StringToDate(endDate), countryCode })
        return i18n.t('account_operations.op_original_date_with_end_date', {
          date: task.operation_next_date,
          endDate: endDateUserFormat
        })
      }
      return i18n.t('account_operations.op_original_date', { date: task.operation_next_date })
    }

    // Computed
    const showLoadMorePages = computed(() => {
      if (isLoading.value || isLoadingNextPage.value) return false
      return pagination.value.total > taskList.value.length
    })

    loadTasksPage({ accountJobId: props.accountJobId, shiftPartName: props.shiftPartName })
    return {
      isLoading,
      taskList,
      showLoadMorePages,
      onIntersect,
      showStartTime,
      taskFrequencyText,
      startTimeUserFormat
    }
  }
}
</script>
<style lang="scss">
.task-order-dialog {
  .task-summary-row + .task-summary-row {
    border-top: 1px solid #DADADA;
  }
}
</style>

<template>
  <v-row
    align="start" justify="start" class="mt-15 mx-md-8"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <drive-new-folder-dialog
      ref="newFolderDialog" :api-key="apiKey"
      :parent-folder-id="parentFolderId"
    />
    <drive-new-file-dialog ref="newFileDialog" :api-key="apiKey" />
    <v-col cols="6" lg="8" class="t-400-22 texts-start mt-4 pps-6">
      <span class="pointer" @click="onDriveClick">
        {{ $t('drive.title') }}
      </span>
      <v-icon>
        {{ isRtl ? 'chevron_left' : 'chevron_right' }}
      </v-icon>
      <drive-folder-path
        :folder="currentFolder"
        @click-folder="onDriveFolderClick"
      />
    </v-col>
    <v-col cols="6" lg="4" class="texts-end mt-4 ppe-6">
      <v-btn
        v-if="showCreateFolder"
        color="#CFD9F5" class="mme-4"
        :disabled="createFileDisabled"
        @click="createFolderClick"
      >
        {{ $t('create') }}
        <v-icon class="material-icons-outlined mx-1">
          folder
        </v-icon>
      </v-btn>
      <v-btn
        color="#0040FF" class="white--text" :disabled="createFileDisabled"
        @click="createFileClick"
      >
        {{ $t('drive.upload') }}
        <v-icon class=" mx-1">
          upload
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="mb-2 row justify-start align-start pb-0">
      <v-col cols="6" />
      <v-col cols="6" class="texts-end">
        <span v-if="showFolderCount" class="ppe-1 d-block d-sm-inline">
          <v-icon class="material-icons-outlined">
            folder
          </v-icon>
          <span>
            {{ foldersCount }}
          </span>
        </span>
        <span v-if="showFileCount" class="ppe-1 d-block d-sm-inline">
          <v-icon class="material-icons-outlined">
            description
          </v-icon>
          <span>
            {{ filesCount }}
          </span>
        </span>
        <drive-resource-accounts
          v-if="folderLoaded" :resource="currentFolder" show-tool-tip
          class="d-block d-sm-inline"
        />
        <drive-resource-roles
          v-if="folderLoaded" :resource="currentFolder" show-tool-tip
          class="d-block d-sm-inline"
        />
      </v-col>
    </v-col>
    <v-col v-if="isEmptyFiles" cols="12" class="mb-4 text-center color10 t-600-24">
      <div class="d-flex justify-center">
        <v-img :src="emptyFolderImage" max-height="240" max-width="240" />
      </div>
      {{ $t('drive.empty_folder') }}
    </v-col>
    <v-col cols="12" class="mb-4">
      <drive-file-table
        :api-key="apiKey"
        :parent-folder-id="parentFolderId"
        @edit-folder="onEditFolderClick"
        @click-folder="onDriveFolderClick"
      />
    </v-col>
  </v-row>
</template>
<script>
import DriveNewFolderDialog from '@/components/drive/DriveNewFolderDialog.vue'
import DriveNewFileDialog from '@/components/drive/DriveNewFileDialog.vue'
import DriveFileTable from '@/components/drive/files_table_view/DriveFileTable.vue'
import DriveResourceRoles from '@/components/drive/shared/DriveResourceRoles.vue'
import DriveResourceAccounts from '@/components/drive/shared/DriveResourceAccounts.vue'
import DriveFolderPath from '@/components/drive/DriveFolderPath.vue'

import useAuth from '@/composables/useAuth.js'
import useCompanyDriveFolder from '@/composables/useCompanyDriveFolder.js'
import useCompanyDriveSubFolder from '@/composables/useCompanyDriveSubFolder.js'

import { useRoute, useRouter } from 'vue-router/composables'
import { isRtl, i18n } from '@/i18n.js'
import { ref, computed } from 'vue'

export default {
  components: {
    'drive-new-folder-dialog': DriveNewFolderDialog,
    'drive-new-file-dialog': DriveNewFileDialog,
    'drive-file-table': DriveFileTable,
    'drive-resource-roles': DriveResourceRoles,
    'drive-resource-accounts': DriveResourceAccounts,
    'drive-folder-path': DriveFolderPath
  },
  setup () {
    const folderLoaded = ref(false)
    const router = useRouter()
    const newFileDialog = ref(null)
    const newFolderDialog = ref(null)
    const currentFolder = ref({})
    const parentFolderId = ref(null)
    const { folderId } = useRoute().params
    parentFolderId.value = folderId
    const { apiKey, isAccountManagerOrAbove, loadCurrentUser } = useAuth()
    const { isLoading, loadSingleFolder } = useCompanyDriveFolder(apiKey.value)
    const { isEmpty: isEmptyFiles, onFileOrFolderChanged } = useCompanyDriveSubFolder(apiKey.value)

    const emptyFolderImage = new URL('@/assets/empty_folder.png', import.meta.url).href
    const afterFileOrFolderChange = ({ isChanged }) => {
      if (!isChanged) return

      onFileOrFolderChanged()
    }

    const setCurrentFolder = () => {
      loadSingleFolder(parentFolderId.value)
        .then((response) => {
          currentFolder.value = response
          folderLoaded.value = true
        })
    }
    // Methods

    const createFileClick = () => newFileDialog.value.openDialog(JSON.parse(JSON.stringify(currentFolder.value))).then(afterFileOrFolderChange)
    const createFolderClick = () => newFolderDialog.value.openDialog().then(afterFileOrFolderChange)
    const onEditFolderClick = (folder) => newFolderDialog.value.openForUpdate(folder.id).then(afterFileOrFolderChange)
    const onDriveClick = () => router.push({ name: 'files' })
    const onDriveFolderClick = (folder) => {
      router.push({ name: 'files_folder', params: { folderId: folder.id } })
      parentFolderId.value = folder.id
      setCurrentFolder()
    }
    // Computed
    const showCreateFolder = computed(() => isAccountManagerOrAbove.value)
    const createFileDisabled = computed(() => !folderLoaded.value || isLoading.value)
    const showFileCount = computed(() => Number.isInteger(currentFolder.value.total_files) &&
      currentFolder.value.total_files > 0)
    const showFolderCount = computed(() => Number.isInteger(currentFolder.value.total_folders) &&
      currentFolder.value.total_folders > 0)

    const filesCount = computed(() => {
      if (!showFileCount.value) return ''
      const totalFiles = currentFolder.value.total_files
      if (totalFiles === 1) return i18n.t('drive.one_file')
      return i18n.t('drive.many_files', { count: totalFiles })
    })
    const foldersCount = computed(() => {
      if (!showFolderCount.value) return ''
      const totalFolders = currentFolder.value.total_folders
      if (totalFolders === 1) return i18n.t('drive.one_folder')
      return i18n.t('drive.many_folders', { count: totalFolders })
    })

    loadCurrentUser()

    setCurrentFolder()
    return {
      isRtl,
      newFileDialog,
      newFolderDialog,
      apiKey,
      isLoading,
      showCreateFolder,
      currentFolder,
      parentFolderId,
      createFileDisabled,
      folderLoaded,
      showFileCount,
      showFolderCount,
      filesCount,
      foldersCount,
      isEmptyFiles,
      emptyFolderImage,
      createFileClick,
      createFolderClick,
      onDriveClick,
      onEditFolderClick,
      onDriveFolderClick
    }
  }
}
</script>

<template>
  <span v-if="showFullPath">
    <span
      v-for="folderPath in shortPath"
      :key="folderPath.id"
      :class="{'pointer' : !folderPath.last && folderPath.isFolder !== false}"
      @click="onPathClick(folderPath)"
    >
      <v-icon small color="#011D35" class="pps-2">
        folder_open
      </v-icon>
      <span class="color20 t-700-18">
        {{ folderPath.name }}
      </span>
      <v-icon
        v-if="!folderPath.last" small color="#011D35"
        class="pps-2"
      >
        {{ isRtl ? 'chevron_left' : 'chevron_right' }}
      </v-icon>
    </span>
  </span>
  <span v-else>
    <v-icon color="#011D35" class="pps-2">
      folder_open
    </v-icon>
    <span class="color20 t-700-18">
      {{ folder.name }}
    </span>
  </span>
</template>
<script>
import { isRtl } from '@/i18n'
import { computed } from 'vue'
export default {
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    // Methods
    const onPathClick = (path) => {
      if (path.isFolder === false) return
      emit('click-folder', path)
    }
    // Computed
    const showFullPath = computed(() => Array.isArray(props.folder.full_path) && props.folder.full_path.length > 0)
    const shortPath = computed(() => {
      const path = props.folder.full_path.length <= 4
        ? props.folder.full_path
        : props.folder.full_path.slice(0, 2)
          .concat({ name: '...', isFolder: false }, props.folder.full_path.slice(-2))
      path[path.length - 1].last = true
      return path
    })
    return {
      showFullPath,
      shortPath,
      isRtl,
      onPathClick
    }
  }
}
</script>

import Client from '@/api_client/client.js'

const show = (apiKey, folderId) => {
  const DRIVE_FOLDER_SHOW_PATH = `/accounts/${apiKey}/drive_folders/${folderId}`
  return Client.get(DRIVE_FOLDER_SHOW_PATH)
}

const index = (apiKey, params) => {
  const DRIVE_FOLDER_INEDX_PATH = `/accounts/${apiKey}/drive_folders`
  return Client.put(DRIVE_FOLDER_INEDX_PATH, params)
}

const indexSubFolder = ({ apiKey, folderId, params }) => {
  const DRIVE_FOLDER_INEDX_SUBFOLDER_PATH = `/accounts/${apiKey}/drive_folders/${folderId}/subfolder`
  return Client.put(DRIVE_FOLDER_INEDX_SUBFOLDER_PATH, params)
}

const create = (apiKey, params) => {
  const DRIVE_FOLDER_CREATE_PATH = `/accounts/${apiKey}/drive_folders`
  return Client.post(DRIVE_FOLDER_CREATE_PATH, params)
}

const update = ({ apiKey, params, id }) => {
  const DRIVE_FOLDER_UPDATE_PATH = `/accounts/${apiKey}/drive_folders/${id}`
  return Client.put(DRIVE_FOLDER_UPDATE_PATH, params)
}

const destroy = (apiKey, folderId) => {
  const DRIVE_FOLDER_DELETE_PATH = `/accounts/${apiKey}/drive_folders/${folderId}`
  return Client.delete(DRIVE_FOLDER_DELETE_PATH)
}

const removeImage = ({ apiKey, id }) => {
  const DRIVE_FOLDER_DELETE_IMAGE_PATH = `/accounts/${apiKey}/drive_folders/${id}/delete_image`
  return Client.delete(DRIVE_FOLDER_DELETE_IMAGE_PATH)
}

export {
  show,
  index,
  indexSubFolder,
  create,
  update,
  destroy,
  removeImage
}

<template>
  <v-img
    :src="imageSrc" :contain="contain" :height="height"
    :width="width" :max-height="maxHeight" :max-width="maxWidth"
    @error="handleImageError"
    @click="$emit('click')"
  >
    <slot v-if="haveSlots" />
    <template #placeholder>
      <v-row
        align="center" justify="center" class="fill-height"
        no-gutters
      >
        <v-progress-circular indeterminate color="primary" />
      </v-row>
    </template>
  </v-img>
</template>
<script>
import { ref, computed } from 'vue'

const USER_IMAGE_PROCESSED_DOMAIN = 'https://user-images.toperation.co/processed/'
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    height: {
      type: [Number, String],
      default: undefined
    },
    maxHeight: {
      type: [Number, String],
      default: undefined
    },
    width: {
      type: [Number, String],
      default: undefined
    },
    maxWidth: {
      type: [Number, String],
      default: undefined
    },
    contain: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { slots }) {
    const imageSrc = ref(props.src)
    const handleImageError = (event) => {
      if (!imageSrc.value.startsWith(USER_IMAGE_PROCESSED_DOMAIN)) return
      imageSrc.value = imageSrc.value.replace('/processed', '')
    }
    const haveSlots = computed(() => !!slots.default)

    return {
      handleImageError,
      haveSlots,
      imageSrc
    }
  }
}
</script>

<template>
  <tr class="texts-start pointer" @click="onFolderClick">
    <td>
      <v-icon>folder</v-icon>
      <span class="pps-1 t-700-16">{{ folder.name }}</span>
    </td>
    <td>
      <span class="t-500-16 color5">
        {{ createdAtString }}
      </span>
    </td>
    <td>
      <span class="t-500-16 color5">
        {{ folder.creator?.first_name }} {{ folder.creator?.last_name }}
      </span>
    </td>
    <td class="text-no-wrap texts-end">
      <v-btn
        v-if="showDeleteOrEditOption" ripple icon
        @click.stop="onEdit"
      >
        <v-icon class="material-icons-outlined">
          edit
        </v-icon>
      </v-btn>
      <v-btn
        v-if="showDeleteOrEditOption" ripple icon
        @click.stop="onDelete"
      >
        <v-icon class="material-icons-outlined">
          delete
        </v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon class="material-icons-outlined">
              info
            </v-icon>
          </v-btn>
        </template>
        <div :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <span v-if="isEmptyFolder">
            <v-icon color="white" class="material-icons-outlined" size="20">
              description
            </v-icon>
            <span class="pps-1">
              {{ $t('drive.empty_single_folder') }}
            </span>
          </span>
          <div v-else>
            <div v-if="haveFiles" class="t-500-13">
              <v-icon color="white" class="material-icons-outlined" size="20">
                description
              </v-icon>
              <span class="pps-1">
                {{ filesCount }}
              </span>
            </div>
            <div v-if="haveFolders" class="t-500-13">
              <v-icon color="white" class="material-icons-outlined" size="20">
                folder
              </v-icon>
              <span class="pps-1">
                {{ foldersCount }}
              </span>
            </div>
          </div>
        </div>
      </v-tooltip>
    </td>
  </tr>
</template>
<script>
import { dateToLocalString } from '@/helpers/DateTime.js'

import useAuth from '@/composables/useAuth.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isUserCompanyAdmin, currentUser } = useAuth()

    // Methods
    const onDelete = () => emit('delete-folder', props.folder)
    const onEdit = () => emit('edit-folder', props.folder)
    const onFolderClick = () => emit('click-folder', props.folder)
    // Computed
    const createdAtString = computed(() => dateToLocalString(new Date(props.folder.created_at)))

    const showDeleteOrEditOption = computed(() => {
      if (isUserCompanyAdmin.value) return true
      const currentUserId = currentUser.value?.id
      return Number.isInteger(currentUserId) && currentUserId === props.folder.creator?.user_id
    })

    const haveFiles = computed(() => {
      const totalFiles = props.folder.total_files
      return Number.isInteger(totalFiles) && totalFiles > 0
    })

    const haveFolders = computed(() => {
      const totalFolders = props.folder.total_folders
      return Number.isInteger(totalFolders) && totalFolders > 0
    })

    const isEmptyFolder = computed(() => !haveFiles.value && !haveFolders.value)
    const filesCount = computed(() => {
      const totalFiles = props.folder.total_files
      if (totalFiles === 1) return i18n.t('drive.one_file')
      return i18n.t('drive.many_files', { count: totalFiles })
    })

    const foldersCount = computed(() => {
      const totalFiles = props.folder.total_folders
      if (totalFiles === 1) return i18n.t('drive.one_folder')
      return i18n.t('drive.many_folders', { count: totalFiles })
    })

    return {
      createdAtString,
      showDeleteOrEditOption,
      isEmptyFolder,
      haveFiles,
      haveFolders,
      filesCount,
      foldersCount,
      isRtl,
      onFolderClick,
      onDelete,
      onEdit
    }
  }
}
</script>

<template>
  <v-row
    align="center" justify="start" class="white rounded t-400-16 my-0 color2 pointer"
    @click="openDialog"
  >
    <v-col cols="10" class="color2">
      {{ $t('org_job_new_dialog.work_days') }}
      <div class="color1">
        {{ activeDaysCount }} {{ $t('days') }}
      </div>
    </v-col>
    <v-col cols="2" class="texts-end">
      <v-icon class="rtl-rotate" color="#616365">
        chevron_right
      </v-icon>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    activeDaysCount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup (_, { emit }) {
    // Methods
    const openDialog = () => emit('shift-time-click')

    return {
      openDialog
    }
  }
}
</script>

<template>
  <div
    v-if="$vuetify.breakpoint.mdAndUp"
    class="d-flex flex-column full-width"
  >
    <v-col class="mb-3 py-0 t-700-24 flex-grow-0 flex-shrink-1">
      {{ $t('items_page.pick_account') }}
    </v-col>
    <v-col v-if="isAccountsLoading" class="flex-grow-1 flex-shrink-0 mb-8 overflow-y-auto">
      <v-row v-for="index in 5" :key="index" class="texts-start pa-0 mb-2">
        <v-col cols="12">
          <v-skeleton-loader type="list-item-avatar-two-line" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else class="flex-grow-1 flex-shrink-0 mb-8 overflow-y-auto">
      <account-list-row
        v-for="account in accountList"
        :key="account.api_key" class="mb-4"
        :account="account"
        :selected="apiKey === account.api_key"
        @change-account="onChangeAccount"
      />
    </v-col>
  </div>
  <v-row v-else align="start" justify="center">
    <v-col cols="10" sm="6" class="mt-sm-0 pb-0">
      <account-select
        v-model="selectedAccount" :api-key="apiKey"
        add-fault-device-count-all-statuses
        :multiple="false"
        @change="onChangeAccount"
      />
    </v-col>
  </v-row>
</template>
<script>
import AccountListRow from '@/components/items/account_list/AccountListRow.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'

import useItem from '@/composables/useItem.js'

import { ref } from 'vue'
export default {
  components: {
    'account-select': AccountSelect,
    'account-list-row': AccountListRow
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const selectedAccount = ref(null)
    const { accountList, isAccountsLoading, initPageData, changeSelectedAccount } = useItem(props.apiKey)
    // Methods

    const onChangeAccount = changeSelectedAccount

    initPageData()
    return {
      selectedAccount,
      isAccountsLoading,
      accountList,
      onChangeAccount
    }
  }
}
</script>

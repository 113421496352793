
import { destroy as destroyDriveFile } from '@/api_client/DriveFile.js'
import { indexSubFolder as indexDriveSubFolder, destroy as destroyDriveFolder } from '@/api_client/DriveFolder.js'
import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 80
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null })
const isLoading = ref(false)
const isFilesLoading = ref(false)
const driveList = ref([])
const pagination = ref(defaultPagination())

let currentSubFolderId = null

let currentApiKey = null

export default function useCompanyDriveSubFolder (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isFilesLoading.value = false
    driveList.value = []
    pagination.value = defaultPagination()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (responseMeta) => {
    const paginationVal = pagination.value
    paginationVal.total = responseMeta.total
    paginationVal.page = responseMeta.page
    paginationVal.perPage = responseMeta.per_page
  }

  const indexApiParams = () => {
    const result = {
      page: pagination.value.page,
      per_page: pagination.value.perPage
    }
    return result
  }

  // Methods
  const changeFolder = (folderId) => loadFilesAndFolders({ reset: true, parentFolderId: folderId, resetPage: true, useLoading: true })
  const loadFilesAndFolders = ({ reset, useLoading, parentFolderId, resetPage } = { reset: false, useLoading: true, resetPage: true }) => {
    currentSubFolderId = parentFolderId
    isLoading.value = true
    if (useLoading) {
      isFilesLoading.value = true
    }
    if (reset) {
      driveList.value = []
    }
    if (resetPage) {
      pagination.value.page = DEFAULT_PAGE
    }

    return indexDriveSubFolder({ apiKey: currentApiKey, folderId: parentFolderId, params: indexApiParams() })
      .then(({ data: { data, meta } }) => {
        driveList.value = driveList.value.concat(data)
        setPaginationFromResponse(meta)
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isFilesLoading.value = false
      })
  }

  const loadNextPage = () => {
    if (driveList.value.length < pagination.value.total) {
      pagination.value.page++
      loadFilesAndFolders({ currentSubFolderId, resetPage: false })
    }
  }

  const deleteFile = (fileId) => {
    isLoading.value = true
    return destroyDriveFile(apiKey, fileId)
      .then(onFileOrFolderChanged)
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const deleteFolder = (folderId) => {
    isLoading.value = true
    return destroyDriveFolder(apiKey, folderId)
      .then(onFileOrFolderChanged)
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const onFileOrFolderChanged = () => loadFilesAndFolders({ reset: true, useLoading: false, parentFolderId: currentSubFolderId, resetPage: true })

  // Computed
  const isEmpty = computed(() => !isLoading.value && driveList.value.length === 0)

  return {
    isEmpty,
    DEFAULT_PER_PAGE,
    isLoading,
    isFilesLoading,
    driveList,
    pagination,
    deleteFile,
    deleteFolder,
    loadNextPage,
    loadFilesAndFolders,
    onFileOrFolderChanged,
    changeFolder
  }
}

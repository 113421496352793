<template>
  <v-dialog
    v-model="dialog" width="580" persistent
    content-class="rounded-xl"
    scrollable
  >
    <v-card class="overflow-y-hidden popup-card bg-color17" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('user_profile.choose_avatar') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2 overflow-y-hidden avatar-select">
        <v-carousel hide-delimiters>
          <v-carousel-item
            v-for="(imageChunk, index) in imageResult"
            :key="index"
            class="px-10"
          >
            <v-row
              align="center" no-gutters
              justify="start"
              class="mx-auto"
            >
              <v-col
                v-for="image in imageChunk" :key="image"
                cols="4" class="avatar-row my-2 d-flex justify-center"
              >
                <v-img
                  class="rounded-lg pointer mx-auto"
                  max-width="120px"
                  max-height="120px"
                  :src="image"
                  cover
                  @click="selectAvatar(image)"
                >
                  <template #placeholder>
                    <v-row
                      align="center" justify="center" class="fill-height"
                      no-gutters
                    >
                      <v-progress-circular indeterminate color="primary" />
                    </v-row>
                  </template>
                </v-img>
                <v-icon
                  v-if="isSelected(image)"
                  class="selected"
                  color="#0040FF"
                >
                  check_circle
                </v-icon>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="7">
            <v-btn
              color="#0E4AFD" class="white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { updateUserAvatar } from '@/api_client/User.js'
import { isRtl } from '@/i18n'
import { ref, computed } from 'vue'

export default {
  setup () {
    let resolveFunc
    const dialog = ref(false)
    const isLoading = ref(false)
    const selectedAvatar = ref(null)
    let userId = null
    const env = import.meta.env.MODE
    const chunkSize = 9
    const imageResult = []
    const images = Array.from({ length: 41 }, (_, index) => index + 1)
      .map(index => `https://assets.toperation.co/${env}/images/User/Avatar/ic_avatar${index}.png`)

    for (let i = 0; i < images.length; i += chunkSize) {
      imageResult.push(images.slice(i, i + chunkSize))
    }
    const closeDialog = (isChanged) => {
      isLoading.value = false
      dialog.value = false
      resolveFunc({ isChanged, selectedAvatar: selectedAvatar.value })
    }
    // Methods

    const close = () => closeDialog()
    const selectAvatar = (image) => (selectedAvatar.value = image)
    const isSelected = (image) => selectedAvatar.value === image

    const openDialog = ({ userId: userIdVal, userAvatarUrl }) => {
      isLoading.value = false
      dialog.value = true
      userId = userIdVal
      if (images.includes(userAvatarUrl)) {
        selectedAvatar.value = userAvatarUrl
      }
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const cancel = () => {
      closeDialog()
      resolveFunc(false)
    }

    const save = () => {
      isLoading.value = true
      const { apiKey } = useAuth()
      const imageNumber = selectedAvatar.value.split('ic_avatar')[1].split('.png')[0]
      updateUserAvatar({ apiKey: apiKey.value, userId, imageNumber })
        .then(() => closeDialog(true))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }
    const isSaveDisabled = computed(() => isLoading.value || selectedAvatar.value === null)
    return {
      dialog,
      isLoading,
      isSaveDisabled,
      isRtl,
      imageResult,
      openDialog,
      cancel,
      close,
      selectAvatar,
      isSelected,
      save
    }
  }
}
</script>
<style  lang="scss">
.avatar-select {
  .avatar-row {
    position: relative;
    padding: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .selected {
      position: absolute;
      bottom: 3%;
      right: 20%;
    }
    .v-img {
      margin: 0 auto;
    }
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog" width="350"
    content-class="rounded-xl"
  >
    <v-card class="overflow-y-hidden popup-card bg-color17" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('company_settings.company_name') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row align="start" justify="start" class="pb-3">
            <v-col cols="12">
              <v-text-field
                v-model="companyNameModel"
                outlined
                background-color="white"
                class="required"
                counter="120"
                :label="$t('company_settings.company_name')"
              />
            </v-col>
            <v-col cols="12">
              <single-image-uploader
                v-if="!readOnlyMode" ref="accountImageRef" :url="companyImageUrl"
                width="80px"
              />
              <v-img
                v-else-if="showImagePreview" max-height="150"
                :src="companyImageUrl"
              >
                <template #placeholder>
                  <v-row
                    align="center" justify="center" class="fill-height"
                    no-gutters
                  >
                    <v-progress-circular indeterminate color="primary" />
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!readOnlyMode">
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="saveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'

import { isString, isObject } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n'
import { computed, ref } from 'vue'

export default {
  components: {
    'single-image-uploader': SingleImageUploader
  },
  setup () {
    const isLoading = ref(false)
    const companyNameModel = ref(null)
    const accountImageRef = ref(null)
    const companyImageUrl = ref(null)
    const dialog = ref(false)

    const { apiKey, isUserCompanyAdmin } = useAuth()
    const { companyName, customCompanyImage, updateCompanyData, removeCompanyImage, loadCompanyData } = useCompany(apiKey.value)

    // Methods

    const openDialog = () => {
      dialog.value = true
      companyNameModel.value = companyName.value
      if (isString(customCompanyImage.value) && customCompanyImage.value.length > 0) {
        companyImageUrl.value = customCompanyImage.value
      }
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
      companyImageUrl.value = null
    }

    const save = () => {
      isLoading.value = true
      const addImage = accountImageRef.value.shouldAddImage()
      const requestParams = { name: companyNameModel.value, withImage: addImage }
      updateCompanyData(requestParams)
        .then(async (responseData) => {
          companyNameModel.value = companyName.value
          if (addImage && isObject(responseData.data.image_upload_data)) {
            const imageUploadData = responseData.data.image_upload_data
            await uploadFileToRemoteServer(accountImageRef.value.getImageFile(), imageUploadData.file_url)
          } else if (accountImageRef.value.shouldRemoveImage()) {
            await removeCompanyImage()
          }
        })
        .catch(errHandler)
        .finally(close)
    }

    // Computed

    const showImagePreview = computed(() => readOnlyMode.value && isString(companyImageUrl.value))
    const readOnlyMode = computed(() => !isUserCompanyAdmin.value)
    const saveDisabled = computed(() => {
      if (isLoading.value) return true
      const newCompanyName = companyNameModel.value
      const valid = isString(newCompanyName) && newCompanyName.trim().length > 0
      return !valid
    })

    isLoading.value = true
    loadCompanyData()
      .then(() => (companyNameModel.value = companyName.value))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))

    return {
      isLoading,
      companyNameModel,
      dialog,
      saveDisabled,
      companyImageUrl,
      showImagePreview,
      isRtl,
      readOnlyMode,
      accountImageRef,
      openDialog,
      close,
      save
    }
  }
}
</script>

<template>
  <v-container
    fluid class="employees_page"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <new-employee-dialog
      v-if="staffAccountApiKeyLoaded"
      ref="employeeDialog"
      :api-key="apiKey" :allow-set-features="isAccountAdminOrAbove"
    />
    <confirm-delete ref="deleteDialog" />
    <employee-from-another-store-dialog
      v-if="showAddEmployeeButton" ref="employeeAnotherStoreDialog"
      :api-key="apiKey"
    />
    <v-btn
      v-if="isAccountManagerOrAbove && !readOnlyMode" fab small
      color="black white--text"
      class="add-button" @click="createNewEmployee"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-row v-if="staffAccountApiKeyLoaded" align="start" justify="center">
      <v-col cols="12" class="page-title">
        {{ companyContext ? $t("employees.staff_page_title") : $t("employees.page_title") }}
      </v-col>
      <v-col cols="12" sm="10" lg="8">
        <v-row align="start" justify="start" no-gutters>
          <v-col
            v-if="showAccountSelect" cols="12" md="4"
            sm="6" class="my-4"
          >
            <page-account-select />
          </v-col>
          <v-col v-if="isAccountManagerOrAbove" cols="12" class="texts-start">
            <v-btn
              v-if="showAddEmployeeButton" :small="$vuetify.breakpoint.xsOnly" rounded
              color="black white--text" @click="addFromAnotherStore"
            >
              {{ $t('employees.add_employee_from_another_store') }}
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="!companyContext"
            cols="12" md="4"
            sm="6" class="mt-3 mb-1"
          >
            <user-search
              v-model="curentUserSearch"
              :api-key="apiKey"
              class="filter-select"
              @change="onUserSearchChange"
            />
          </v-col>
          <v-col cols="12" class="mt-8 mt-sm-2 mms-2">
            <span class="font-weight-bold mx-1">
              {{ $t("employees.total_employee") }}
            </span>
            <span class="font-weight-bold blue--text">
              {{ totalUsers }}
            </span>
          </v-col>
          <v-col cols="12" class="mt-2">
            <v-data-table
              :items="usersList"
              :loading="isLoading"
              class="elevation-1"
              :headers="usersTableHeaders"
              :hide-default-header="$vuetify.breakpoint.xsOnly"
              :loading-text="$t('loading')"
              :items-per-page="DEFAULT_PER_PAGE"
              hide-default-footer
              item-key="id"
            >
              <template #item="{ item }">
                <employee-table-row
                  :employee="item"
                  :read-only="readOnlyMode"
                  @edit-employee="editEmployee"
                  @delete-employee="deleteEmployee"
                />
              </template>
              <template #no-data>
                <v-alert :value="true" color="white black--text">
                  {{ $t("no_result") }}
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="totalPages > 0" cols="12" class="text-center">
            <v-pagination
              :value="pagination.page" circle total-visible="10"
              :length="totalPages" @input="setPage"
              @previous="setPage(pagination.page - 1)" @next="setPage(pagination.page + 1)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else align="start" justify="center">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </v-container>
</template>
<script>
import EmployeesTableRow from '@/components/employees/EmployeesTableRow.vue'
import NewEmployeeDialog from '@/components/employees/new_employee_dialog/NewEmployeeDialog.vue'
import EmployeeFromAnotherStoreDialog from '@/components/employees/EmployeeFromAnotherStoreDialog.vue'
import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'
import UserSearch from '@/components/shared/user_search/UserSearch.vue'

import useAuth from '@/composables/useAuth.js'

import useCompany from '@/composables/useCompany.js'
import useEmployeeStore from '@/components/employees/useEmployeeStore.js'

import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import { isString, isObject } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, watch } from 'vue'

import { scrollToTop } from '@/classes/TopScroll.js'

import { useRoute } from 'vue-router/composables'

export default {
  components: {
    'employee-table-row': EmployeesTableRow,
    'new-employee-dialog': NewEmployeeDialog,
    'employee-from-another-store-dialog': EmployeeFromAnotherStoreDialog,
    'confirm-delete': ConfirmDelete,
    'page-account-select': PageAccountSelect,
    'user-search': UserSearch
  },
  props: {
    isStaff: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props) {
    const usersTableHeaders = [{ align: 'center', text: i18n.t('employees.name_col'), width: '100%', value: 'name', sortable: false }]
    const employeeDialog = ref(null)
    const employeeAnotherStoreDialog = ref(null)
    const deleteDialog = ref(null)
    const curentUserSearch = ref({})

    const router = useRoute()
    const { apiKey: apiKeyRef, isAccountAdminOrAbove, isAccountManagerOrAbove, isUserCompanyAdmin, isCompanyAdminRegionOrAbove } = useAuth()
    const { isMultipleAccounts, staffAccountApiKey } = useCompany(apiKeyRef.value)
    const companyContext = computed(() => props.isStaff === true || router.meta.staffOnly)
    const apiKey = computed(() => companyContext.value ? staffAccountApiKey.value : apiKeyRef.value)
    const { DEFAULT_PER_PAGE, users: usersList, totalUsers, pagination, totalPages, isLoading, showSingleUser, loadUsers, deleteUser, setOnlyCompanyAdmins } = useEmployeeStore(apiKey.value)

    setOnlyCompanyAdmins(companyContext.value)

    const loadTablePage = async (page) => loadUsers(page).catch(errHandler)

    // Methods

    const createNewEmployee = () => employeeDialog.value.openForCreate(companyContext.value)
    const addFromAnotherStore = () => employeeAnotherStoreDialog.value.openDialog()
    const editEmployee = (employee) => employeeDialog.value.openForUpdate(employee)
    const setPage = (page) => {
      loadTablePage(page)
      scrollToTop()
    }

    const deleteEmployee = (employee) => {
      deleteDialog.value.open()
        .then((shouldDelete) => {
          if (!shouldDelete) return
          deleteUser(employee.id)
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }

    const onUserSearchChange = () => userSelected.value === true ? showSingleUser(curentUserSearch.value.id) : loadUsers()

    // Computed
    const readOnlyMode = computed(() => companyContext.value && !isUserCompanyAdmin.value)
    const showAddEmployeeButton = computed(() => {
      if (!companyContext.value && isMultipleAccounts.value === true && staffAccountApiKeyLoaded.value) return true
      return false
    })
    const staffAccountApiKeyLoaded = computed(() => {
      if (companyContext.value !== true) return true
      const staffAccountApiKeyVal = staffAccountApiKey.value
      return isString(staffAccountApiKeyVal) && staffAccountApiKeyVal.length > 0
    })

    const showAccountSelect = computed(() => isCompanyAdminRegionOrAbove.value && companyContext.value !== true)
    const userSelected = computed(() => isObject(curentUserSearch.value) && Number.isInteger(curentUserSearch.value.id))

    watch(() => staffAccountApiKeyLoaded.value, (isLoaded) => {
      if (isLoaded !== true) return
      loadTablePage().catch(errHandler)
    }, { immediate: true })

    return {
      usersTableHeaders,
      DEFAULT_PER_PAGE,
      employeeDialog,
      employeeAnotherStoreDialog,
      deleteDialog,
      companyContext,
      showAddEmployeeButton,
      staffAccountApiKeyLoaded,
      apiKey,
      isAccountManagerOrAbove,
      isAccountAdminOrAbove,
      usersList,
      totalUsers,
      pagination,
      totalPages,
      isLoading,
      isRtl,
      readOnlyMode,
      showAccountSelect,
      curentUserSearch,
      createNewEmployee,
      addFromAnotherStore,
      setPage,
      editEmployee,
      deleteEmployee,
      onUserSearchChange
    }
  }
}
</script>
<style lang="scss">
.employees_page {
  margin-top: 116px;

  .v-data-table__wrapper {
    overflow-x: hidden;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 136px;

    &.staff {
      margin-top: 138px;
    }
  }
}
</style>

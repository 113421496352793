<template>
  <v-dialog
    v-model="dialog"
    max-width="350" persistent
    content-class="rounded-xl"
  >
    <v-card
      class="edit-chat-dialog overflow-y-hidden"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="text-center pb-5">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="pa-2" :class=" readOnlyMode ? 'scrollable' : 'overflow-y-hidden' ">
        <v-row align="center" justify="start">
          <v-col cols="12" class="pb-0">
            <v-text-field
              v-model="newGroupData.name"
              single-line
              outlined
              class="required"
              dense
              :rules="[requiredField]"
              :loading="isLoading"
              :disabled="readOnlyMode || isLoading"
              counter="180"
              :label="$t('app_chat.new_group_dialog.group_name')"
            />
          </v-col>
          <v-col cols="12">
            <v-progress-circular v-if="loadingGroupUsers" indeterminate color="primary" />
            <v-list v-else-if="readOnlyMode">
              <v-list-item
                v-for="user in newGroupData.groupUsers" :key="user.id"
              >
                <v-badge
                  color="primary"
                  icon="star"
                  :value="user.group_admin === true"
                  overlap
                >
                  <v-avatar color="yellow darken-2" size="40">
                    <v-img v-if="user.avatar_url" :src="user.avatar_url" />
                    <span v-else class="white--text text-h5">
                      {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
                    </span>
                  </v-avatar>
                </v-badge>
                <v-list-item-content class="mms-4 font-weight-medium">
                  {{ user.first_name }} {{ user.last_name }}
                  <v-list-item-title />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <company-user-search
              v-else v-model="newGroupData.groupUsers"
              :api-key="apiKey"
              outlined
              class="required"
              dense
              show-selection-count
              hide-details
              :read-only="readOnlyMode"
              :placeholder="$t('app_chat.new_group_dialog.add_users')"
            />
          </v-col>
          <v-col v-if="readOnlyMode && groupWithImage" cols="12" class="mt-5">
            <v-img
              max-height="150"
              :src="newGroupData.imageUrl"
            >
              <template #placeholder>
                <v-row
                  align="center" justify="center" class="fill-height"
                  no-gutters
                >
                  <v-progress-circular indeterminate color="primary" />
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col v-else-if="!readOnlyMode" cols="12">
            <div class="color5 t-500-16 mb-3">
              {{ $t('app_chat.new_group_dialog.group_image') }}
            </div>
            <single-image-uploader ref="groupImage" width="80px" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-2 pr-2">
        <v-row align="center" justify="center">
          <v-col v-if="readOnlyMode" cols="9">
            <v-btn
              class="cancel-btn"
              color="#DADADA" depressed outlined
              block
              tile @click="close"
            >
              {{ $t('close') }}
            </v-btn>
          </v-col>
          <v-col v-else cols="9" class="pb-0">
            <v-btn
              class="save-btn white--text"
              color="#0E4AFD" depressed block
              :loading="isLoading" :disabled="isSaveDisabled" @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
          <v-col v-if="!readOnlyMode" cols="9">
            <v-btn
              class="cancel-btn"
              color="#DADADA" depressed outlined
              block :disabled="isLoading"
              @click="close"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import CompanyUserSearch from '@/components/shared/user_search/CompanyUserSearch.vue'
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import useAppChat from '@/composables/useAppChat.js'
import { requiredField } from '@/classes/InputValidators.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isString } from '@/helpers/Utils.js'

import { EventBus, NEW_CHAT_GROUP_CREATED } from '@/EventBus.js'

import { i18n, isRtl } from '@/i18n.js'
import { computed, ref } from 'vue'

const DialogModes = { create: 1, update: 2 }

const newGroupDefualtData = () => {
  return {
    id: null,
    name: '',
    groupUsers: [],
    imageUrl: null
  }
}

export default {
  components: {
    'company-user-search': CompanyUserSearch,
    'single-image-uploader': SingleImageUploader
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const dialog = ref(false)
    const dialogMode = ref(DialogModes.create)
    const newGroupData = ref(newGroupDefualtData())
    const loadingGroupUsers = ref(false)
    const readOnlyMode = ref(false)
    const groupImage = ref(false)

    const { isLoading, createNewGroup, updateGroup, addGroupImage, removeGroupImage, listGroups, chatGroupParticipants } = useAppChat(props.apiKey)

    const saveParams = () => {
      const newGroupVal = newGroupData.value

      const newGroupParams = {
        name: newGroupVal.name,
        groupUsers: newGroupVal.groupUsers.map(user => user.id)
      }

      if (dialogMode.value === DialogModes.update) {
        newGroupParams.id = newGroupVal.id
      }
      if (groupImage.value.shouldAddImage()) {
        newGroupParams.withImage = true
      }
      return newGroupParams
    }

    const resetValues = () => {
      newGroupData.value = newGroupDefualtData()
      loadingGroupUsers.value = false
      readOnlyMode.value = false
      if (groupImage.value) groupImage.value.reset()
    }

    const onOpenDialog = (mode = DialogModes.create) => {
      dialog.value = true
      dialogMode.value = mode
    }

    const updateImageIfNeeded = async (responseData) => {
      if (groupImage.value.shouldRemoveImage()) {
        return removeGroupImage({ chatGroupId: newGroupData.value.id })
      } else if (groupImage.value.shouldAddImage()) {
        const imageUploadData = responseData.image_upload_data
        const chatGroupId = dialogMode.value === DialogModes.create ? responseData.id : newGroupData.value.id
        return addGroupImage({ chatGroupId, imageUploadData, imageFile: groupImage.value.getImageFile() })
      }
    }

    // Methods
    const openDialog = onOpenDialog

    const close = () => {
      dialog.value = false
      resetValues()
    }

    const openForUpdate = ({ id, name, isReadOnly, imageUrl }) => {
      onOpenDialog(DialogModes.update)
      readOnlyMode.value = isReadOnly
      const newGroupDataVal = newGroupData.value
      newGroupDataVal.id = id
      newGroupDataVal.name = name
      newGroupDataVal.imageUrl = imageUrl
      if (!isReadOnly) {
        if (groupImage.value) {
          groupImage.value.setImageUrl(imageUrl)
        } else {
          setTimeout(() => groupImage.value.setImageUrl(imageUrl))
        }
      }

      loadingGroupUsers.value = true
      chatGroupParticipants({ chatGroupId: id })
        .then((data) => {
          const groupUsers = newGroupDataVal.groupUsers
          groupUsers.splice(0, groupUsers.length)
          data.forEach((user) => groupUsers.push(user))
        })
        .catch(errHandler)
        .finally(() => (loadingGroupUsers.value = false))
    }

    const save = () => {
      const params = saveParams()
      const promise = dialogMode.value === DialogModes.create
        ? createNewGroup(params)
        : updateGroup(params)

      promise
        .then((response) => updateImageIfNeeded(response))
        .then(() => EventBus.emit(NEW_CHAT_GROUP_CREATED))
        .then(listGroups)
        .catch(errHandler)
        .finally(close)
    }

    // Computed
    const groupWithImage = computed(() => isString(newGroupData.value.imageUrl) && newGroupData.value.imageUrl.length > 0)

    const dialogTitle = computed(() => {
      if (dialogMode.value === DialogModes.create) return i18n.t('app_chat.new_group_dialog.title_create')
      if (readOnlyMode.value === true) return i18n.t('app_chat.new_group_dialog.title_read_only')
      return i18n.t('app_chat.new_group_dialog.title_update')
    })

    const isSaveDisabled = computed(() => {
      if (isLoading.value === true) return true
      const newGroupDataVal = newGroupData.value
      const groupName = newGroupDataVal.name
      if (!isString(groupName) || groupName.trim().length === 0) return true
      return newGroupDataVal.groupUsers.length === 0
    })

    return {
      dialog,
      isLoading,
      loadingGroupUsers,
      isSaveDisabled,
      dialogTitle,
      newGroupData,
      readOnlyMode,
      groupWithImage,
      groupImage,
      isRtl,
      openDialog,
      openForUpdate,
      close,
      save,
      requiredField
    }
  }
}
</script>
<style lang="scss">
.edit-chat-dialog {
  .scrollable {
    overflow: auto;
    height: 50vh;
    max-height: 70vh;
  }
  .cancel-btn {
    color: rgba(21, 25, 32, 0.50) !important;
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
}
</style>

<template>
  <v-row
    align="start" justify="start" class="my-0"
  >
    <v-col class="flex-grow-0 flex-shrink-1 ppe-0 py-2">
      <v-checkbox
        :input-value="workingDay.selected"
        hide-details
        class="ppe-2 mt-2 d-inline-block"
        @change="onDaySelect"
      />
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 d-flex align-center justify-start py-2">
      <span class="t-500-16 color2 day-name mme-2">
        {{ day.translatedName }}
      </span>
      <v-text-field
        class="shift-time-field mx-2"
        color="black"
        hide-details
        readonly
        :label="$t('org_job_new_dialog.start')"
        :value="startTimeInUserFormat"
        @click="onTimeClick(true)"
      />
      <span class="t-700-17">-</span>
      <v-text-field
        class="shift-time-field mms-2"
        hide-details
        readonly
        :label="$t('org_job_new_dialog.finish')"
        :value="endTimeInUserFormat"
        @click="onTimeClick(false)"
      />
      <span v-if="showTotalHours" class="t-500-16 color2 white py-2 px-3 rounded-xl mms-2">
        {{ totalWorkTimeForDay }}
      </span>
    </v-col>
  </v-row>
</template>
<script>
import useAccount from '@/composables/useAccount.js'

import { timeStringToUserFormat, durationBetweenTimes, isValidTimeString } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    workingDay: {
      type: Object,
      required: true
    },
    day: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { accountLocaleCode } = useAccount()

    const timeInUserFormat = (timeString) => {
      if (!isValidTimeString(timeString)) return ''
      return timeStringToUserFormat({ timeString, localeCode: accountLocaleCode.value })
    }

    // Methods
    const onTimeClick = (isStartTime) => emit('time-click', { isStartTime, dayName: props.day.name })
    const onDaySelect = () => emit('day-select', props.day.name)

    // Computed
    const startTimeInUserFormat = computed(() => timeInUserFormat(props.workingDay.start_time))
    const endTimeInUserFormat = computed(() => timeInUserFormat(props.workingDay.end_time))
    const showTotalHours = computed(() => isValidTimeString(props.workingDay.start_time) && isValidTimeString(props.workingDay.end_time))
    const totalWorkTimeForDay = computed(() => {
      const startTime = props.workingDay.start_time
      const endTime = props.workingDay.end_time
      const { hours, minutes } = durationBetweenTimes({ startTime, endTime })
      return minutes > 0
        ? i18n.t('org_structure_jobs.shift_duration', { hours, minutes })
        : i18n.t('org_structure_jobs.shift_duration_hours', { hours })
    })

    return {
      startTimeInUserFormat,
      endTimeInUserFormat,
      showTotalHours,
      totalWorkTimeForDay,
      onTimeClick,
      onDaySelect
    }
  }
}
</script>
